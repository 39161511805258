
<div class="modal" tabindex="-1" role="dialog" [ngClass]="isShowModal ? 'open-modal' : 'close-modal'">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header title-container" [hidden]="true">
            <img
            (click)="hideModal()"
              *ngIf="!showSuccessDailog"
              class="close-logo"
              src="assets/locations/close.png"
              alt="..."
            />
            <h3>{{(heading | customTranslation: translationData) ? ((heading | customTranslation: translationData)): heading }}</h3>
          </div>
          <div class="modal-body">
            <div
            *ngIf="!showSuccessDailog && !showProgress"
            class="trackupload-modal-content trail-upload-region"
            appDragDropFileUpload
            (click)="fileField.click()"
            (fileDropped)="fileChange($event)"
          >
            <div for="fileField">
              <img class="upload-logo" src="assets/locations/file.png" alt="..." />
            </div>
            <div>
              <input type="file" #fileField (change)="fileChange($event)" accept=".gpx, .zip, .kml" />
              <span>{{'BP_EM_DRAG_DROP' | customTranslation : translationData }} <strong
                  class="heightlight-color">{{'BP_CM_BROWSE_BTN_LBL' | customTranslation : translationData }}</strong></span>
            </div>
            </div>
          <div class="trackupload-modal-content trail-upload-region" *ngIf="showProgress"             
          >
            <div for="fileField">
              <img class="upload-logo" src="assets/locations/file.png" alt="..." />
            </div>
            <div class="progress-bar">
              <app-progressbar
                [progress]="progress"
                *ngIf="showProgress"
              ></app-progressbar>
            </div>
          </div>
        
          <div class="successfull-content" *ngIf="showSuccessDailog">
            <div for="fileField">
              <img
                class="success-logo"
                src="assets/locations/icn_tick.png"
                alt="..."
              />
            </div>
            <div>
              <span class="upload-text">Upload complete</span>
              <span class="redirect-text">Redirecting you to the next step..</span>
            </div>
          </div>
          </div>
      </div>
  </div>
</div>