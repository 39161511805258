import { LocationsModule } from './modules/locations/locations.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule,APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenResolverService } from './shared/resolver/token-resolver.service';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { AuthorizeComponent } from './components/authorize/authorize.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './modules/locations/services/loader.service';
import { LogoutComponent } from './components/logout/logout.component';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthAmplifyService } from './shared/service/auth-amplify.service';
import { AliasModule } from './modules/alias/profile.module';
import { HeaderComponent } from './components/header/header.component';
import { RefreshSessionComponent } from './components/refresh-session/refresh-session.component';
import { SharedModule } from './shared/shared.module';
import { ErrorHandlingInterceptor } from './shared/error-handling/error-handling.interceptor';
import { AlertModule } from './modules/locations/components/alert-modal/alert-modal.module';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { TranslationModule } from "./modules/translation/translation.module";
import { RandomNumberGeneratorService } from './shared/service/random-number-generator.service';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { TranslationService } from './shared/service/translation.service';
import { firstValueFrom, tap } from 'rxjs';
import { LanguageSelectionComponent } from './shared/components/language-selection/language-selection.component';
import { LanguageService } from './shared/service/language.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './shared/store-manager/admin-stores/app.reducer';
import { metaReducers, reducers } from './shared/store-manager/store';

/**
 * @description Added to load feature flags API and get all feature flags before any routes 
 * or auth.guard.ts get laoded. This will be used to navigation, refresh etc
 * @param translationService 
 * @returns Promise<any>
 */
export function loadFeatureFlagsFactory(translationService: TranslationService, languageService: LanguageService): () => Promise<any> {
    return async () => {
        const [featureFlags, supportedLanguages] = await Promise.all([
            firstValueFrom(translationService.getAllFeatureFlags().pipe(
                tap((featureFlags: any) => {  
                    translationService.featureFlags = featureFlags;
                })
            )),
            firstValueFrom(translationService.getAllSupportedLanguages().pipe(
                tap((supportedLanguages: any) => {
                    translationService.supportedLanguagesForDisplay = supportedLanguages;
                })
             ))
        ]);
        return [featureFlags, supportedLanguages];
    };
}


@NgModule({
    declarations: [
        AppComponent,
        AuthenticateComponent,
        AuthorizeComponent,
        LogoutComponent,
        FooterComponent,
        HeaderComponent,
        RefreshSessionComponent,
        MainNavComponent,
        LanguageSelectionComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadFeatureFlagsFactory,
            deps: [TranslationService],
            multi: true
        },
        TokenResolverService,
        LanguageService,
        AuthAmplifyService,
        RandomNumberGeneratorService,
        {
            //With this your app will wait to resolve the promise of init() of your UserAuthService.
            provide: APP_INITIALIZER,
            useFactory: (service: AuthAmplifyService) => function () { const res = service.init(); return res; },
            deps: [AuthAmplifyService],
            multi: true
        },
        LoaderService,
        // { provide: HTTP_INTERCEPTORS, useClass: AuthHelpers, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        AliasModule,
        SharedModule,
        AlertModule,
        TranslationModule,
        LocationsModule,
        LoadingSpinnerModule,
        StoreModule.forRoot(reducers, {metaReducers})
    ]
})
export class AppModule { }
