import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppAction from '../admin-stores/app.action';
import { languageSelector, mapCurrentDataSelector, mapResetCurrentDataSelector, mapTilesSelector } from '../admin-stores/app.selector';
import { takeUntil, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreManagerService {

  private currentLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('en-GB');
  private mapThemeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('normal');
  private _currentMapDetails: BehaviorSubject<{ latLng: string; zoom: number; }> = new BehaviorSubject<{ latLng: string, zoom: number, }>({ latLng: "52.520007,13.404954", zoom: 3.75 });
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _store: Store) { }

  /**
   * @description This is called when user selects a language from the language selection modal
   * @param languageId 
   */
  public setUserLanguageToStore(languageId: string) {
    this._store.dispatch(AppAction.setLanguage({ language: languageId }));
  }

  public getUserSelectedLanguageFromStore(): string {
    this._store.select(languageSelector).pipe(takeUntil(this.destroy$)).subscribe((currentLanguage: string) => {
      this.currentLanguageSubject.next(currentLanguage);
    });
    return this.currentLanguageSubject.getValue();
  }

  /**
   * @description This is called when user selects a theme for the map - satellite or map
   * @param theme 
   */
  public setMapThemeToStore(theme: string) {
    this._store.dispatch(AppAction.setUserSelectedMapTiles({ mapTiles: theme }));
  }

   /**
   * @description This is called when the application is loaded.
   * This is get the map theme - satellite or normal
   */
   public getMapThemeFromStore() {
    this._store.select(mapTilesSelector).pipe(takeUntil(this.destroy$)).subscribe((mapThemeSelected) => {
      this.mapThemeSubject.next(mapThemeSelected);
    });
    return this.mapThemeSubject.getValue();
  }

  /**
   * @description This is called when user changes the map coordinates or zoom level
   * we subscribe to the store to get the updated map coordinates and zoom level
   * so that when user navigates to other pages and comes back, we load the map page where he last left
   * @param mapCurrentData 
   */
  public setCurrentMapDataToStore(mapCurrentData: { latLng: string, zoom: number }) {
    this._store.dispatch(AppAction.mapCurrentData({mapData: mapCurrentData}));
  }

  public getCurrentMapDataFromStore() {
    this._store.select(mapCurrentDataSelector).pipe(takeUntil(this.destroy$)).subscribe((mapCoordinatesWithZoom) => {
      this._currentMapDetails.next(mapCoordinatesWithZoom);
    });
    return this._currentMapDetails.getValue();
  }

  public setMapDataOnLoad(initialMapCurrentData: { latLng: string, zoom: number }) {
    this._store.dispatch(AppAction.mapResetCurrentData({mapResetData: initialMapCurrentData}));
  }

  public getMapDataOnLoad() {
    this._store.select(mapResetCurrentDataSelector).pipe(takeUntil(this.destroy$)).subscribe((mapCoordinatesWithZoom) => {
      this._currentMapDetails.next(mapCoordinatesWithZoom);
    });
    return this._currentMapDetails.getValue();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
