import { AppConstants } from 'src/app/shared/constants/constants';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import { TranslationService } from '../../service/translation.service';
import { Store } from '@ngrx/store';
import * as AppAction from "src/app/shared/store-manager/admin-stores/app.action";
import { languageSelector } from '../../store-manager/admin-stores/app.selector';
import { StoreManagerService } from '../../store-manager/services/store-manager.service';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit {

  @Input() showLanguageSelectionModal: boolean = false;
  translationData: any;
  allLanguages: any[] = [];
  BUTTON_NAMES = AppConstants.COMMON_BUTTON_LABELS;

  constructor(private _languageService: LanguageService, 
    private _translationService: TranslationService,
    private _storeManagerService: StoreManagerService) {
    this._storeManagerService.getUserSelectedLanguageFromStore();
   }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe((data:any) => {
      this.translationData = data;
    });
    this.allLanguages = this._translationService.supportedLanguagesForDisplay;
  }

  public closeLangSelection(): void {
    this._languageService.showLanguageSelectionModal.next(false);
  }

  public switchLanguage(language:string): void {
    this._storeManagerService.setUserLanguageToStore(language);
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  ngOnDestroy(): void {
    this._languageService.showLanguageSelectionModal.next(false);
  }

}
