<ng-template #showModalContent>
  <form
  [formGroup]="addMemberForm"
  (ngSubmit)="onSubmit()"
    class="userId-form"
    id="userId-form"
    action=""
  >
    <label for="userId" class="userId">User ID</label>
    <input type="text"
    class="userId-Data"
    [ngClass]="{'not-empty': formSubmitted && addMemberForm.get('userId')?.errors?.minlength, 
                'error-add': errorAddMember || (addMemberForm.value.userId.invalid && addMemberForm.value.userId.touched), 
                'invalid-value': addMemberForm.get('userId')?.errors?.pattern && (addMemberForm.get('userId')?.touched || addMemberForm.get('userId')?.dirty) }"
    formControlName="userId"
    maxlength="20"
     />
    <small *ngIf="errorAddMember && addMemberForm.invalid && addMemberForm.value.userId.length" >{{errorMessage}}</small>
  </form>
</ng-template>

<app-common-modal
  [isCloseModal]="isCloseModal"
  [isHeaderVisible]="isHeaderVisible"
  [isFooterVisible]="isFooterVisible"
  [closeButtonTitle]="closeButtonTitle"
  [saveButtonTitle]="saveButtonTitle"
  [showModalContent]="showModalContent"
  (closeModalEmitter)="closeModal()"
  (saveModalEmitter)="onSubmit()"
  [type]="type"
  *ngIf="showModal"
>
</app-common-modal>
