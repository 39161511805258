<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="layout-title">{{locationConstants.HELPER_INPUT_LABELS.LAYOUT_DETAILS| customTranslation:translationData}}</div>
    <div class="form-row">
        <div class="form-item material-underline full-w">
            <label>{{locationConstants.HELPER_INPUT_LABELS.ENTER_CIRCUIT_NAME| customTranslation:translationData | sentencecase}} *</label>
            <input type="text" placeholder=" " formControlName="name" maxlength="50"  value="" [ngClass]="form.controls.name.dirty && form.controls.name.invalid ? 'invalid-input': ''">
        </div>
    </div>
    <div class="form-row">
        <div class="form-item material-underline full-w">
            <label for="floatingTextarea">{{commonLabels.DESCRIPTION| customTranslation:translationData | sentencecase}}</label>
            <textarea (keyup)="checkWordCount(); textAreaAdjust()" id="description" [placeholder]="" maxlength="500" formControlName="description"  value="">
            </textarea>        
            <div class="form-note-text right">{{wordCount}}/500 {{commonLabels.CHARACTERS| customTranslation: translationData }}</div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-item material-underline">
            <label>{{locationConstants.HELPER_INPUT_LABELS.SET_CIRCUIT_NAME| customTranslation:translationData | sentencecase}}</label>
            <div class="btn-group-rd-ck" formControlName="type">
                <div class="form-check-item">
                    <input class="form-check-input" #permanent type="radio" value="PERMANENT" name="type" formControlName="type" >
                    <label for="flexRadioDefault1" (click)="permanent.click()"> {{'BP_LM_PERMANENT' | customTranslation: translationData | sentencecase }} </label>
                </div>
                <div class="form-check-item">
                    <input class="form-check-input" #develop type="radio" value="TEMPORARY" name="type" formControlName="type">
                    <label for="flexRadioDefault2" (click)="develop.click()"> {{'BP_LM_TEMPORARY' | customTranslation: translationData | sentencecase}} </label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-item material-underline full-w">
            <label for="floatingInputValue">{{locationConstants.HELPER_INPUT_LABELS.WEBSITE| customTranslation:translationData | sentencecase}}</label>
            <input type="text" formControlName="website"  value="">
        </div>
    </div>
    <div class="form-row">
        <div class="form-item material-underline full-w">
            <label >{{locationConstants.HELPER_INPUT_LABELS.WIKIPEDIA| customTranslation:translationData | sentencecase}}</label>
            <input type="text" formControlName="wikipedia"  value="">
        </div>
    </div>
    <div class="form-row">
        <div class="form-item material-underline full-w">
            <label>{{'BP_LM_ADDRESS' | customTranslation:translationData | sentencecase }}</label>
            <input type="text" formControlName="address" value="">
        </div>
    </div>
    <ng-container *ngIf="hasImage">
        <div class="layout-title">{{locationConstants.HELPER_INPUT_LABELS.IMAGE_COPYRIGHT_INFO| customTranslation:translationData}}</div>
        <div formGroupName="copyright">
            <div class="form-row">
                <div class="form-item material-underline full-w">
                    <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_ATTRIBUTION | customTranslation:translationData |
                        sentencecase }}</label>
                    <input type="text" formControlName="attribution" value="">
                </div>
                <div class="form-item material-underline full-w">
                    <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_LICENSE | customTranslation:translationData |
                        sentencecase }}</label>
                    <input type="text" formControlName="license" value="">
                </div>
                <div class="form-item material-underline full-w">
                    <label>{{locationConstants.HELPER_INPUT_LABELS.LICENCE_URL | customTranslation:translationData |
                        sentencecase }}</label>
                    <input type="text" formControlName="license_url" value="" [ngClass]="form.get('copyright.license_url')?.invalid ? 'invalid-input': ''">
                </div>
                <div class="form-item material-underline full-w">
                    <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_URL | customTranslation:translationData | sentencecase
                        }}</label>
                    <input type="text" formControlName="subject_url" value="" [ngClass]="form.get('copyright.subject_url')?.invalid ? 'invalid-input': ''">
                </div>
            </div>
        </div>
    </ng-container>
</form>