import {ActionReducer, createReducer, on} from '@ngrx/store';
import * as AppAction from './app.action';
import { LOCATIONS_CONSTANTS } from 'src/app/modules/locations/interfaces/locations-constants';

const initialCoordinates = {
  latLng: '52.520007,13.404954',
  zoom: LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_NO_LOC
};

const latLng = sessionStorage.getItem('mapCoords');
const userCurrentLocation = {
  latLng: latLng,
  zoom: latLng ? LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC: LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_NO_LOC
}
const initialState = {
  profile: null,
  language: 'en-GB',
  mapTiles: 'normal',
  mapData: {
    latLng: '',
    zoom: 0
  },
  mapResetData: {
    latLng: userCurrentLocation.latLng ?? initialCoordinates.latLng,
    zoom: userCurrentLocation?.zoom ?? initialCoordinates.zoom
  }
};

export const appReducer: ActionReducer<any> = createReducer(initialState,
  on(AppAction.setProfile, (state, { profile }) => {
    state = { ...state, profile };
    return state;
  }),
  on(AppAction.setLanguage, (state, { language }) => {
    state = { ...state, language };
    return state;
  }),
on(AppAction.setUserSelectedMapTiles, (state, { mapTiles }) => {
    state = { ...state, mapTiles };
    return state;
}),
on(AppAction.mapCurrentData, (state, { mapData }) => {
    state = { ...state, mapData };
    return state;
}),
on(AppAction.mapResetCurrentData, (state, { mapResetData }) => {
    state = { 
      ...state, 
      mapResetData: {
        latLng: userCurrentLocation.latLng ?? initialCoordinates.latLng,
        zoom: userCurrentLocation.zoom ?? initialCoordinates.zoom
      }
    };
    return state;
}),
);
