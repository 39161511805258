import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceServiceProfile {

  API_BASE_URL: string;
  access_token: string | null;
  api_header: any;
  qrCode_api_header: any;
  infoUrl:string='';
  proxyEndpoint:string = environment.proxyUrl
  constructor(
    private http: HttpClient
  ) {
    this.API_BASE_URL = environment.baseURL;
    this.access_token = localStorage.getItem('Token');
   }

  getAliasList(){
    const url=`${this.proxyEndpoint}/getProf/api/v2/profile`
    return this.http.get(url, {observe: 'response', withCredentials: true});
  }
  getMemberList(groupName:any){  // neds to be checked BE
    const url=`${this.proxyEndpoint}/callevnt/api/v2/profile/members?groupname=${groupName}`
    return this.http.get(url, {observe: 'response', withCredentials: true});
  }

  postMember(payload:any){
    const url=`${this.proxyEndpoint}/callevnt/api/v2/profile/members`
    return this.http.post(url,payload,{observe: 'response', withCredentials: true});
  }

  switchProfile(payload?:any,isSwitched?:boolean,profileNeedToSwitch?:any){
    if(payload?.groupName){
    const url=`${this.proxyEndpoint}/postProf/api/v2/profile`
    return this.http.post(url,payload, {observe: 'response', withCredentials: true});
    }
    else{
      let cred = {observe: 'response', withCredentials: true}
      const url=`${this.proxyEndpoint}/postProf/api/v2/profile?switchprofile=${isSwitched}&updatetogroup=${profileNeedToSwitch}`
      return this.http.post(url,{}, {observe: 'response', withCredentials: true});
  }
    }
    
  getCarDetails(vinNumber:string){
    const url=`${this.proxyEndpoint}/callevnt/api/v2/profile/cars/${vinNumber}/info`
    return this.http.get(url,{observe: 'response', withCredentials: true});
  }

  addCarToFleet(reqBody:any){
    // send profileId in headers
    const url=`${this.proxyEndpoint}/callevnt/api/v2/profile/cars`
    return this.http.post(url,reqBody, {observe: 'response', withCredentials: true});
  }
  
  getFleetList(): Observable<any>{
    // send profileId in headers
    const url=`${this.proxyEndpoint}/callevnt/api/v2/profile/cars`
    return this.http.get(url, {observe: 'response', withCredentials: true});
  }

  updateProfile(aliasFormData:any,selectedFile:any){
    
    const formData=new FormData()
    formData.append('data',
    new Blob([JSON.stringify(aliasFormData)])
    );
    formData.append('profileImage',selectedFile)
    const url=`${this.API_BASE_URL}/api/v2/profile/manage`
    return this.http.put(url,formData);
  }

  deleteCarFromFleet(vin:string){
    const url=`${this.proxyEndpoint}/callLocApi/api/v2/profile/cars/${vin}`
    return this.http.delete(url, {observe: 'response', withCredentials: true});
  }
  
}
