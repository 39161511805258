import { Component, Input, OnInit } from "@angular/core";
import { LocationsHttpService } from "../../services/locations-http.service";
import { DbService } from './image.db';
import { RandomNumberGeneratorService } from "src/app/shared/service/random-number-generator.service";

@Component({
  selector: "app-blob-image",
  templateUrl: "./blob-image.component.html",
  styleUrls: ["./blob-image.component.scss"],
})
export class BlobImageComponent implements OnInit {
  private emptyImageSrc = "assets/no-icon.PNG";

  public imgSrc:any;
  public isImageLoaded = false;
  public isVideoLoaded = false;
  public isMediaLoaded = false;
  fullscreenDiv:any;
  count = 0;
  videoUrl: any;
  imageUrl: any;
  videoSrc: any;
  video: any;
  urlCount: any;
  
  public backgroundImageURL$:any;
  public savedBlob:any;
  @Input() public url: any;
  @Input() public totalMediaCount: any;
  @Input() public imageType = "thumbnail";
  @Input() public isUploadMediaEnabled = false;
  @Input() showMediaCount: boolean =true;
  @Input() isGalleryOPened: boolean =false;
  
  public blobUrl:any;
  public isMediaCachedValue:any;
  public mediaCacheSubscription:any;
  
  
  divId = "id" + this.createUUID();
  public image: any;

  constructor(private locationService: LocationsHttpService,private db: DbService, private randomNumberService: RandomNumberGeneratorService) {
  }

  async ngOnInit(){
    let imageSrc:any=''
    this.isMediaCachedValue="";
    if (this.totalMediaCount > 8 && !this.isUploadMediaEnabled) {
      console.log( this.count," this.count");
      this.count = this.totalMediaCount - 8;
      console.log( this.count," this.count after");
    }
     imageSrc= await this.db.getMedia(this.url,this.imageType);
     
     if (this.imageType == "thumbnail") {
      this.showBlobMedia(imageSrc.thumbBlob);
    } else if (this.imageType == "fullscreen") {
      this.showBlobMedia(imageSrc.originalBlob);
    }
     

  }
  createUUID() {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
       (c) => {
        let r = (dt + this.randomNumberService.randomNumber()) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }
 
  ngOnChanges() {
    if (this.isUploadMediaEnabled && this.totalMediaCount > 7) {
      this.count = this.totalMediaCount -7 ;   
      console.log( this.count," this.count in ngonchages");
    }
  }

  showBlobMedia(src:any){
    this.isMediaLoaded = true;
    if (this.url.type.toLowerCase() == "image") {
      this.isImageLoaded = true;
      this.imgSrc=src ?? this.emptyImageSrc;
      if(this.imgSrc){
      this.isMediaLoaded = false;
            this.isImageLoaded = false;
      }
    }
    else{
      this.isVideoLoaded = true;
      this.video = document.getElementById(this.divId);
      this.video.src =src?? this.emptyImageSrc;
      this.videoSrc=src;
      if( this.videoSrc){
      this.isMediaLoaded = false;
      this.isVideoLoaded = false;
      }
    }
  }
  ngOnDestroy() {
    if(this.mediaCacheSubscription){
      //Empty block
    }
  }
}
