import { AppConstants } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from 'src/app/modules/locations/interfaces/locations-constants';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { FullScreenMapModalComponent } from "../../components/full-screen-map-modal/full-screen-map-modal.component";
import { LocationsHttpService } from "../../services/locations-http.service";
import {
  CircuitListService,
  TrackLayoutAction,
} from "../../services/circuit-list/circuit-list.service";
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { DbService } from "../../components/blob-image/image.db";
import { TranslationService } from "src/app/shared/service/translation.service";
import { CustomTranslationPipe } from "src/app/shared/pipes/custom-translation.pipe";
import { Location } from "@angular/common";
import { LanguageService } from "src/app/shared/service/language.service";

@Component({
  selector: "app-layout-details",
  templateUrl: "./layout-details.component.html",
  styleUrls: ["./layout-details.component.scss"],
})
export class LayoutDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  public trackDetails: any;
  public encodedTrackPoints: any;
  public encodedSectorPoints: any = [];
  public trackName = "";
  public trackLength = 0;
  public mediaUrls: any = [];

  // public VideoUrls:any=[];
  public selectedMedia: any = [];
  public apiKey = environment.apikey;
  public isEditClicked: any = false;
  public secondaryBtnAction: any;
  public count: any = 0;
  paramTrackGuid: any;
  circuitName: any;
  urls: any;
  trackDetailsName: string = "";
  showToast = false;
  toastMessage = "";
  isSuccess = false;
  toastType: any;
  position: any;
  leftPosition: any;
  showNotFoundPage: any;
  urlCircuitGuid: any;
  isModalOpen: any;
  title: any;
  message: any;
  cancelBtnTitle: any;
  actionBtnTitle: any;
  actionType: any;
  isFormDataReverted = false;
  screenHeight: any;
  screenWidth: any;
  mapHeight:any;
  public isDisablePrimaryButton:boolean=false;

  public selectedImgIndex: any;
  public showGallery: boolean = false;
  translationData: any;
  phrasePipe: any;
  language: string = '';
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;

  placeholderObject = {
    placeholderTrackType : 'Circuit'
  }
  imageUrls: any[] = [];
  location_constants = LOCATIONS_CONSTANTS;
  public showCopyrightData: boolean = false;
  public deletedImageIds: string[] = [];

  constructor(
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    public dialog: MatDialog,
    private circuitListService: CircuitListService,
    private route: ActivatedRoute,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private mediaCarouselService: MediaCarouselService,
    private changeDetectorRef:ChangeDetectorRef,
    private db: DbService,
    private translationService: TranslationService,
    private location: Location,
    private languageService: LanguageService
  ) {
    this.db.openDb();
    this.route.paramMap.subscribe((paramMap: any) => {
      this.urlCircuitGuid = paramMap.get("guid");
      let trackGuid: any = paramMap.get("trackGuid");
      this.paramTrackGuid = trackGuid;
    });
  }

  public primaryButtonTitle = LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_PUBLISH;
  public secondaryButtonTitle = LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_DRAFT;
  public primaryButtonColor = "primary";
  public isApiCallDispatched = false;

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1024) {
      this.mapHeight = '193px';
    } else {
      this.mapHeight = '272px';
    }
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.phrasePipe = new CustomTranslationPipe;
    this.trackLayoutAction();
    this.getTrackDetails();
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }

  ngAfterViewInit() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 1024) {
      this.mapHeight = '193px';
    } else {
      this.mapHeight = '272px'
    }
  }

  public onImageUpload(event: any) {
    const file:File = event.target.files[0];
    const imageValidity = this.mediaCarouselService.checkFilesValidation([file])
    if (!imageValidity.isValidFiles) {
      this.showToastErrorMsg(imageValidity.toastDetails?.toastType, imageValidity.toastDetails?.message)
      return
    }
    if (this.imageUrls.length > 0) {
      this.onRemoveImage();
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imageUrls.push(reader.result);
    };
    const selectedImages = [];
    selectedImages.push(file);
    this.locationsHttpService.clearMediaValue();
    this.locationsHttpService.sendMediaValue(selectedImages);
    this.showCopyrightData = true;
  }

  /**
   * @description Called when we click on Change button or when we click on Delete image button
   */
  public onRemoveImage() {
    if (this.imageUrls[0].id) {
      this.deletedImageIds.push(this.imageUrls[0].id);
      this.showCopyrightData = false;
    }
    this.locationsHttpService.clearMediaValue();    
    this.imageUrls.pop();
    this.showCopyrightData = false;
  }

  public showToastErrorMsg(type: any,  message: string): void {
    this.toastService.show(type, message, "8%", "40%");
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }

  /**
   * @function getTrackDetails
   * @returns void
   * @description subscribing to an event to decide the button color, title on to UI
   */
  trackLayoutAction() {
    this.circuitListService.trackLayoutAction.subscribe(
      (action: TrackLayoutAction) => {
        switch (action) {
          case TrackLayoutAction.New: {
            this.isEditClicked = true;
            this.primaryButtonColor = "primary";
            this.primaryButtonTitle = LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_PUBLISH;
            this.secondaryButtonTitle = LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_DRAFT;
            break;
          }
          case TrackLayoutAction.Edit: {
            this.primaryButtonColor = "secondary";
            this.primaryButtonTitle = this.buttonNames.EDIT;
            this.secondaryButtonTitle = this.buttonNames.DELETE;
            break;
          }
          case TrackLayoutAction.Update: {
            this.isDisablePrimaryButton=true;
            this.primaryButtonColor = "primary";
            this.primaryButtonTitle = AppConstants.COMMON_BUTTON_LABELS.UPDATE;
            this.secondaryButtonTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
            break;
          }
          default: {
            break;
          }
        }
      }
    );
  }

  downloadTrackDetails() {
    this.locationsHttpService
      .downloadTrackDetails(this.trackDetails.guid)
      .subscribe((data: any) => {
        const blob = new Blob([data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = this.trackDetails.name;
        anchor.href = url;
        anchor.click();
      });
  }

  openFullScreenView() {
    this.dialog.open(FullScreenMapModalComponent, {
      data: {
        encodedTrackPoints: this.encodedTrackPoints,
        encodedSectorPoints: this.encodedSectorPoints,
        trackLength: this.trackLength,
      },
      backdropClass: "backdrop-background",
      width: "1341px",
      height: "800px",
      panelClass: "full-screen-map",
    });
  }
  navigateBack() {
    this.router.navigateByUrl(
      "/locations/circuit/" + this.trackDetails.circuitGuid
    );
  }

  /**
   * @function secondaryButtonClick
   * @returns void
   * @description called when Cancel button is clicked
   */
  secondaryButtonClick() {
    this.isFormDataReverted = false;
    switch (this.circuitListService.trackLayoutAction.value) {
      case TrackLayoutAction.New: {
        this.isEditClicked = false;
        this.circuitListService.trackLayoutAction.next(TrackLayoutAction.Edit);
        break;
      }
      case TrackLayoutAction.Update: {
        
        this.isModalOpen = true;
        this.title=this.phrasePipe.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData)
        this.message=this.phrasePipe.transform("BP_LM_confirmModal_subtitle", this.translationData)
        this.cancelBtnTitle=this.buttonNames.NO;
        this.actionBtnTitle=this.buttonNames.YES;
        this.actionType = "revert";
        
        break;
      }
      case TrackLayoutAction.Edit: {
        
        this.isModalOpen = true;
        this.isModalOpen = true;
        this.title = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_DELETE;
        this.message = "Are you sure you want to delete this track layout?";
        this.cancelBtnTitle = "Cancel";
        this.actionBtnTitle = "Delete";
        this.actionType = "delete-track";
        
        break;
      }
    }
  }

  /**
   * @function getCancelModalResponse
   * @param event - accepts an event of type {action: boolean}
   * @description decides whether to navigate back to prev screen or remain on same screen using Yes/No buttons on popup
   */
  getCancelModalResponse(event: any) {
    this.isModalOpen = false;
    if (event.action && event.actionType == "delete-track") {
      this.isApiCallDispatched = true;
      this.locationsHttpService.deleteTrack(this.trackDetails.guid).subscribe({
        next: () => {
          this.navigateBack();
          this.isModalOpen = false;
          this.isApiCallDispatched = false;
        },
        error: (_error: any) => {
          this.navigateBack();
          this.isModalOpen = false;
          this.isApiCallDispatched = false;
        },
      });
    } else if (event.action && event.actionType == "revert") {
      this.circuitListService.trackLayoutAction.next(TrackLayoutAction.Edit);
      this.isFormDataReverted = true;
      this.isModalOpen = false;
      this.isEditClicked = false;
      this.locationsHttpService.sendMediaValue([]);
      this.trackLayoutAction();
      this.getTrackDetails();
    } else if (event.action && event.actionType == "goToCircuits") {
      this.router.navigateByUrl(this.urls.circuitUrl);
    } else if (event.action && event.actionType == "goToLocations") {
      this.router.navigateByUrl("/locations");
    }
  }

  /**
   * @function primaryButtonClick
   * @return void
   * @description Called when Edit/Save and Publish button is clicked in UI
   */
  primaryButtonClick() {
    switch (this.circuitListService.trackLayoutAction.value) {
      case TrackLayoutAction.New: {
        this.isEditClicked = false;
        this.circuitListService.trackLayoutAction.next(TrackLayoutAction.Edit);
        break;
      }
      case TrackLayoutAction.Update: {
       
        break;
      }
      case TrackLayoutAction.Edit: {
        this.isEditClicked = true;
        this.circuitListService.trackLayoutAction.next(
          TrackLayoutAction.Update
        );
        this.changeDetectorRef.detectChanges();
        break;
      }
    }
  }

  getTrackDetails() {
    let count:any=0;
    this.locationsHttpService.getTrackDetails(this.paramTrackGuid).subscribe({
    next:  (response) => {
      let data = response.body
        this.trackDetails = data;
        this.location.replaceState("/" + this.language+ "/locations/circuit/"+ this.trackDetails.circuitGuid+"/layout/"+this.trackDetails.guid);

        if (this.urlCircuitGuid !== this.trackDetails.circuitGuid) {
          this.showNotFoundPage = true;
        }
        
        if (this.trackDetails.track) {
          this.encodedTrackPoints = this.processTrackPoints(this.trackDetails.track.geometry.coordinates);
            ;
        } else {
          this.encodedTrackPoints = this.trackDetails.encodedGpsPoints;
        }
        this.mediaUrls = [];
        // Do we need this code?
        this.trackDetails.media?.image?.map((image: any, _index: any) => {
          let imageObj: any = {
            originalUrl: image.originalUrl,
            thumbnailUrl: image.thumbnailUrl,
            id: image.id,
            type: "image",
            count: count,
            guid: this.trackDetails.guid,
          };
          this.mediaUrls.push(imageObj);
          count = count + 1;
        });
        // BETRACKPRO-18495: Copyright info for tracks; support only 1 image
        if (this.trackDetails.media?.image) {
          this.imageUrls.push(this.trackDetails.media.image[0]);
          this.showCopyrightData = true;
        }
        this.trackDetails.media?.video?.map((video: any, index: any) => {
          let vedioObj: any = {
            originalUrl: video.originalUrl,
            thumbnailUrl: video.thumbnailUrl,
            id: video.id,
            type: "video",
            count: count,
            guid: this.trackDetails.guid,
          };
          this.mediaUrls.push(vedioObj);
          count=count+1;
        });
        this.mediaCarouselService.shareMediaUrls.next(this.mediaUrls);
        this.encodedSectorPoints = this.trackDetails.sectorPoints;
        this.trackName = this.trackDetails.name;
        this.trackLength = this.trackDetails.trackLength / 1000;
      },
     error: (error: any) => {
        if (error.status === 406) {
          this.showNotFoundPage = true;
        }
        // this.router.navigateByUrl("/locations/not-found")
      }
    }
    );
  }

  private processTrackPoints(track: any): any[] {
    const trackPoints: any[] = track.map((point: any) => {
      return [point[0], point[1]];
    });
    return trackPoints;
  }
  
  /**
   * @function updatedTrackDetails
   * @param event - accepts an object that has name, sector points, guidId, startpoint, tracklength, startline...
   * @description called when we click on cancel and Yes from Cancel Popup
   * @returns void
   */
  updatedTrackDetails(event: any) {
  
    this.encodedSectorPoints = event.sectorPoints;
    this.trackName = event.name;
    this.trackDetailsName = event.name;
    this.trackDetails.name = event.name;
  
    this.isEditClicked = false;
    this.circuitListService.trackLayoutAction.next(TrackLayoutAction.Edit);
  }
  
  setUpdateFlagValue(event:any){
    if(event.enableUpdateButton){
      this.isDisablePrimaryButton=false;
    }
  }
  ngOnDestroy() {
    this.trackDetails = null;
    this.circuitListService.trackLayoutAction.next(TrackLayoutAction.Edit);
  }
}
