import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Amplify, Auth} from 'aws-amplify';
import { environment } from 'src/environments/environment';



@Injectable()

export class AuthAmplifyService {

  isUserLoggedIn = false;
  loggedInUserInfo: any = {};
  userAuthTokenData: any;
  auth = Auth;
  proxyUrl:string = environment.proxyUrl

  constructor(private http: HttpClient) {
    this.config();
  }

  config() {
    // Amplify.configure({
    // Auth:environment.amplifyConfig
    // }
    //   );
  }

  proxyLogin(){
    const authUrl = this.proxyUrl+'/auth';
    // window.location.href = authUrl;
    return this.http.get<any>(authUrl);
  }

  // loginResponse(){
  //   this.proxyLogin().subscribe(response => {
  //     alert('success')
  //     console.log(response);
  //   });
  // }

  callback(code: string) {
    const url = this.proxyUrl+'/callback?code='+code;
    // return this.http.get(url); 
    return this.http.get<any>(url, {observe: 'response', withCredentials: true});
  }

  async init(): Promise<any>{
  //   this.auth.currentAuthenticatedUser().then(
  //     (data:any) => {
  //         const userData = this.getCurrentUserToken();
  //         if (!userData?.length) {
  //             this.storeCurrentSessionData();
  //         }
  //         return true;
  //     },
  //     (res:any) => {  
  //         console.log('it goes to can acrive error block');
  //         return false;
  //     }
  // )
  }

  async storeCurrentSessionData() {
    // await this.currentSession().then((res:any) => {
    //   let accessToken = res.getAccessToken()
    //   let jwt = accessToken.getJwtToken();
    //   let jwt_expires_in = accessToken.getExpiration();
    //   let refreshToken = res.getRefreshToken();
    //   let jwtRefreshToken = refreshToken.getToken();
    //   let idToken = res.getIdToken();
    //   let jwtIdToken = idToken.getJwtToken();
    //   let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
    //   this.setUserLoginStatus(true);
    //   this.setCurrentUserToken(fullData);
    //   return fullData;
      
    // })
  }

  async signIn() {
    await this.auth.federatedSignIn({customProvider: 'IAMProvider'})
  } 

  logout() {
    localStorage.removeItem('profileImage');
    localStorage.removeItem('profileName');
    localStorage.removeItem('aliasGroupName');
    localStorage.removeItem("profile");
    localStorage.removeItem("isProfileChanged");
    localStorage.removeItem("loggedIn");

    const authUrl = this.proxyUrl+'/logout';
    // window.location.href = authUrl;
    return this.http.get<any>(authUrl, {observe: 'response', withCredentials: true});
  }

  currentSession() {
    return this.auth.currentSession();
  }

  getUserLoginStatus() {
    return this.isUserLoggedIn;
  }

  setUserLoginStatus(status: boolean) {
    this.isUserLoggedIn = status;
  }
  
  setCurrentUserToken(data: any) {  
    this.userAuthTokenData = data;
    // localStorage.setItem('Token', data.access_token);
  }

  getCurrentUserToken() {  
    return this.userAuthTokenData;
  }
}