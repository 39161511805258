import { Component, EventEmitter, Input,  OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslationService } from '../../service/translation.service';
import { CircuitsServicesService } from 'src/app/modules/locations/services/circuits-services.service';
import { LOCATIONS_CONSTANTS } from 'src/app/modules/locations/interfaces/locations-constants';
import { ToastService, ToastType } from '../../service/toast.service';
import { ASSET_TYPES, ETOUR_CREATION_STEPS } from 'src/app/modules/locations/interfaces/business-tour-create.interface';
import { AppConstants } from '../../constants/constants';
import { MediaCarouselService } from 'src/app/modules/locations/services/media/media-carousel.service';
import { take, takeUntil } from 'rxjs';
import { CopyrightRequestWithMedia, Media } from 'src/app/modules/locations/interfaces/amg-annotation.interface';

@Component({
  selector: 'app-common-media-upload',
  templateUrl: './common-media-upload.component.html',
  styleUrls: ['./common-media-upload.component.scss']
})
export class CommonMediaUploadComponent implements OnInit {
 
  public translationData: any;
  public mediaFiles: string[] = [];
  public mediaFilesOverlay: string[] = [];
  public showOverlayFlag: boolean = false;
  public isMediaDisable: boolean = true;
  public selectedLocation: any = null;
  public markFieldsAsRequired: boolean = false;
  public commonLables = AppConstants.COMMON_LABELS;

  // All Input and Output declarations go here
  @Input() currentStep: string="";
  @Input() tourCoverImg: string = "";
  @Input() markMediaFieldsAsRequired: boolean=false;
  @Input() saveImgWithCopyright: boolean = false;

  @Output() mediaData= new EventEmitter<any>();
  @Output() mediaFilesUpdated = new EventEmitter<number>();

  @ViewChild('myFileInput', {static: false}) myFileInput: any;


  constructor(
    private translationService: TranslationService,
    private mediaService: CircuitsServicesService,
    private toastService: ToastService,
  private _mediaCaroselService: MediaCarouselService) { }
    
 
  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.loadCoverImageThumbnails();
    // this.checkForImage();
    this.loadAnnotationImageThumnails()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.saveImgWithCopyright?.currentValue) {
      this.displayMediaThumbnailsOnConfirm();
    }
  }

  /**
   * @description Called when media is uploaded, checks validation and displays image
   * @param event 
   * @returns void
   */
  public uploadMediaOnClk(event: any): void {
    const files = event.target.files;
    if (files) {  
      const imageValidity = this._mediaCaroselService.checkFilesValidation(files);   
      if (imageValidity.isValidFiles) {
        this._mediaCaroselService.processImageUploaded(event.target.files, false);
        this.mediaData.emit([...event.target.files]);             
      } else {
        this.showToastErrorMsg(imageValidity.toastDetails?.toastType, imageValidity.toastDetails?.message)
      }   
    }    
  }

  public showMediaGallery(): void {
    this._mediaCaroselService.copyrightInfoDialog = {
      showModal: true,
      saveData: false,
      updateCopyrightOnFileSelect: false
    };
  }

  /**
   * @description Called when user has confirmed all his images from copy right dialog
   */
  public displayMediaThumbnailsOnConfirm(): void {
    this.mediaFiles = this.mediaFilesOverlay = [];
    this.showOverlayFlag = false;
    const userAddedMedia = this._mediaCaroselService.getImagesWithCopyRightData();
    if (!this.showOverlayFlag) {
      if (!this.mediaFiles[0] || this.mediaFiles.length > 0 && this.mediaFiles.length < 3) {
        this.showMediaWithoutOverlay(userAddedMedia);
      } else {
        this.showMediaThumbnailsWithOverlay(userAddedMedia, 3);
      }
    } else {
      this.showMediaThumbnailsWithOverlay(userAddedMedia, 3);
    }
  }

  /**
   * @description If only 3 images are there show the 3 images as 3 boxes. If number of images exceed 3, 
   * Show 3 boxes with first 2 images and 3rd box will have an image with overlay and number of files remaining
   * @param selectedMedia 
   */
  public showMediaWithoutOverlay(selectedMedia: {media: string, copyrightData: any[]}[]): void {
    this.mediaFiles = selectedMedia.slice(0, 3).map((media) => media.media);
    if (this.mediaFiles.length >= 3) {
      this.showMediaThumbnailsWithOverlay(selectedMedia, 3);
    }
  }

  /**
   * @description To push images into the 3rd box and cover it with an overlay.
   * Additionally display the number of images
   * @param selectedMedia 
   * @param startingIndex starts with 3 always b'cause 3rd box has the overlay
   */
  public showMediaThumbnailsWithOverlay(selectedMedia: {media: string, copyrightData: any}[], startingIndex: number): void {
      this.mediaFilesOverlay = selectedMedia.slice(startingIndex).map((media) => media.media);
      if (this.mediaFilesOverlay.length > 0) { 
        this.showOverlayFlag = true;
      }
  }

  /**
   * @description Loads images while editing a AMG annotation
   */
  public loadAnnotationImageThumnails(): void {
    if (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS || this.currentStep === ETOUR_CREATION_STEPS.EDIT_START || this.currentStep === ETOUR_CREATION_STEPS.EDIT_DESTINATION) {
      this.mediaService.mediaValueForAnnotation$.pipe(take(2)).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.populateMediaForGallery(data);
          this.displayMediaThumbnailsOnConfirm();
        } else {
            this.mediaFiles = [];
            this.mediaFilesOverlay = [];
            this._mediaCaroselService.resetMediaWithCopyRightData();
        }
      });
    }
  }

  /**
   * @description shows images in the left panel for cover image
   */
  public loadCoverImageThumbnails(): void {
    if (this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH) {
      this.mediaService.mediaValueForTrailCover$.pipe(take(1)).subscribe((data: any[]) => {
        if (data) {
          this.populateMediaForGallery(data);
          this.displayMediaThumbnailsOnConfirm();
        } else {
          this.mediaFiles = [];
          this.mediaFilesOverlay = [];
          this._mediaCaroselService.resetMediaWithCopyRightData();
        }
      });
    }
  }

  /**
   * @description Called to populate the media files for the copyright dialog
   * This is called for both cover image and annotation images. Called when the
   * panel changes based on current step
   * @param mediaFiles 
   */
  public populateMediaForGallery(mediaFiles: Media[]): void {
    this._mediaCaroselService.resetMediaWithCopyRightData();
    for (let i = 0; i < mediaFiles.length; i++) {
      const mediaWithCopyrightInfo: CopyrightRequestWithMedia = {
        id: mediaFiles[i].id,
        originalMedia: mediaFiles[i].url,
        media: mediaFiles[i].url,
        copyRightData: mediaFiles[i].copyright ?? <any>null
      }
      this._mediaCaroselService.setImagesWithCopyRightData(mediaWithCopyrightInfo);
    }
  }

  public showToastErrorMsg(type: any,  message: string): void {
    this.toastService.show(type, message, "8%", "40%");
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }
  
  public checkIsInTrackUpdateState(): boolean {
    return ((this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED ||this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED || this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH
  ));

  }

}
