import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, lastValueFrom } from "rxjs";
import { LocationsHttpService } from "../../services/locations-http.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { EALL_TRACKS, ESEARCH_PAGE_STRING, ISearchResponse } from "../../interfaces/search-location.interface";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { RecentSearch } from "../../interfaces/business-tour-create.interface";
import { CircuitsServicesService } from "../../services/circuits-services.service";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnChanges {
  searchTerm: UntypedFormControl = new UntypedFormControl();
  @Input() public placeholderText: any = "";
  @Input() public showCircuitName: any;
  @Input() public showAutoList = 0;
  @Input() public type: any;
  @Input() public page: any;
  @Input() public isCreateEmotionTour = false;
  @Input() public selectedTerm: any = "";
  @Input() public showAnnotationsSearch = false;
  @Input() public enableHightlight = false;
  @Input() public searchId = "searchInput";
  @Input() public isDisabled = false;
  @Input() selectedLocationName: string = '';


  @Output() public showSearchedLocation = new EventEmitter<any>();
  @Output() public emitChangeInput = new EventEmitter<object>();
  @Output() public emitSearchResults = new EventEmitter<any[]>();
  @Output() public searchCancelledForEt = new EventEmitter<boolean>();

  @ViewChild('locationSearchFocus') locationSearchFocus!: ElementRef;

  showSearchResult = true;
  searchResult: any = [];
  quickSearchData: any = [];
  selectedValue = "";
  listIcon: string = "";
  isNoResult: any;
  showSearch = true;
  translationData: any;
  public recentSearchData: RecentSearch[] = [];
  showRecentSearch: boolean = false;
  private _isLeftPanelVisible: any;
  constructor(
    private locationsHttpService: LocationsHttpService,
    private translationService: TranslationService,
    private _mediaCarouselService: MediaCarouselService,
    private _circuitService: CircuitsServicesService
  ) {}

  ngOnInit(): void {  
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );  
    this.callSearchApiConditinally();
  }

  public callSearchApiConditinally(): void {
    switch (this.page) {
      case ESEARCH_PAGE_STRING.ADD_NEW_CIRCUIT:
        this.searchTerm.valueChanges
          .pipe(debounceTime(500))
          .subscribe((term: string) => {
            this.callGeoJsonApi(term, ESEARCH_PAGE_STRING.ADD_NEW_CIRCUIT);
          });
        break;
      case ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR:
        this.searchTerm.markAsTouched();
        if (this.selectedTerm) {
          this.searchTerm.setValue(this.selectedTerm);
          this.selectedValue = this.selectedTerm;
        }
        this.searchTerm.valueChanges
          .pipe(debounceTime(500))
          .subscribe((term: string) => {
            this.callGeoJsonApi(term, ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR);
          });
        break;
      default:
        this.searchTerm.valueChanges
          .pipe(debounceTime(200))
          .subscribe((term: string) => {
            this.showSearchResult = true;
            this.isNoResult = false;
            if (term && this.type && term.length > 2) {
              this.searchLocationsBasedOnFilter(term);
            } else if (term.length === 0) {
              this.quickSearchData = [];
            }
          });
        break;
    }
  }

  public callGeoJsonApi(term: string, selectedType: string): void {
    this.showSearchResult = true;
    this.showSearch = true;
    this.isNoResult = false;
    if (this.checkTermExists(term)) {
      this.getGeoSearchAddressData("48,9", 5, "en", term);
      if (selectedType === ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR) {
        this.recentSearchData = this._mediaCarouselService.recentSearchHistory
                                .filter((value: RecentSearch) => value.name?.toLowerCase().startsWith(term.toLowerCase()));
      }
    } else {
      this.quickSearchData = [];
      this.recentSearchData = [];
      if (selectedType === ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR && !this.selectedLocationName) {
        this.recentSearchData = this._mediaCarouselService.recentSearchHistory;
      }
    }
  }

  public searchLocationsBasedOnFilter(searchValue: string): void {
    this.searchCircuits(10, 0, searchValue, this._circuitService.checkIsCircuits(this.type), this._circuitService.checkIsEmotionTour(this.type), this._circuitService.checkIsOffroad(this.type)); 
  }

  public checkTermExists(term: string): boolean {
    return term !== '' && this.page && term !== this.selectedLocationName
  }

  ngOnChanges() {
    if(this.enableHightlight && !this.showAnnotationsSearch) {
      this.getFocus(this.searchId);
    } else {
      this.loseFocus(this.searchId);
    }
    if(this.page === ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR) {
      if(this.selectedTerm) {        
        this.showSearchResult = false;
        this.selectedValue = this.selectedTerm;        
      }      
    }      
    
    if (this.searchTerm.value) {
      // BETRACKPRO-13636: Landing page, this.page is undefined. It has to call searchLocations
      // in landing page and GeoSEarch API should be called in Circuit Creation page
      if (this.page && this.page != ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR) {
        this.getGeoSearchAddressData("48,9", 5, "en", this.searchTerm.value);
      } else {
        this.searchLocationsBasedOnFilter(this.searchTerm.value);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.locationSearchFocus?.nativeElement.focus();
    }, 0);
  }

  async searchCircuits(size: number, pageNo: number, name: string, circuits: boolean, emotionTour: boolean, showOffroad: boolean) {
    this.isNoResult = false;
    const response: any = this.locationsHttpService.gobalSearch(size, pageNo, name, circuits, emotionTour, showOffroad)
    this.isNoResult = false;
    if (response) {
      const searchData:any = await lastValueFrom(response)
      const searchResultPromise: ISearchResponse = searchData.body;
      this.searchResult = searchResultPromise.locations;
      this.isNoResult = this.searchResult.length == 0;
      this.quickSearchData = this.searchResult?.slice(0, 5).map((item: any) => {
        switch(item.type) {
          case EALL_TRACKS.CIRCUIT:
            item.Icon = "assets/locations/circuitpin.svg";
            break;
          case EALL_TRACKS.EMOTION_TOUR:
            item.Icon = "assets/locations/emotionpin.svg";
            break;
          case EALL_TRACKS.OFF_ROAD:
          case EALL_TRACKS.OFFROAD_TRACK:
            item.Icon = "assets/locations/offroad-pin.svg";
            break;
        }
        return item;
      });
      this.emitSearchResults.emit(this.quickSearchData);
    }
    return this.searchResult;
  }

  /*async searchDealerAnnotation(
    size: any,
    pageNo: any,
    name: any,
    query:any
  ) {
    let response: any = this.locationsHttpService
      .dealerAnnotationSearch(size, pageNo, name, query)
    if (response) {
      this.quickSearchData = await lastValueFrom(response);
      console.log('search results from new ', this.quickSearchData)
      this.searchResult = response;
      if (this.searchResult.length == 0) {
        this.isNoResult = true;
      }
      
      this.emitSearchResults.emit(this.quickSearchData);
    }
    return this.searchResult;
  }*/

  /**
   * @description This API is called in Circuit Creation step
   * @param centerCoordinates 
   * @param limit 
   * @param lang 
   * @param term 
   */
  getGeoSearchAddressData(
    centerCoordinates: any,
    limit: number,
    lang: string,
    term: string
  ) {
    this.locationsHttpService
      .getGeoSearchAddress(centerCoordinates, limit, lang, term)
      .subscribe({
        next: (data) => {
          this.quickSearchData = data.items;
          if (this.quickSearchData?.length == 0) {
            this.isNoResult = true;
          }
        },
      });
  }

  SearchEmotionTour(term:any) {
           this.showSearch = true;
           this.isNoResult = false;
           if (this.checkTermExists(term)) {
             this.getGeoSearchAddressData("48,9", 5, "en", term);
           } else {
             this.quickSearchData = [];
           }
  }
  selectCircuit(circuit: any,tracks:any) {
    this.searchResult = [];
    this.selectedValue = circuit.name;
    this.showSearchResult = false;
    circuit.isSearch = true;
    if (circuit.type == EALL_TRACKS.CIRCUIT) {
      circuit.viewType = EALL_TRACKS.CIRCUIT;
    }
    if (circuit.type == EALL_TRACKS.EMOTION_TOUR) {
      circuit.viewType = EALL_TRACKS.EMOTION_TOUR;
    }
    circuit.selectedTrack=tracks;
    this.searchTerm.setValue("");
    console.log('circuit tt',circuit)
    this.showSearchedLocation.emit(circuit);

    this.selectedValue = "";
  }

  selectLocation(location: any) {
    this.searchResult = [];
    this.selectedValue = location.title;
    this.showSearchResult = false;
    this.searchTerm.setValue("");
    this.showSearchedLocation.emit(location);
    this.selectedValue = "";
    this.recentSearchData = [];
  }

  selectEmotionTourLocation(location: any) {
    this.searchResult = [];
    this.selectedValue = location.title;
    this.showSearchResult = false;
    this.showSearch = false;
    this.showSearchedLocation.emit(location);
  }


  emitChange() {
    this.emitChangeInput.emit();
  }

  getFocus(id:any) {
    document.getElementById(id)?.focus();
  }

  loseFocus(id:any) {
    document.getElementById(id)?.blur();

  }


  cancle() {
    if (!this.isDisabled) {
      this.searchResult = [];
      this.quickSearchData = [];
      this.searchTerm.setValue("");
      this.selectedValue = "";
      this.showSearchResult = false;
      this.showSearchedLocation.emit({});
      this.emitSearchResults.emit([]);
      if (this.page === ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR) {
        // this.selectedLocationName = ''
        this.searchCancelledForEt.emit(true);
        this.recentSearchData = this._mediaCarouselService.recentSearchHistory;
      }
    }

  }
}