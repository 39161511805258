import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EMEDIA_STATUS, ETOUR_CREATION_STEPS, RecentSearch } from '../../interfaces/business-tour-create.interface';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { CopyrightRequestWithMedia } from '../../interfaces/amg-annotation.interface';
import { AppConstants } from 'src/app/shared/constants/constants';
import { ToastType } from 'src/app/shared/service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class MediaCarouselService {
  
  public openModal = new BehaviorSubject<boolean>(false);
  public scrollToSelectedIndex = new BehaviorSubject<Object>({});
  public deleteSelectedMedia = new BehaviorSubject<any>([]);
  public callGetTrackDetails = new BehaviorSubject<boolean>(false);
  public shareMediaUrls = new BehaviorSubject<any>([]);
  public showAddButton= new BehaviorSubject<Object>({});
  public CancelCurrentStep = new BehaviorSubject<string>(ETOUR_CREATION_STEPS.SECOND);
  public highlightCount = new BehaviorSubject<number>(0);
  public hideContentOnLoadersPage = new BehaviorSubject<boolean>(false); 
  public startLocationButtonClicked = new BehaviorSubject<boolean>(false);
  public showPlusIconOnMapHover = new BehaviorSubject<boolean>(true);
  public waypointAdd = new BehaviorSubject<boolean>(false);
  public deleteAnnotationRedrawMap = new BehaviorSubject<any>('');
  private _highlights: string[] = [];
  private _annotationIds: string[] = [];
  private _isRoundTripSelected: boolean = false;
  private _viewInsdrSptDetailsSubject$ = new BehaviorSubject<boolean>(false);
  private _modalActionSubject$ = new BehaviorSubject<string>('');
  private _insiderSptDetails: any;
  private _recentSearch: RecentSearch[] = [];
  private _mediaWithCopyRightData: CopyrightRequestWithMedia[] = [];
  private _copyrightInfoDialog$ = new BehaviorSubject<{showModal: boolean, saveData: boolean, updateCopyrightOnFileSelect: boolean}>({showModal: false, saveData: false, updateCopyrightOnFileSelect: false});
  private _mediaData: any;

  /**
   * setter and getter that will have list of highlights incl start and destination
   * We can set, get and reset these values and communicate b/w diff components
   */
  public set highlightList(highlights: string[]) {
    this._highlights = [...this._highlights, ...highlights];
  }

  public get highlightList(): string[] {
    return this._highlights;
  }

  public set annotationIds(annotationIds: string[]) {
    this._annotationIds = [...this._annotationIds, ...annotationIds]
  }

  public get annotationIds(): string[] {
    return this._annotationIds;
  }

  public set isRoundTripSelected(roundTrip: boolean) {
    this._isRoundTripSelected = roundTrip;
  }

  public get isRoundTripSelected(): boolean {
    return this._isRoundTripSelected;
  }

  public set decideModalAction(action: string) {
    this._modalActionSubject$.next(action);
  }
  
  public get decideModalAction(): any {
    return this._modalActionSubject$.asObservable();
  }

  public set viewSelectedCardDetails(action: boolean) {
    this._viewInsdrSptDetailsSubject$.next(action);
  }
  
  public get viewSelectedCardDetails(): any {
    return this._viewInsdrSptDetailsSubject$.asObservable();
  }

  public set copyrightInfoDialog(action: {showModal: boolean, saveData: boolean, updateCopyrightOnFileSelect: boolean}) {
    this._copyrightInfoDialog$.next(action);
  }
  
  public get copyrightInfoDialog(): any {
    return this._copyrightInfoDialog$.asObservable();
  }


  public set insiderSptDetails(details: any) {
    this._insiderSptDetails = details;
  }

  public get insiderSptDetails(): any {
    return this._insiderSptDetails;
  }

  /**
   * @description setter and getter for recent searches. We will display top 5 recent searches to the user.
   * However, we store 10 most recent searches. These search results will be present as long as the user session is present
   */
  public set recentSearchHistory(recentSearch: RecentSearch) {
    this._recentSearch.unshift(recentSearch);
    if (this._recentSearch.length > LOCATIONS_CONSTANTS.MAX_SEARCH_RESULTS_TO_STORE) {
      this._recentSearch.pop();
    }
    sessionStorage.setItem('Recent_Search', JSON.stringify(this._recentSearch));
  }

  public get recentSearchHistory(): RecentSearch[] | any {
    const search = sessionStorage.getItem('Recent_Search');
    this._recentSearch = search ? JSON.parse(search): [];
    return this._recentSearch;
  }

  public setImagesWithCopyRightData(value: CopyrightRequestWithMedia) {
    if (!value.pre_signedUrl && value.id) {
      value.pre_signedUrl = {
        id: value.id,
        fileName: value.id,
        preSignedUrl: ''
      }
    }
    this._mediaWithCopyRightData.push(value);
  }

  public updateImagesWithCopyRightData(value: CopyrightRequestWithMedia[]) {
    this._mediaWithCopyRightData =  value
  }

  public getImagesWithCopyRightData(): any[] {
    return this._mediaWithCopyRightData;
  }

  public processImageUploaded(fileList: any[], isCopyrightModalOpen: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (_event: any) => {
                this.setImagesWithCopyRightData({originalMedia: file, media: reader.result, copyRightData: <any>null})
                if (i === fileList.length - 1 && !isCopyrightModalOpen) {
                  setTimeout(() => {
                    this.copyrightInfoDialog = {
                      showModal: true,
                      saveData: false,
                      updateCopyrightOnFileSelect: false
                    };
                    resolve();
                  }, 500)
                } else if (i === fileList.length - 1 && isCopyrightModalOpen) {
                    this.copyrightInfoDialog = {
                      showModal: true,
                      saveData: false,
                      updateCopyrightOnFileSelect: true
                    };
                    resolve();
                }
              }
            }
      }catch (error) {
        reject(error);
      }
    } )
  }

  public resetMediaWithCopyRightData(): void {
    this._mediaWithCopyRightData = [];
  }

  public updateTrackUpdateRequestWithCopyright(guid: string): any {
    let request: { guid: string; metadata: { image?: any[] } } = {
      guid: guid,
      metadata: {}
    };
    this._mediaWithCopyRightData.forEach((media: CopyrightRequestWithMedia) => {
      const imageData = [{
        id: media.pre_signedUrl?.id,
        name: media.pre_signedUrl?.fileName,
        status: EMEDIA_STATUS.SUCCESS,
        copyright: {
          attribution: media.copyRightData.attribution,
          license: media.copyRightData.license,
          license_url: media.copyRightData.license_url,
          subject_url: media.copyRightData.subject_url
        }
      }]
      if (!request.metadata['image']) {
        request.metadata['image'] = [];
      }
      request.metadata['image'].push(...imageData);
    });
    console.log('object :>> ', request);
    return request;
  }

  public checkFilesValidation(files: File[]): {isValidFiles: boolean, toastDetails: any} {
    let isValidFiles = true;
    let message: string = ''
    for (let i = 0; i < files.length; i++) {
      let fileExtension = files[i].name.split(".");
      let extension = fileExtension[fileExtension.length - 1];
      if (!LOCATIONS_CONSTANTS.ALLOWED_EXTENTIONS_IMG_UPLOAD.includes(extension)) {
        message = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ALLOWED_MEDIA_EXTENSION_MSG
        isValidFiles = false;
      } else if (files[i].size > AppConstants.MAX_IMAGE_SIZE) {
        message = AppConstants.COMMON_ERROR_MSGS.MAX_ALLOWED_IMG_SIZE
        isValidFiles = false;
      }
    }
    return {
      isValidFiles,
      toastDetails: {
        toastType: ToastType.Error,
        message
      }
    }
  }

  set geoJsonDataMediaForCover(media: any) {
    this._mediaData = media;
  }

  get geoJsonDataMediaForCover(): any {
    return this._mediaData;
  }

}
