<div class="toast-style" *ngIf="showToast">
  <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition">
  </app-toast>
  </div>
<div class="admin-console-wrapper group-members-main">
  <div class="web-portal-container">
    <div class="group-details">
      <div class="group-details-left">
        
        <div class="dp-profile">
        <div class="dp">
          <img src="{{ profileDetails?.profileImageUrl }}" alt="" />
        </div>
       
          <div *ngIf="members" class="group-name">
            <h4>{{ profileDetails?.profileName }}</h4>
            <span class="icon icon_info info" *ngIf="isIconInfoShown">
            </span>
          </div>
          </div>
          <p class="desc">
            {{profileDetails?.profileDescription}}
          </p>
          <div *ngIf="members" class="linkout-section">
            <p class="link">{{profileDetails?.businessWebsite}}</p>
            <a href="{{profileDetails?.businessWebsite}}" target="_blank" class="trans" rel="noopener">
              <span class="icon icon_redirect"></span>
            </a>
          </div>
      
      </div>
      <div class="edit-btn button-region">
        <button class="hallow" (click)="editProfile()" [disabled]="!enableEditProfile">
          <span class="icon icon_edit"></span>
          <span>{{buttonNames.EDIT | customTranslation:translationData}}</span>
        </button>
      </div>
    </div>

    <div class="list-header">
      <h5>{{'BP_PM_MEMBERS_LIST'| customTranslation:translationData }}</h5>
      <app-button [buttonClass]="'beta'" [isTitleVisible]="true" [title]="'BP_PM_ADD_MEMBER'" (functionEmitter)="addMember()" [isIconVisible]="true" [iconEleClass]="'icon icon_add'" [buttonId]="'add-member-button'"></app-button>
    </div>
    <div class="members-list">
      <div id="group-alias-usr-list" class="tbl-main">
        <div class="tbl-rw-gp tbl-hd">
          <div class="tbl-row race-card-data-container">
            <div class="tbl-cell"><span>{{'BP_EM_PARTICIPANTS_SLNO' | customTranslation:translationData}}</span></div>
            <div class="tbl-cell"><span>{{'BP_EM_NAME' | customTranslation:translationData}}</span></div>
            <div class="tbl-cell"><span>{{'BP_EM_PARTICIPANTS_ENTER_EMAIL' | customTranslation:translationData}}</span></div>
            <div class="tbl-cell"><span>{{'BP_PM_ACCESS_TYPE'| customTranslation:translationData}}</span></div>
            <div class="tbl-cell"><span>{{'BP_CM_ACTIONS'| customTranslation:translationData}}</span></div>
          </div>
        </div>
        <div *ngIf="members" class="tbl-rw-gp tbl-bd">
          <div
            class="tbl-row race-card-data-container"
            *ngFor="let item of members; let i = index"
          >
            <div class="tbl-cell">
              <span>{{ i + 1 }}</span>
            </div>
            <div class="tbl-cell">
              <span>{{ item.name }}</span>
            </div>
            <div class="tbl-cell">
              <span>{{ item.email }}</span>
            </div>
            <div class="tbl-cell">
              <span>{{ item.accessType }}</span>
            </div>
            <div class="tbl-cell">
              <div
                role="button"
                [class.menu-bar]="isMenuClicked && showIndex == i"
                (click)="menuClicked(i)"
                appClickOutside
                (clickOutside)="closeDialog()"
              >
                <span
                  [ngClass]="
                    isMenuClicked && showIndex !== i
                      ? 'icon-menu icon icon_kebab'
                      : 'icon icon_kebab'
                  "
                ></span>
              </div>
            </div>
            <ng-container *ngIf="isMenuClicked && showIndex == i">
              <div role="button" id="drop-down-menu">
                <ul>
                  <li>{{buttonNames.EDIT | customTranslation:translationData}}</li>
                  <li>{{buttonNames.DELETE | customTranslation:translationData}}</li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!members" >
          <ng-container *ngFor="let item of [].constructor(8)">
               <div class="tbl-row race-card-data-container">
                   <div class="tbl-cell">
                    <ngx-skeleton-loader animation="pulse"  [theme]="{width: '15px', height: '20px','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
                   </div>
                   <div class="tbl-cell">
                    <ngx-skeleton-loader animation="pulse"  [theme]="{width: '250px', height: '20px','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
                   </div>
                   <div class="tbl-cell">
                    <ngx-skeleton-loader animation="pulse"  [theme]="{width: '250px', height: '20px','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
                   </div>
                   <div class="tbl-cell">
                    <ngx-skeleton-loader animation="pulse"  [theme]="{width: '200px', height: '20px','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
                   </div>
                   <div class="tbl-cell">
                    <ngx-skeleton-loader animation="pulse"  [theme]="{width: '20px', height: '20px','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
                   </div>
               </div>
              </ng-container>
        </div>
        <ng-container></ng-container>
      </div>
    </div>

    <ng-container *ngIf="isModalOpen">
      <invite-member-form
      (closeModalEmitter)="closeModal()" (getMemberListData)="saveModal()"></invite-member-form>
    </ng-container>
    <ng-container *ngIf="editProfileClicked">
        <app-create-edit-profile (cancelClicked)="cancelClicked($event)"></app-create-edit-profile>
    </ng-container>
</div>
</div>
