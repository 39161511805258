export const translationsArray = [
    {
        "key": "BP_CM_ADD_BTN_LBL",
        "value": "Add"
    },
    {
        "key": "BP_CM_ADD_MEDIA",
        "value": "Add media"
    },
    {
        "key": "BP_CM_BACK_BTN_LBL",
        "value": "Back"
    },
    {
        "key": "BP_CM_BROWSE_BTN_LBL",
        "value": "Browse"
    },
    {
        "key": "BP_CM_CANCEL_BTN_LBL",
        "value": "Cancel"
    },
    {
        "key": "BP_CM_CHARACTERS",
        "value": "Characters"
    },
    {
        "key": "BP_CM_CONFIRM_BTN_LBL",
        "value": "Confirm"
    },
    {
        "key": "BP_LM_CLEAR_LBL",
        "value": "Clear"
    },
    {
        "key": "BP_CM_CONTINUE_BTN_LBL",
        "value": "Continue"
    },
    {
        "key": "BP_CM_DONE_BTN_LBL",
        "value": "Done"
    },
    {
        "key": "BP_CM_FILE_UPLOAD_SUCCESS",
        "value": "File uploaded successfully"
    },
    {
        "key": "BP_CM_PAGE_NOT_FOUND_MSG",
        "value": "Sorry, page not found!"
    },
    {
        "key":"BP_CM_SELECT_TRACK_LAYOUT",
        "value":"Please select circuit to select track layout"
    },
    {
        "key": "BP_EM_LARGEIMG",
        "value": "Upload image is too large. Please upload an image below 15mb and try again"
    },
    {
        "key": "BP_EM_ERR_LARGEIMG",
        "value": "Please upload a file with size less than 15Mb"
    },

    {
        "key": "BP_EM_DRIFT_CALCULATION",
        "value": "Drift score calculation"
    },
    {
        "key": "BP_EM_DRIFT_ANGLE",
        "value": "Average drift angle"
    },
    {
        "key": "BP_EM_DRIFT_DISTANCE",
        "value": "Percentage drifted distance"
    },
    {
        "key": "BP_EM_DRIFT_AVERAGE_ANGLE",
        "value": "Calculate the drift score based on the average drift angle"
    },
    {
        "key": "BP_EM_DRIFT_DISTANCE_PERCENTAGE",
        "value": "Calculate the drift score based on the percentage of distance drifted"
    },
    {
        "key": "BP_EM_DRIFT_VALID_DRIFT_ANGLE",
        "value": "Maximum valid drift angle (optional)"
    },
    {
        "key":"BP_EM_DRIFT_PTS",
        "value":'pts'
    },

    {
        "key": "BP_CM_IMG_MAX_SIZE_MSG",
        "value": "Image upload failed. Image size exceeds 15 mb. Please choose a smaller file size."
    },
    {
        "key": "BP_CM_SOMETHING_WENT_WRONG_MSG",
        "value": "Something went wrong."
    },
    {
        "key": "BP_CM_PREVIEW",
        "value": "Preview"
    },
    {
        "key": "BP_CM_INVALID_IMAGE_FORMAT_MSG",
        "value": "Please upload a file in .jpeg, .jpg or .png format"
    },
    {
        "key": "BP_CM_CLOSE_BTN_LBL",
        "value": "Close"
    },
    {
        "key": "BP_CM_DELETE_BTN_LBL",
        "value": "Delete"
    },
    {
        "key": "BP_CM_DESCRIPTION",
        "value": "Description"
    },
    {
        "key": "BP_CM_VIEW_DETAILS",
        "value": "View details"
    },
    {
        "key": "BP_CM_DISCARD_BTN_LBL",
        "value": "Discard"
    },
    {
        "key": "BP_CM_EDIT_BTN_LBL",
        "value": "Edit"
    },
    {
        "key": "BP_CM_GPX_FILE_LABEL",
        "value": "gpx file"
    },
    {
        "key": "BP_CM_LOAD_MORE_BTN_LBL",
        "value": "Load more"
    },
    {
        "key": "BP_CM_NO_BTN_LBL",
        "value": "No"
    },
    {
        "key": "BP_CM_ACTIONS",
        "value": "Actions"
    },
    {
        "key": "BP_CM_UPLOAD_COVER_IMAGE",
        "value": "a .jpg .jpeg or .png file to upload circuit cover image"
    },
    {
        "key": "BP_CM_SAVE_BTN_LBL",
        "value": "Save"
    },
    {
        "key": "BP_CM_UPDATE_BTN_LBL",
        "value": "Update"
    },
    {
        "key": "BP_CM_YES_BTN_LBL",
        "value": "Yes"
    },
    {
        "key":"BP_CM_UPLOAD_GPX_ZIP",
        "value":"Please upload a file in .gpx or .zip format"
    },
    {
        "key": "BP_EM_ABOUT_EVENT",
        "value": "ABOUT YOUR EVENT"
    },
    {
        "key": "BP_EM_AEH",
        "value": "Add event hashtag"
    },
    {
        "key": "BP_EM_BROWSE_EVENT",
        "value": "Browse events of your business"
    },
    {"key":
        "BP_EM_EXERCISE_DRIFT",
        "value":"Drift"
    },
    {
        "key": "BP_EM_CONFIRM_DELETE_EVENT_TITLE",
        "value": "Confirm event delete"
    },
    {
        "key": "BP_EM_CONFIRM_DELETE_PARTICIPANTS",
        "value": "Are you sure you want to delete selected {placeholderParticipants} participants ?"
    },
    {
        "key": "BP_EM_CHANGE_IMAGE",
        "value": "Change image"
    },
    {
        "key": "BP_EM_EVENT_ND",
        "value": "It is not possible to delete events with races.",
    },
    {"key":'BP_EM_CIAM_LINKED',"value":'Linked'},
    { "key": "BP_EM_EXERCISE_ND", "value": "Exercise not deleted" },
    {
        "key": "BP_EM_EXERCISE_ENAE",
        "value": "The provided exerciseName already exists!.",
      },
    { "key": "BP_EM_RACE_NF", "value": "Race not found" },
    { "key": "BP_EM_PARTICIPANT_EAE", "value": "Provided e-mail already exists" },
    {
        "key": "BP_EM_PARTICIPANT_PDE",
        "value": "Participant with participantId `participant_id` does not exists",
      },
    {
        "key": "BP_EM_PARTICIPANT_HDR",
        "value":
          "Cannot delete participant. Invalid participant or particpant has done races",
      },
      {
        "key":"BP_EM_RACES_JUST_NOW",
        "value":"Just now"
      },
      {
        "key":"BP_EM_RACES_MIN_AGO",
        "value":"{placeholderTime} mins ago"
      },
      {
        "key":"BP_EM_RACES_YESTERDAY",
        "value":"Yesterday"
      },
    { "key": "BP_EM_EVENT_PECE", "value": "Past events cannot be modified" },
    { "key": "BP_EM_EXERCISE_PECM", "value": "Past exercises cannot be updated" },
    { "key": "BP_EM_SC_ISM", "value": "Initial Score Mismatch" },
    { "key": "BP_EM_PNF", "value": "User is not authorized to access this resource" },
    {"key":'BP_PM_FM_CAP',"value":'Car already present for the user'},
    {"key":'BP_EM_NO_RACE_FOUND',"value":'No race found for this participant'},
    {"key":'BP_EM_NO_ACCESS',"value":'Access denied'},
    {"key":'BP_EM_ANONYMOUS_PARTICIPANT',"value":'Anonymous Participant'},
    { "key": "BP_PM_TOKEN_FAIL", "value": "Token validation fail" },
    {
        "key": "BP_EM_DELETE_IMAGE",
        "value": "Delete image"
    },
    {
        "key": "BP_EM_CREATE_EVENT",
        "value": "Create event"
    },
    {
        "key": "BP_EM_CREATE_NEW_EVENT",
        "value": "Create new event"
    },
    {
        "key":"BP_EM_RACES_UPDATED",
        "value":"Races updated"
    },
    {
        "key": "BP_EM_PARTICIPANT_DELETED_SUCCESS",
        "value": "Participant has been deleted successfully"
    },
    {
        "key": "BP_EM_PARTICIPANTS_DELETED_SUCCESS",
        "value": "Participants has been deleted successfully"
    },
    {
        "key": "BP_EM_PARTICIPANT_ADDED_SUCCESS",
        "value": "Participant added successfully"
    },
    {
        "key": "BP_EM_PARTICIPANT_UPDATED_SUCCESS",
        "value": "Participant updated successfully"
    },
    {
        "key": "BP_EM_PARTICIPANT_LIST_UPDATED_SUCCESS",
        "value": "Participant List successfully Updated"
    },
    {
        "key": "BP_EM_ADMIN_QR_DOWNLOAD_SUCCESS",
        "value": "Admin QR Code downloaded successfully"
    },
    {
        "key": "BP_EM_MAIL_TRIGGERED_SUCCESS",
        "value": "Mail triggered successfully"
    },
    {
        "key": "BP_EM_EVENT_NAME",
        "value": "Enter event name"
    },
    {
        "key": "BP_EM_DELETE_EVENT",
        "value": "Delete event"
    },
    {
        "key": "BP_EM_CEN",
        "value": "Change exercise name"
    },
    {
        "key": "BP_EM_EVENT_LOGO",
        "value": "Event logo"
    },
    {
        "key": "BP_EM_VALID_FNAME",
        "value": "Please enter valid first name"
    },
    {
        "key": "BP_EM_VALID_LNAME",
        "value": "Please enter valid last name"
    },
    {   "key":'BP_EM_CIAM_LINKED',
        "value":'Linked'},
    {
        "key": "BP_EM_VALID_EMAIL",
        "value": "Please enter valid email"
    },
    {
        "key": "BP_EM_NO_PARTICIPANTS",
        "value": "There are currently no Participants for this event, please add/ </br>upload participants list."
    },
    {
        "key": "BP_EM_UPLOAD_PART_LIST",
        "value": "upload participants list."
    },
    {
        "key": "BP_EM_DOWNLOAD_EM_EXCEL",
        "value": "Download .xsl template"
    },
    {
        "key":"BP_EM_ADD_PENALTY",
        "value":"Add penalty"
    },
    {
        "key":"BP_EM_CLEAR_PENALTY",
        "value":"Clear penalty"
    },
    {
        "key": "BP_EM_PARTICIPANTS",
        "value": "Participants"
    },
    {
        "key": "BP_EM_UPLOAD",
        "value": "Upload"
    },
    {
        "key": "BP_EM_ADD_BTN_LBL",
        "value": "Add"
    },
    {
        "key": "BP_EM_SELEXERCISE",
        "value": "Select event exercises"
    },
    {
        "key": "BP_EM_CONFIRM_DELETE_EXERCISE_TITLE",
        "value": "Confirm exercise delete"
    },
    {
        "key": "BP_EM_LOCAL_LANGUAGE",
        "value": "Local language"
    },
    {
        "key": "BP_EM_NAME_AZ",
        "value": "Name A-Z"
    },
    {
        "key": "BP_EM_NEW_OLD",
        "value": "New to old"
    },
    {
        "key": "BP_EM_OLD_NEW",
        "value": "Old to new"
    },
    {
        "key": "BP_EM_NAME_ZA",
        "value": "Name Z-A"
    },
    {
        "key": "BP_EM_NEF",
        "value": "No events found"
    },
    {
        "key": "BP_EM_SORT_BY",
        "value": "Sort by"
    },
    {
        "key": "BP_EM_SEARCH_EVENTS",
        "value": "Search events"
    },
    {
        "key": "BP_EM_SEARCH_RESULTS",
        "value": "No search results for"
    },
    {
        "key": "BP_EM_NPF",
        "value": "No participants found"
    },
    {
        "key": "BP_EM_START_DATE",
        "value": "Set start date"
    },
    {
        "key": "BP_EM_END_DATE",
        "value": "Set end date"
    },
    {
        "key": "BP_EM_EXERCISE_LEADERBOARD_DETAILS",
        "value": "Exercise & leaderboard details"
    },
    {
        "key": "BP_EM_EXERCISE_SCORE",
        "value": "Score"
    },
    {
        "key": "BP_EM_CIRCUIT_LAYOUT_DETAILS",
        "value": "Circuit & layout details"
    },
    {
        "key": "BP_EM_QR_CODES",
        "value": "QR Codes"
    },
    {
        "key": "BP_EM_AEH",
        "value": "Add event hashtag"
    },
    {
        "key": "BP_EM_SCORE_TYPE",
        "value": "Score type"
    },
    {
        "key": "BP_EM_LEADERBOARD_ORDER",
        "value": "Leaderboard order"
    },
    {
        "key": "BP_EM_TP",
        "value": "Total points"
    },
    {
        "key": "BP_EM_TT",
        "value": "Total time"
    },
    {
        "key": "BP_EM_DRIFT_TOTAL_SCORE",
        "value": "Total score"
    },
    {
        "key": "BP_EM_UNDO",
        "value": "Undo"
    },
    {
        "key": "BP_EM_BR_RN",
        "value": "Racer Name"
    },
    {
        "key": "BP_EM_SCORE_TYPE_POINTS",
        "value": "Points"
    },
    {
        "key": "BP_EM_SCORE_TYPE_TIME",
        "value": "Time"
    },
    {
        "key": "BP_EM_ALL_EVENTS",
        "value": "All events"
    },
    {
        "key": "BP_EM_EMAIL_FAIL",
        "value": "Emailer failed"
    },
    {
        "key": "BP_EM_EMAIL_HOLD",
        "value": "Emailer on hold"
    },
    {
        "key": "BP_EM_EMAIL_SENT",
        "value": "Emailer sent"
    },
    {
        "key": "BP_EM_EXERCISE",
        "value": "Exercise"
    },
    {
        "key": "BP_EM_FAILED",
        "value": "Failed"
    },
    {
        "key": "BP_EM_EVENTS_TITLE",
        "value": "Events"
    },
    {
        "key": "BP_EM_MANAGE_BR_BD",
        "value": "Manage best races and race data"
    },
    {
        "key": "BP_EM_ADD_PARTICIPANTS",
        "value": "Add participants"
    },
    {
        "key": "BP_EM_ADD_PARTICIPANT",
        "value": "Add participant"
    },
    {
        "key": "BP_EM_EDIT_PARTICIPANT",
        "value": "Edit participant"
    },
    {
        "key": "BP_EM_EVENT_HASHTAG",
        "value": "Event hashtag"
    },
    {
        "key": "BP_EM_DOWNLOADADM_QR",
        "value": "Download admin QR"
    },
    {
        "key": "BP_EM_DOWNLOAD_PARTICIPANTS",
        "value": "Download selected participants QR codes"
    },
    {
        "key": "BP_EM_All",
        "value": "All"
    },
    {
        "key": "BP_EM_NAME",
        "value": "Name"
    },
    {
        "key": "BP_EM_PSEE",
        "value": "Please select the exercise to the event"
    },
    {
        "key": "BP_EM_EXERCISE_CONSISTANCY",
        "value": "Consistency"
    },
    {
        "key": "BP_EM_EXERCISE_EFFICIENCY",
        "value": "Efficiency"
    },
    {
        "key": "BP_EM_EXERCISE_BESTTIME",
        "value": "Best time"
    },
    {
        "key": "BP_EM_ME_ID_CONNECT",
        "value": "MercedesMe ID"
    },
    {
        "key": "BP_EM_LANGUAGE",
        "value": "Language"
    },
    {
        "key": "BP_EM_LAST_UPDATED",
        "value": "Last updated"
    },
    {
        "key": "BP_EM_RESEND_EMAIL",
        "value": "Resend email"
    },
    {
        "key": "BP_EM_PARTICIPANT_AED",
        "value": "Add, edit and download information related to event participants here"
    },
    {
        "key": "BP_EM_BR_IP",
        "value": "In progress"
    },
    {
        "key": "BP_EM_RANK",
        "value": "Rank"
    },
    {
        "key": "BP_EM_MANAGE_EVENT",
        "value": "Manage event"
    },
    {
        "key": "BP_EM_MANAGE_EVENT_RELATED_DETAILS",
        "value": "Manage general event related details"
    },
    {
        "key": "BP_EM_MEMBERS",
        "value": "Members"
    },
    {
        "key": "BP_EM_RACE_DATA_UPLOAD",
        "value": "Race data uploaded"
    },
    {
        "key": "BP_EM_RACE_DATA_UPLOAD_FAIL",
        "value": "Race data upload failed"
    },
    {
        "key": "BP_EM_RACE_DATA_UPLOAD_HOLD",
        "value": "Race data upload on hold"
    },
    {
        "key": "BP_EM_RACE_VIDEO_UPLOAD",
        "value": "Race video uploaded"
    },
    {
        "key": "BP_EM_RACE_VIDEO_FAIL",
        "value": "Race video upload failed"
    },
    {
        "key": "BP_EM_RACE_VIDEO_HOLD",
        "value": "Race video upload on hold"
    },
    {
        "key": "BP_EM_STATUS",
        "value": "Status"
    },
    {
        "key": "BP_EM_SEARCH_RACER",
        "value": "Search by racer name"
    },
    {
        "key": "BP_EM_TIME_CLOCKED",
        "value": "Time clocked"
    },
    {
        "key": "BP_EM_MANAGE_BTN_LBL",
        "value": "Manage"
    },
    {
        "key": "BP_EM_OPERATOR_TEXT",
        "value": "Operator text"
    },
    {
        "key": "BP_EM_SUBMIT_BTN_LBL",
        "value": "Submit"
    },
    {
        "key": "BP_EM_CROP_COVERIMG",
        "value": "Crop cover image"
    },
    {
        "key": "BP_EM_EVENT_TYPES",
        "value": "Event types"
    },
    {
        "key": "BP_EM_DRAG_DROP",
        "value": "Drag and drop here or"
    },
    {
        "key": "BP_EM_ADD_COVER_IMAGE",
        "value": "to add event logo please"
    },
    {
        "key": "BP_EM_EDIT_EVENT",
        "value": "Edit this event"
    },
    {
        "key": "BP_EM_ERR_SMALLIMG",
        "value": "Upload image seems to be too small. Please upload higher quality image and try again"
    },
    {
        "key": "BP_ADD_EVENT_INFO",
        "value": "Add event operator information"
    },
    {
        "key": "BP_EM_BEST_RACES",
        "value": "Best races"
    },
    {
        "key": "BP_EM_BTE",
        "value": "Back to event"
    },
    {
        "key": "BP_EM_DOWNLOAD",
        "value": "Download"
    },
    {
        "key": "BP_EM_DOWNLOAD_PART_QR",
        "value": "Download partcipants QR"
    },
    {
        "key": "BP_EM_DOWNLOAD_PART_QR_CODES",
        "value": "Download participants QR codes"
    },
    {
        "key": "BP_EM_ENTER_EVENT_HASHTAG",
        "value": "Enter event hashtag"
    },
    {
        "key": "BP_EM_EVENT_ESVU",
        "value": "You can upload the excel sheet via the upload button, If you need the empty excel sheet template please download via download excel sheet button"
    },
    {
        "key": "BP_EM_ADD_SCORE",
        "value": "Add score"
    },
    {
        "key": "BP_EM_CHANGE_EX_NAME",
        "value": "Change exericse name"
    },
    {
        "key": "BP_EM_ENTER_SHORT_URL",
        "value": "Enter short URL"
    },
    {
        "key": "BP_EM_SELECT_EVENT_EXERCISES",
        "value": "Select event exercises"
    },
    {
     "key":"BP_EM_SELECT",
     "value":"Select"
    },
    {
     "key":"BP_EM_CHANGE",
     "value":"Change"
    },
    {
        "key": "BP_EM_MOBILE_RANKING",
        "value": "Mobile ranking (Short version)"
    },
    {
        "key": "BP_EM_MANAGE_INCOMING_RACES",
        "value": "Manage incoming races"
    },
    {
        "key": "BP_EM_ASTP",
        "value": "Add score to the participants"
    },
    {
        "key": "BP_EM_RACER_NAME",
        "value": "Racer name"
    },
    {
        "key":"BP_EM_FINAL_TIME",
        "value":"Final time"
    },
    {
        "key":"BP_EM_DRIFT_FINAL_SCORE",
        "value":"Final score"
    },
    {
        "key": "BP_EM_SEARCH_CIRCUIT",
        "value": "Search for circuit"
    },
    {
        "key": "BP_EM_SEARCH_HERE",
        "value": "Search here"
    },
    {
        "key": "BP_EM_UPDATE_SCORE",
        "value": "Update score"
    },
    {
        "key": "BP_EM_GROUP_BTN_LBL",
        "value": "Group"
    },
    {
        "key": "BP_EM_MANUALLY_ADD_PART",
        "value": "Manually add participants"
    },
    {
        "key": "BP_EM_DELERE_EVENT_RACES",
        "value": "It is not possible to delete events with races"
    },
    {
        "key": "BP_EM_OPERATOR_EMAIL",
        "value": "Operator e-mail"
    },
    {
        "key": "BP_EM_OPERATOR_NAME",
        "value": "Operator name"
    },
    {
        "key": "BP_EM_OPERATOR_PH",
        "value": "Operator phone number"
    },
    {
        "key": "BP_EM_OR",
        "value": "OR"
    },
    {
        "key": "BP_EM_PAST_EVENT_MODIFY",
        "value": "Past events cannot be modified"
    },
    {
        "key": "BP_EM_INCOMING_RACES",
        "value": "Incoming races"
    },
    {
        "key": "BP_EM_REMOVE_EXERCISE",
        "value": "Remove exercise"
    },
    {
        "key": "BP_EM_RENAME_EXERCISE",
        "value": "Rename exercise"
    },
    {
        "key": "BP_EM_SEARCH_PART_NAME",
        "value": "Search particpant name, email etc"
    },
    {
        "key":"BP_EM_DRIFT_NON_EDITABLE",
        "value":"Drift exercise in progress, changes to settings can't be made till the event is complete."
    },
    {
        "key": "BP_EM_UPLOAD_PARTICIPANTS",
        "value": "Upload participants"
    },
    {
        "key": "BP_EM_BEST_TIME",
        "value": "Best time"
    },
    {
        "key": "BP_EM_CONSISTANCY",
        "value": "Consistancy"
    },
    {
        "key": "BP_EM_EFFICIENCY",
        "value": "Efficiency"
    },
    {
        "key": "BP_EM_LEADERBOARD_LINKS",
        "value": "Leaderboard links"
    },
    {
        "key": "BP_EM_EXERCISE_FLEADERBOARD",
        "value": "Full leaderboard"
    },
    {
        "key": "BP_EM_LIVE_LEADRBOARD",
        "value": "Live leaderboard"
    },
    {
        "key": "BP_EM_LIVE",
        "value": "LIVE"
    },
    {
        "key": "BP_EM_EXERCISE_EXIST",
        "value": "The provided exerciseName already exists"
    },
    {
        "key": "BP_EM_EXERCISE_NOT_DELETED",
        "value": "Exercise not deleted"
    },
    {
        "key": "BP_EM_PAST_EXERCISE_UPDATE",
        "value": "Past exercises cannot be updated"
    },
    {
        "key": "BP_EM_SCORE",
        "value": "Score"
    },
    {
        "key": "BP_EM_LIVE_EVENTS",
        "value": "Live event"
    },
    {
        "key": "BP_EM_EVENT_EXERCISE",
        "value": "Event exercise"
    },
    {
        "key": "BP_EM_SET_EXERCISE_LEADERBOARD",
        "value": "Set your exercises for the event here."
    },
    {
        "key": "BP_EM_MANAGE_PARTICIPANTS",
        "value": "Manage participants"
    },
    {
        "key": "BP_EM_PARTICIPANT",
        "value": "Participant"
    },
    {
        "key": "BP_EM_PARTICIPANTS_ENTER_EMAIL",
        "value": "Email"
    },
    {
        "key": "BP_EM_PARTICIPANTS_ENTER_FNAME",
        "value": "Enter first name"
    },
    {
        "key": "BP_EM_PARTICIPANTS_ENTER_LNAME",
        "value": "Enter last name"
    },
    {
        "key": "BP_EM_PARTICIPANTS_FIRST_NAME",
        "value": "First name"
    },
    {
        "key": "BP_EM_PARTICIPANTS_LAST_NAME",
        "value": "Last name"
    },
    {
        "key": "BP_EM_PARTICIPANTS_NOT_AVAILABLE",
        "value": "No participants available"
    },
    {
        "key": "BP_EM_PARTICIPANTS_SLNO",
        "value": "S.No."
    },
    {
        "key": "BP_EM_PARTICIPANTS_REFRESH",
        "value": "Refresh"
    },
    {
        "key": "BP_EM_PARTICIPANTS_SCREEN_SUBTITLE",
        "value": "Add or manage participants for the event"
    },
    {
        "key": "BP_EM_PARTICIPANTS_SEARCH_PLACEHOLDER",
        "value": "Type participant name, email etc"
    },
    {
        "key": "BP_EM_EMAIL_ALREADY_EXISTS",
        "value": "Provided e-mail already exists"
    },
    {
        "key": "BP_EM_PARTICIPANT_GROUPS",
        "value": "Groups"
    },
    {
        "key": "BP_EM_ERROR_DELETE_PARTICIPANT",
        "value": "Cannot delete participant. Invalid participant or particpant has done races"
    },
    {
        "key": "BP_EM_ERROR_PARTICIPANT_EXIST",
        "value": "Participant with participantId `participant_id` does not exists"
    },
    {
        "key": "BP_EM_ERROR_AUTHORIZATION",
        "value": "User is not authorized to access this resource"
    },
    {
        "key": "BP_EM_DOWNLOAD_ALL",
        "value": "Download all"
    },
    {
        "key": "BP_EM_UPLOAD_EXCEL",
        "value": "You can upload the excel sheet via the upload button, If you need the empty excel sheet template please download via download excel sheet button"
    },
    {
        "key": "BP_EM_DOWNLOADEMP_EXCEL",
        "value": "Download empty excel sheet"
    },
    {
        "key": "BP_EM_DOWNLOAD_EXCEL_TEMP",
        "value": "Download excelsheet template"
    },
    {
        "key": "BP_EM_DOWNLOAD_QR",
        "value": "Download QR"
    },
    {
        "key": "BP_EM_DELETE_PARTICIPANTS",
        "value": "Delete selected participants"
    },
    {
        "key": "BP_EM_POINTS_VALUE",
        "value": "Points value"
    },
    {
        "key": "BP_EM_ASCENDING",
        "value": "Ascending"
    },
    {
        "key": "BP_EM_DESENDING",
        "value": "Desending"
    },
    {
        "key": "BP_EM_MINUTES",
        "value": "Minutes"
    },
    {
        "key": "BP_EM_SECONDS",
        "value": "Seconds"
    },
    {
        "key": "BP_EM_UPDATE_POINTS",
        "value": "Update points"
    },
    {
        "key": "BP_EM_ADD_POINTS",
        "value": "Add points"
    },
    {
        "key": "BP_EM_UPDATE_TIME",
        "value": "Update time"
    },
    {
        "key": "BP_EM_ADD_TIME",
        "value": "Add time"
    },
    {
        "key":'BP_EM_ITEM_DELETED',
        "value":'Item has been deleted'
    },
    {
        "key":"BP_EM_RACES_UPDATED",
        "value":"Races updated"
    },
    {
     "key":"BP_EM_EXERCISE_DELETED",
     "value":"Exercise has been deleted"
    },
    {
        "key":"BP_EM_PARTICIPANTS_FORMAT_ERROR",
        "value":"Participants format error"
    },
    {
        "key":"BP_EM_SCORE_ADDED_SUCCESSFULLY",
        "value":"Score added successfully"
    },
    {
        "key":'BP_EM_NLF',
        "value":'No layouts found'
    },
    {
        "key":"BP_EM_SEARCH_PARTICIPANT_NAME",
        "value":"Search by participants name"
    },
    {
        "key":"BP_EM_DRIFT_ERROR_MAX",
        "value":"Invalid input"
    }
    ,
    {
        "key":"BP_EM_DRIFT_ERROR_PATTERN",
        "value":"Invalid input"
    }
    ,
    {
        "key": "BP_LM_SEARCH_GEO_FENCE",
        "value": "Search or find loaction on map to create the geo-fence for your circuit"
    },
    {
        "key": "BP_LM_TRACK_DETAILS_UPDATE",
        "value": "Update"
    },
    {
        "key": "BP_LM_ADD_DETAILS_BTN_LBL",
        "value": "Add details"
    },
    {
        "key": "BP_LM_ADD_DESTINATION_LBL",
        "value": "Set as destination"
    },
    {
        "key": "BP_LM_ADD_DSTN_NEW",
        "value": "Enter destination"
    },
    {
        "key": "BP_LM_ADD_INSDR_SPT_NEW",
        "value": "Add insider spot"
    },
    {
        "key": "BP_LM_ADD_START_BTN_LBL",
        "value": "Set as start"
    },
    {
        "key": "BP_LM_ADD_STRT_LCN_MSG_NEW",
        "value": "Search or manually drop a pin to locate your start."
    },
    {
        "key": "BP_LM_ADD_DEST_LCN_MSG_NEW",
        "value": "Search or manually drop a pin to locate your destination."
    },
    {
        "key": "BP_LM_ADD_INSDR_NEW",
        "value": "Search or manually drop a pin to add your insider spot."
    },
    {
        "key": "BP_LM_ADD_STRT_MSG_NEW",
        "value": "Enter start"
    },
    {
        "key": "BP_LM_BACK_TO_LOCATIONS_BTN_LBL",
        "value": "Back to locations"
    },
    {
        "key": "BP_LM_CIRCUITS_LBL",
        "value": "Circuits"
    },
    {
        "key": "BP_LM_OFFROAD_LBL",
        "value": "Offroad"
    },
    {
        "key": "BP_LM_CIRCUIT_COORDINATES_LBL",
        "value": "Circuit coordinates"
    },
    {
        "key": "BP_LM_CIRCUIT_RADIUS_LBL",
        "value": "Circuit radius"
    },
    {
        "key": "BP_LM_CLOSE_BTN_LBL",
        "value": "Close"
    },
    {
        "key": "BP_LM_CTG_CULTURAL",
        "value": "Cultural"
    },
    {
        "key": "BP_LM_CTG_EXPLORE_NATURE",
        "value": "Explore nature"
    },
    {
        "key": "BP_LM_CTG_FASTEST",
        "value": "Fastest"
    },
    {
        "key": "BP_LM_CTG_FIELD_TRIP",
        "value": "Field trip"
    },
    {
        "key": "BP_LM_CTG_LUX_PRFRMNC",
        "value": "Luxury performance"
    },
    {
        "key": "BP_LM_CTG_PANORAMA",
        "value": "Panorama"
    },
    {
        "key": "BP_LM_CTG_SCNC_PNTS",
        "value": "Scenic spots"
    },
    {
        "key": "BP_LM_CTG_SIGHT_SEEING",
        "value": "Sightseeing"
    },
    {
        "key": "BP_LM_CTG_STRAIGHT_AHEAD",
        "value": "Straight ahead"
    },
    {
        "key": "BP_LM_CTG_UPS_DOWNS",
        "value": "Ups and downs"
    },
    {
        "key": "BP_LM_CTG_WATER_VIEW",
        "value": "Water view"
    },
    {
        "key": "BP_LM_DRAFT_LBL",
        "value": "Draft"
    },
    {
        "key": "BP_LM_EDIT_GEOFENCE_BTN_LBL",
        "value": "Edit geo-fence"
    },
    {
        "key": "BP_LM_EDT_INSDR_SPT_NEW",
        "value": "Edit insider spot"
    },
    {
        "key": "BP_LM_GENERATE_TOUR_BTN_LBL",
        "value": "Generate tour"
    },
    {
      "key": "BP_LANG_ENG",
      "value": "English"
    },
    {
      "key": "BP_LANG_DE",
      "value": "German"
    },
    {
        "key": "BP_LM_NEW_LAYOUT_BTN_LBL",
        "value": "New layout"
    },
    {
        "key": "BP_LM_NEW_LBL",
        "value": "New"
    },
    {
        "key": "BP_LM_NO_LAYOUTS_AVAILABLE_MSG",
        "value": "No track layouts available for this circuit. Start creating now"
    },
    {
        "key": "BP_LM_OFFROAD_ADV_LBL",
        "value": "Offroad adventure"
    },
    {
        "key": "BP_LM_PUBLISH_BTN_LBL",
        "value": "Publish"
    },
    {
        "key": "BP_LM_ROUND_TRIP_NOTE",
        "value": "Skip adding your destination and create round trip back to your start."
    },
    {
        "key": "BP_LM_SAVE_INSIDER_POINT_BTN_LBL",
        "value": "Add insider spot"
    },
    {
        "key": "BP_LM_SAVE_PUBLISH_BTN_LBL",
        "value": "Save and publish"
    },
    {
        "key": "BP_LM_SAVE_UPDATE_BTN_LBL",
        "value": "Save and update"
    },
    {
        "key": "BP_LM_TRACK_PACE_LBL",
        "value": "Track pace"
    },
    {
        "key": "BP_LM_TOUR_DETAILS",
        "value": "Tour details"
    },
    {
        "key": "BP_LM_UPDATE_DETAILS_BTN_LBL",
        "value": "Update details"
    },
    {
        "key": "BP_LM_UPDT_DSTN",
        "value": "Edit destination"
    },
    {
        "key": "BP_LM_UPDT_STRT",
        "value": "Edit start"
    },
    {
        "key": "BP_LM_UPLOAD_GPX_FILE_MSG",
        "value": "Please upload gpx file to add new layout to this circuit"
    },
    {
      "key": 'BP_LM_UPLOAD_AUDIO_FILE_LBL',
      "value": 'Upload audio files'
    },
    {
        "key": "BP_LM_LOCATIONS_TITLE",
        "value": "Locations"
    },
    {
        "key": "BP_LM_CHANGE_IMG",
        "value": "Change image"
    },
    {
        "key": "BP_LM_ADDRESS",
        "value": "Address"
    },
    {
        "key": "BP_LM_ALL_LANGUAGES_LBL",
        "value": "All languages"
    },
    {
        "key": "BP_LM_SET_DISPLAY_NAME",
        "value": "Set display name"
    },
    {
        "key": "BP_LM_SAVE_DRAFT_BTN_LBL",
        "value": "Save as draft"
    },
    {
        "key": "BP_LM_LAYOUT_DETAILS",
        "value": "Layout details"
    },
    {
        "key": "BP_LM_ENTER_CIRCUIT_NAME",
        "value": "Enter circuit name"
    },
    {
        "key": "BP_LM_SET_CIRCUIT_NAME",
        "value": "Set circuit type"
    },
    {
        "key": "BP_LM_WEBSITE",
        "value": "Website"
    },
    {
        "key": "BP_LM_WIKIPEDIA",
        "value": "Wikipedia"
    },
    {
        "key": "BP_LM_CIRCUIT_RESET_MSG",
        "value": "The current geo-fence will be reset when a new tool is used"
    },
    {
        "key": "BP_LM_ALERT",
        "value": "Alert"
    },
    {
        "key": "BP_LM_CANCEL_EMOTION_TOUR_TITLE",
        "value": "Cancel emotion tour"
    },
    {
        "key": "BP_LM_DLT_INSDR_SPT_TITLE",
        "value": "Delete insider spot"
    },
    {
        "key": "BP_LM_CANCEL_EDITING_CARD_DTLS",
        "value": "Unsaved changes"
    },
    {
        "key": "BP_LM_CONFIRM_CANCEL",
        "value": "Confirm cancel"
    },
    {
        "key": "BP_LM_DELETE_TRACK_TITLE",
        "value": "Delete track"
    },
    {
        "key": "BP_LM_DELETE_ET_TITLE",
        "value": "Delete emotion tour"
    },
    {
        "key": "BP_LM_DELETE_OFFROAD_TITLE",
        "value": "Delete offroad track"
    },
    {
        "key": "BP_LM_CANCEL_OFFROAD_TRACK_TITLE",
        "value": "Cancel offroad track"
    },
    {
        "key": "BP_LM_PUBLISH_NOW",
        "value": "Publish now"
    },
    {
        "key": "BP_LM_BROWSE_LOCATIONS_MSG",
        "value": "Browse through the circuits, emotion tours, offroad tracks anywhere around the world."
    },
    {
        "key": "BP_LM_SET_CENTER_CIRCLULAR",
        "value": "Set the centre point to plot a circular geo-fence"
    },
    {
        "key": "BP_LM_DRAG_GEOFENCE_MSG",
        "value": "Hold and drag to move the geo fence area"
    },
    {
        "key": "BP_LM_GEO_FENCE_RADIUS_MSG",
        "value": "Enter geo fence radius"
    },
    {
        "key": "BP_LM_TRACK_EXISTS_MSG",
        "value": "Track already exists."
    },
    {
        "key": "BP_LM_DISCARD_EMOTION_TOUR_MSG",
        "value": "Are you sure you want cancel this tour? All progress made to this emotion tour will be lost."
    },
    {
        "key": "BP_LM_DISCARD_OFFROAD_TRACK_MSG",
        "value": "Are you sure you want to cancel this offroad track? All progress made to this offroad track will be lost."
    },
    {
        "key": "BP_LM_DISCARD_INSIDER_SPOT_MSG",
        "value": "Are you sure you want to cancel? All details added to this insider spot will be lost."
    },
    {
        "key": "BP_LM_DISCARD_EDIT_INSIDER_SPOT_MSG",
        "value": "Are you sure you want to cancel. This will discard your progress."
    },
    {
        "key": "BP_LM_DELETE_INSIDER_SPT_MSG",
        "value": "Are you sure you want to delete the insider spot '<strong>{placeholderInsiderspotName}</strong>' from this tour?"
    },
    {
        "key": "BP_LM_ALLOWED_MEDIA_EXTENSION_MSG",
        "value": "Please upload a file in .jpeg, .jpg, .png , .gif or .mp4format"
    },
    {
        "key": "BP_LM_MAX_ALLOWED_MEDIA_MSG",
        "value": "Max 1 image is allowed."
    },
    {
        "key": "BP_EM_DLT_EVENT_CONFIRM_MSG",
        "value": "Are you sure you want to delete this event?"
    },
    {
        "key": "BP_EM_CONFIRM_DELETE_PARTICIPANT",
        "value": "Are you sure you want to delete this participant?"
    },
    {
        "key": "BP_EM_DLT_EXERCISE_CONFIRM_MSG",
        "value": "Are you sure you want to delete this exercise?"
    },
    {
        "key": "BP_EM_DLT_RACE_CONFIRM_MSG",
        "value": "Are you sure you want to delete this race?"
    },
    {
     "key":"BP_EM_COPIED_FL_URL",
     "value":"Copied full leaderboard URL"
    },
    {
     "key":"BP_EM_COPIED_LL_URL",
     "value":"Copied live leaderboard URL"
    },
    {
      "key":"BP_EM_UPLOAD_COMPLETE",
      "value":"Upload complete"
    },
    {
        "key":"BP_EM_REDIRECTING_NEXT_STEP",
        "value":"Redirecting you to the next step"
    },
    {
        "key": "BP_LM_CONFIRM_DELETE_MESSAGE",
        "value": "Are you sure you want to delete this circuit?"
    },
    {
        "key": "BP_LM_DELETE_EMOTION_TOUR_MSG",
        "value": "Are you sure you want to delete this emotion tour?"
    },
    {
        "key": "BP_LM_SEND_TRACK_TO_FLEET_MSG",
        "value": "Publishing this track will be sent to all cars in your fleet under the favourites section."
    },
    {
        "key": "BP_LM_DELETE_TRACK_LAYOUT",
        "value": "Are you sure you want to delete this track layout?"
    },
    {
        "key": "BP_LM_DELETE_TRACK_MSG",
        "value": "Are you sure you want to delete this track?"
    },
    {
        "key": "BP_LM_UNSAVED_CHANGES_WARNING",
        "value": "All your updates made to this insider spot will be lost. Do you want to continue?"
    },
    {
        "key": "BP_LM_GEOFENCE_VALIDATION_ERROR",
        "value": "Couldn’t validate the Geo-fence"
    },
    {
        "key": "BP_LM_START_CIRCUIT_CREATION",
        "value": "Add points on map to start creating the geo-fence"
    },
    {
        "key": "BP_LM_ZOOM_GEO_FENCE_MSG",
        "value": "Zoom and pan through the map to start plotting the Geo-fence for your circuit"
    },
    {
        "key": "BP_LM_NEXT_STEPS_MSG",
        "value": "Taking you to next steps..."
    },
    {
        "key": "BP_LM_VALIDATED_MSG",
        "value": "Validated"
    },
    {
        "key": "BP_LM_VALIDATING",
        "value": "Validating"
    },
    {
        "key": "BP_LM_VALIDATION_IN_PROGRESS",
        "value": "Validation in progress..."
    },
    {
        "key": "BP_LM_RADIUS_LBL",
        "value": "Radius"
    },
    {
        "key": "BP_LM_POLYLINE_LBL",
        "value": "Polyline"
    },
    {
        "key": "BP_LM_CONFIRM_DELETE_TITLE",
        "value": "Confirm Delete "
    },
    {
        "key": "BP_LM_TOUR_SENT_SUCCESS",
        "value": "Tour sent to car successfully"
    },
    {
        "key": "BP_LM_ET_NAME_REQ",
        "value": "Tour name cannot be null or empty"
    },
    {
        "key": "BP_LM_ET_TOUR_ID_REQ",
        "value": "Tour ID cannot be null or empty"
    },
    {
        "key": "BP_LM_ET_AMG_ANNOTATION_EXISTS",
        "value": "AMG annotation already exists with annotationId"
    },
    {
        "key": "BP_LM_ET_GUID_REQ",
        "value": "Track guid cannot be null or empty"
    },
    {
        "key": "BP_LM_ET_INVLAID_TRACK_FORMAT",
        "value": "Invalid track GUID format"
    },
    {
        "key": "BP_LM_ET_INVALID_GUID",
        "value": "No emotion tour found for this guid."
    },
    {
        "key": "BP_LM_MAX_ONE_MEDIA_FILE",
        "value": "You can upload only one media file for the circuit."
    },
    {
        "key": "BP_LM_CIRCUIT_RADIUS_REQ",
        "value": "Circuit radius cannot be null or empty"
    },
    {
        "key": "BP_LM_CIRCUIT_TYPE_REQ",
        "value": "Circuit type cannot be null or empty"
    },
    {
        "key": "BP_LM_CIRCUIT_COORDINATES_REQ",
        "value": "Circuit coordinates cannot be null or empty"
    },
    {
        "key": "BP_LM_CIRCUIT_NAME_REQ",
        "value": "Circuit name cannot be null or empty"
    },
    {
        "key": "BP_LM_ADDRESS_NOT_FOUND",
        "value": "Address Not found for given lattitude and longitude"
    },
    {
        "key": "BP_LM_CIRCUIT_CREATE_ERR",
        "value": "Either provide 'encodedPolyline' or 'coordinates' to create a circuit."
    },
    {
        "key": "BP_LM_CIRCUIT_WRONG_FILE_UPLOADED_ERR",
        "value": "Please upload a file in .gpx format"
    },
    {
        "key": "BP_LM_WRONG_FILE_UPLOAD_ERR",
        "value": "Please upload a file in .gpx or .kml format"
    },
    {
        "key": "BP_LM_ADDING_LAYOUT_MSG",
        "value": "Adding your track layout, please wait this might take a few seconds."
    },
    {
        "key": "BP_LM_ENHANCING_TRK_MSG",
        "value": "Enhancing your tour, please wait this might take a few seconds."
    },
    {
        "key": "BP_LM_EMPTY_GEOFENCE_ERROR",
        "value": "Couldn’t validate empty Geo-fence"
    },
    {
        "key": "BP_LM_TOUR_DOES_NOT_EXIST",
        "value": "Tour does not exist"
    },
    {
        "key": "BP_LM_INVALID_VALUE_ENETERED",
        "value": "Invalid value entered. Type a value between 0 and 100."
    },
    {
        "key": "BP_LM_GEO_FENCE_OVERLAP_ERR",
        "value": "Geofence overlaps with another geofence."
    },
    {
        "key": "BP_LM_INVALID_PHONE_NUMBER",
        "value": "Invalid phone number"
    },
    {
        "key": "BP_LM_ZOOM_IN_MSG",
        "value": "Click on zoom button to create geo fence area"
    },
    {
        "key": "BP_LM_ZOOM_IN_LBL",
        "value": "Zoom in"
    },
    {
        "key": "BP_LM_NO_SEARCH_RESULTS",
        "value": "No search results"
    },
    {
        "key": "BP_LM_NO_RESULTS",
        "value": "No results for"
    },
    {
        "key": "BP_LM_SEARCH_LBL",
        "value": "Search"
    },
    {
        "key": "BP_PM_LOGOUT",
        "value": "Logout"
    },
    {
        "key": "BP_PM_OPERATING_AS",
        "value": "Operating as"
    },
    {
        "key": "BP_PM_SWITCH_PROFILE",
        "value": "Switch Profile"
    },
    {
        "key": "BP_PM_MANAGE_BUSINESS",
        "value": "Manage Business"
    },
    {
        "key": "BP_PM_MANAGE_FLEET",
        "value": "Manage Fleet"
    },
    {
        "key": "BP_PM_TOKEN_FAIL",
        "value": "Token validation fail"
    },
    {
        "key": "BP_PM_LP_SB",
        "value": "Select Business"
    },
    {
        "key": "BP_PM_LP_PD",
        "value": "Please select the business you want to use to manage Mercedes me Stories services. Your account can be linked and operated under multiple businesses, You can change business anytime Under Profile section or can come back to this page."
    },
    {
        "key": "BP_PM_FLEET",
        "value": "FLEET"
    },
    {
        "key": "BP_PM_CAR",
        "value": "Car"
    },
    {
        "key": "BP_PM_VVIN",
        "value": "VIN"
    },
    {
        "key": "BP_PM_LICNO",
        "value": "License no"
    },
    {
        "key": "BP_PM_MODEL_DETAILS_UNAVALIABLE",
        "value": "Model details unavailable"
    },
    {
        "key": "BP_PM_FLEET_EMPTY",
        "value": "Your fleet seems to be empty, start by adding a car"
    },
    {
        "key": "BP_PM_CAR_FLEET",
        "value": "Add a new car to your fleet"
    },
    {
        "key": "BP_PM_VIN",
        "value": "VIN number"
    },
    {
        "key": "BP_PM_CAR_MODEL",
        "value": "Car model"
    },
    {
        "key": "BP_PM_LICENSE",
        "value": "License plate"
    },
    {
        "key": "BP_PM_NEW_CAR",
        "value": "Add new car"
    },
    {
        "key": "BP_PM_ADD_EDIT_FLEET",
        "value": "Add, delete and manage your fleet here"
    },
    {
        "key": "BP_PM_MEMBERS_LIST",
        "value": "Members list"
    },
    {
        "key": "BP_PM_ACCESS_TYPE",
        "value": "Access type"
    },
    {
        "key": "BP_PM_AN",
        "value": "Alias name"
    },
    {
        "key": "BP_PM_BW",
        "value": "Business website"
    },
    {
        "key": "BP_LM_VIEW_SUMMARY_BTN_LBL",
        "value": "View summary"
    },
    {
        "key": "BP_PM_DELETE_FLEET_MESSAGE",
        "value": "Are you sure you want to delete <strong>{placeholderObject}</strong> from fleet?"
    },
    {
      "key":"BP_PM_JB",
      "value":"Join Business"
    },
    { "key": "BP_PM_HI", 
      "value": "Hi" 
    }, 
    { "key": "BP_PM_PCYA",
      "value": "Your account is not assigned to any business group yet. Please contact your administrator to join"
    },
    {"key":'BP_PM_YWLI',
      "value":'You will be logged out in'
    },
    {
        "key":"BP_PM_CAR_DETAILS_UPDATED",
        "value":"Car details updated"
    },
    {
        "key":"BP_PM_CAR_ADDED_SUCCESSFULLY",
        "value":"Car successfully added to fleet"
    },
    {
        "key": "BP_PM_ADD_MEMBER",
        "value": "Member"
    },
    {
        "key": "BP_PM_SUCCESS_ADD",
        "value": "User Added Successfully"
    },
    {
        "key": "BP_PM_ADD_MEMBER_TITLE",
        "value": "Add member"
    },
    {
        "key": "BP_LM_CREATED_TOUR_LBL",
        "value": "Created by <strong>{placeholderName}</strong> on <strong>{placeholderDate}</strong>"
    },
    {
        "key": "BP_LM_CONFIRM_ALERT_MESSAGE",
        "value": "Are you sure you want to leave the page? Any changes that you made will not be saved."
    },
    {
        "key": "BP_LM_NEXT_BTN_LBL",
        "value": "Next"
    },
    {
        "key": "BP_LM_SELECT_LANG",
        "value": "Select language"
    },
    {
        "key": "BP_LM_UPLOADING",
        "value": "Uploading..."
    },
    {
        "key": "BP_LM_ADDED_TOUR_LBL",
        "value": "Added to tour"
    },
    {
        "key": "BP_CIRCUIT_LAYOUT_ONLY_LBL",
        "value": "Circuits only with layouts"
    },
    {
        "key": "BP_LM_ADD_NEW_CIRCUIT",
        "value": "Add a New Circuit"
    },
    {
        "key": "BP_EM_NCR",
        "value": "No search results"
    },
    {
        "key": "BP_LM_BACK_BTN_LBL",
        "value": "Back"
    },
    {
        "key": "BP_LM_RENAME_ADD_LBL",
        "value": "Re-name address"
    },
    {
        "key": "BP_LM_CREATING_TRK_UNDER_LBL",
        "value": "Creating <strong>{placeholderTrackType}</strong> under <strong>{placeholderGroupName}</strong>"
    },
    {
        "key": "BP_LM_EMOTION_TOUR_LBL",
        "value": "Emotion tour"
    },
    {
        "key": "BP_LM_ENTER_TOUR_NAME",
        "value": "Enter tour name"
    },
    {
        "key": "BP_LM_AMG_INFO_MSG",
        "value": "This location will be tagged and saved to the AMG database if description and media has been added to this location."
    },
    {
        "key": "BP_LM_GPX_MSG",
        "value": "Do you have a gpx file ? Please use below button to upload and plot it on the map."
    },
    {
        "key": "BP_LM_CREATE_BTN_LBL",
        "value": "Create"
    },
    {
        "key": "BP_LM_PICK_CATEGORY",
        "value": "Pick a category for your tour"
    },
    {
        "key": "BP_LM_DRIVE_EXPRIENCE",
        "value": "What they will experience while driving"
    },
    {
        "key": "BP_LM_OR",
        "value": "Or"
    },
    {
        "key": "BP_LM_CUTARED_TOUR_BTN_LABEL",
        "value": "Continue without annotations"
    },
    {
        "key": "BP_LM_ADD_INSDR_PT",
        "value": "Atleast one insider spot is required to generate a tour"
    },
    {
        "key": "BP_LM_HIGHLIGHTS",
        "value": "Highlights"
    },
    {
        "key": "BP_LM_HIGHLIGHT_LBL",
        "value": "Highlight"
    },
    {
        "key": "BP_LM_DESTINATION",
        "value": "Destination"
    },
    {
        "key": "BP_LM_START",
        "value": "Start"
    },
    {
        "key": "BP_LM_LENGTH",
        "value": "LENGTH"
    },
    {
        "key": "BP_LM_SUMMARY",
        "value": "View summary"
    },
    {
        "key": "BP_LM_LAYOUT_URL",
        "value": "Layout URL"
    },
    {
        "key": "BP_LM_SECTORS",
        "value": "Sectors"
    },
    {
        "key": "BP_LM_SECTOR_LABEL",
        "value": "Sector"
    },
    {
        "key": "BP_PM_MEMBER",
        "value": "CIAM ID"
    },
    {
        "key": "BP_LM_TRK_GUID",
        "value": "Track GUID"
    },
    {
        "key": "BP_LM_SET_TRACK_TYPE",
        "value": "Set track type"
    },
    {
        "key": "BP_LM_PERMANENT",
        "value": "Permanent"
    },
    {
        "key": "BP_LM_TEMPORARY",
        "value": "Temporary"
    },
    {
        "key": "BP_LM_TRACK_TYPE",
        "value": "Track type"
    },
    {
        "key": "BP_LM_YOUR_TRACKS_LBL",
        "value": "Your tracks"
    },
    {
        "key": "BP_LM_LAYOUT_NAME",
        "value": "Layout name"
    },
    {
        "key": "BP_LM_LAYOUT_LENGTH",
        "value": "Layout length"
    },
    {
        "key": "BP_LM_GPX_LYT",
        "value": "gpx layout"
    },
    {
        "key": "BP_CM_CREATED_ON",
        "value": "Created on"
    },
    {
        "key": "BP_CM_CREATED_BY_YOU",
        "value": "Created by you"
    },
    {
        "key": "BP_CM_CREATED_BY",
        "value": "Created by <b>{placeholderName}</b>"
    },
    {
        "key": "BP_LM_SEARCH_BY_LOCATION",
        "value": "Search by location name etc..."
    },
    {
        "key": "BP_LM_DELETE_COVER_IMAGE",
        "value": "Delete cover image"
    },
    {
        "key": "BP_LM_SEARCH_TEXT",
        "value": "Search circuits and emotion tours around the world"
    },
    {
        "key": "BP_LM_CONFIRM_SUBTITLE",
        "value": "Are you sure you want to cancel? Any changes you made will not be saved."
    },
    {
        "key": "BP_LM_TRACK_SEARCH_TEXT",
        "value": "Search circuits around the world"
    },
    {
        "key": "BP_LM_EMOTION_TOUR_SEARCH_TEXT",
        "value": "Search emotion tours around the world"
    },
    {
        "key": "BP_LM_FIND_YOUR_TRACKS_MSG",
        "value": "Find your created Tracks and tours"
    },
    {
        "key": "BP_LM_CIRCUIT_SEARCH",
        "value": "Type circuit name city etc.."
    },
    {
        "key": "BP_LM_SCANNING_GEOFENCE_MSG",
        "value": "Scanning your Geo-fence"
    },
    {
        "key": "BP_CM_ADD_COVER_IMG",
        "value": "Add cover image"
    },
    {
        "key": "BP_LM_UPLOAD_TOUR",
        "value": "Upload a tour"
    },
    {
        "key": "BP_LM_CIRCUIT_GEO_FENCE_AREA",
        "value": "Circuit geo-fence area"
    },
    {
        "key": "BP_LM_NO_OF_LAYOUTS",
        "value": "No. of layouts"
    },
    {
        "key": "BP_LM_EDIT_DETAILS",
        "value": "EDIT DETAILS"
    },
    {
        "key": "BP_LM_CREATE_GEO_AREA",
        "value": "Start adding points on map to create a geo fence area"
    },
    {
        "key": "BP_LM_CENTRE_POINT",
        "value": "Click on the map to create a centre point"
    },
    {
        "key": "BP_LM_CIRCUIT_CREATED_SUCCESS",
        "value": "Circuit created successfully."
    },
    {
        "key": "BP_LM_CIRCUIT_DELETED_SUCCESS",
        "value": "Circuit deleted successfully."
    },
    {
        "key": "BP_LM_CIRCUIT_UPDATED_SUCCESS",
        "value": "Circuit updated successfully."
    },
    {
        "key": "BP_LM_TRACK_CREATED_SUCCESS",
        "value": "Track created successfully."
    },
    {
        "key": "BP_LM_TRACK_DELETED_SUCCESS",
        "value": "Track deleted successfully."
    },
    {
        "key": "BP_LM_TRACK_UPDATED_SUCCESS",
        "value": "Track updated successfully."
    },
    {
        "key": "BP_LM_TOUR_CREATED_SUCCESS",
        "value": "Emotion tour created successfully."
    },
    {
        "key": "BP_LM_TOUR_DELETED_SUCCESS",
        "value": "Emotion tour deleted successfully."
    },
    {
        "key": "BP_LM_TOUR_UPDATED_SUCCESS",
        "value": "Emotion tour updated successfully."
    },
    {
        "key": "BP_LM_INSIDER_SPOT_CREATED_SUCCESS",
        "value": "Insider spot created successfully."
    },
    {
        "key": "BP_LM_INSIDER_SPOT_DELETED_SUCCESS",
        "value": "Insider spot deleted successfully."
    },
    {
        "key": "BP_LM_INSIDER_SPOT_UPDATED_SUCCESS",
        "value": "Insider spot updated successfully."
    },
    {
        "key": "BP_LM_OFFROAD_CREATED_SUCCESS",
        "value": "Offroad track created successfully."
    },
    {
        "key": "BP_LM_OFFROAD_DELETED_SUCCESS",
        "value": "Offroad track deleted successfully."
    },
    {
        "key": "BP_LM_OFFROAD_UPDATED_SUCCESS",
        "value": "Offroad track updated successfully."
    },
    {
        "key": "BP_LM_DELETE_OFFROAD_TRACK_MSG",
        "value": "Are you sure you want to delete this offroad track?"
    },
    {
        "key": "BP_LM_PROFILE",
        "value": "Profile"
    },
    {
        "key": "BP_LM_URL_COPIED_SUCCESS",
        "value": "URL copied to clipboard"
    },
    {
        "key": "BP_CM_UPLOADING_FILE_LBL",
        "value": "Uploading your file "
    },
    {
        "key": "BP_LM_TRACK_LBL",
        "value": 'Track'
    },
    {
        "key": "BP_LM_TRACKS_LBL",
        "value": 'Tracks'
    },
    {
        "key": "BP_LM_CIRCUIT_DETAILS",
        "value": 'Circuit details'
    },
    {
        "key": "BP_LM_IMAGE_COPYRIGHT_INFO",
        "value": 'Image copyright information'
    },
    {
        "key": "BP_LM_IMAGE_ATTRIBUTION",
        "value": 'Image attribution'
    },
    {
        "key": "BP_LM_IMAGE_LICENSE",
        "value": 'Image license'
    },
    {
        "key": "BP_LM_LICENCE_URL",
        "value": 'License URL'
    },
    {
        "key": "BP_LM_IMAGE_URL",
        "value": 'Image URL'
    },
    {
        "key": "BP_LM_CIRCUIT_DLT_ERR",
        "value": 'Circuit deletion failed, track is already associated with given Circuit guid'
    },
    {
        "key": "BP_LM_COPYRIGHT_INFO",
        "value": '<span class="info-txt"><a href="{placeholderImgURl}" target="_blank" rel="noopener noreferrer">Photo</a></span> by {placeholderName} on <span class="info-txt"><a href="{placeholderImgLicense}" target="_blank" rel="noopener noreferrer">{placeholderLicense}</a></span>'
    },
    {
        "key": "BP_EM_CIAM_LINKED",
        "value": "Linked"
    },
    {
        "key": "BP_LM_TOUR_NAME_EMPTY",
        "value": "Tour name cannot be empty"
    },
    {
        "key": "BP_LM_CIRCUIT_GUID_REQ",
        "value": "Circuit guid cannot be null or empty"
    },
    {
        "key": "BP_LM_MISSING_TRACK_DETAILS",
        "value": "Missing track file attachment"
    },
    {
        "key": 'BP_LM_INVAID_FILE_TYPE',
        "value": 'Invalid input file format, please upload valid track file'
    },
    {
        "key": "BP_LM_INVALID_TRACK_GUID_FORMAT",
        "value": "Invalid track GUID format"
    },
    {
        "key": "BP_LM_VIN_OR_FIN_REQUIRED",
        "value": "Either vin or userId of the track is mandatory"
    },
    {
        "key": "BP_LM_ANNOTATION_ID_REQUIRED",
        "value": "Annotation id cannot be null or empty"
    },
    {
        "key": "BP_LM_PROFILE_ID_REQUIRED",
        "value": "Profile id is mandatory"
    },
    {
        "key": "BP_LM_INVALID_ANNOTATION_NAME",
        "value": "Invalid annotation name"
    }
    ,
    {
        "key": "BP_LM_INVALID_ANNOTATION_LANDMARK",
        "value": "Invalid annotation landmark"
    },
    {
        "key": "BP_LM_INVALID_ANNOTATION_PROPERTIES",
        "value": "Invalid properties for the annotation"
    },
    {
        "key": "BP_LM_INVALID_ANNOTATION_DESCRIPTION",
        "value": "Invalid description for the annotation"
    },
    {
        "key": "BP_LM_IMAGE_COPYRIGHT_DETAILS",
        "value": "Image copyright details"
    },
    {
        "key": "BP_LM_FILE_UPLOAD_ERR",
        "value": "File upload error"
    },
    {
        "key": "BP_LM_IMAGE_COPYRIGHT_SAVED_SUCCESS_MSG",
        "value": "Your images and copyright information have been successfully saved."
    },
    {
        "key": "BP_LM_IMAGE_PREVIEW_LBL",
        "value": "Image preview"
    }
];
