import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthAmplifyService } from '../shared/service/auth-amplify.service';
import { LanguageService } from '../shared/service/language.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authAmplifyService: AuthAmplifyService,
        private _languageService: LanguageService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {      
        //add auth header with jwt if user is logged in and request is to the api url this.authAmplifyService.getCurrentUserToken
        // const currentUser = this.authAmplifyService.getCurrentUserToken()
        // if (currentUser) {
        //     const isApiUrl = request.url.startsWith(environment.baseURL) || request.url.startsWith(environment.apiEndPoint);
        //     if (isApiUrl) {
        //         request = request.clone({
        //             setHeaders: {
        //                 Authorization: `Bearer ${currentUser.access_token}`
        //             }
        //         });
        //     }
        // }
        if (request.url.startsWith(environment.apiEndPoint) || (request.url.startsWith(environment.proxyUrl))) {
           
            request = request.clone({
                setHeaders: {
                    'locale': `${this._languageService.getUserSelectedLanguage()??'en-GB'}`,
                    'user-role': 'Business_Portal',
                },
                withCredentials:true
            });
        }
        
        return next.handle(request);
    }    
}