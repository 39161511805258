<div class="map-container">
  <div #map [style.width]="width" [style.height]="height"></div>
</div>
<div *ngIf="showLayerSwitchMenu">
  <span *ngIf="!isShowCircuitInfo && enableRecenterFeature; else markerClicked"
    [ngClass]="url1 === '/' + selectedLanguage + '/locations' ? 'icon_recenter_homePage' : 'icon_recenter'"
    class="recenter icon" title="Recenter" (click)="recentreToUserCurrentLocation(mapCoordinates, 'RECENTER_BUTTON')"></span>
  <ng-template #markerClicked>
    <ng-container *ngIf="enableRecenterFeature">
      <span class="recenter icon icon_recenter" title="Recenter"
        (click)="recenterUserToCircuitSelected(circleCoordinates)"></span>
    </ng-container>
  </ng-template>
  <button *ngIf="enableSatelliteView"
    [ngClass]="selectedLayer === 'normal'?'button-satellite-layer':'button-normal-layer'"
    class="switch-button-style selected-layer" (click)="switchMapView()"
    title="{{selectedLayer === 'normal' ? 'Satellite': 'Map'}}"></button>
</div>

