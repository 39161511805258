import { Component, EventEmitter, Output, TemplateRef, ViewChild, AfterContentChecked,ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/shared/constants/constants';
import { CommonModalService } from '../../events/services/common-modal.service';
import { DataServiceServiceProfile } from '../services/profile-data-service.service';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';


@Component({
  selector: 'invite-member-form',
  templateUrl: './invite-member-form.component.html',
  styleUrls: ['./invite-member-form.component.scss']
})
export class InviteMemberFormComponent {

  headerTitle:string='BP_PM_ADD_MEMBER_TITLE'
  closeButtonTitle:string=AppConstants.COMMON_BUTTON_LABELS.CLOSE
  saveButtonTitle:string='Add'
  isCloseModal:boolean=false
  showModal:boolean=true
  formSubmitted:boolean=false
  type:string='small-popup'
  isHeaderVisible:boolean = true
  isFooterVisible:boolean = true
  groupName:any
  errorAddMember:boolean = false;
  errorMessage: string = ''
  constructor(
     public fb:UntypedFormBuilder ,
     private commonModalService: CommonModalService,
     private dataServiceProfile: DataServiceServiceProfile,
     private cdref:ChangeDetectorRef,
     private _loadingSpinnerService:LoadingSpinnerService
    ) { }

  @ViewChild('showModalContent') showModalContent:TemplateRef<any> |any

  @Output()  closeModalEmitter =  new EventEmitter<boolean>();
  @Output()  getMemberListData = new EventEmitter<boolean>();
 
  addMemberForm=this.fb.group({
    userId:['',[Validators.required,
              Validators.minLength(3),
              Validators.maxLength(20),
              Validators.pattern('^[a-zA-Z0-9]*$')]
            ]
  })
  
  ngOnInit(): void {
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(true)
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(true)
    this.commonModalService._modalHeaderTitle.next(this.headerTitle) 
    this.addMemberForm.get('userId')?.valueChanges.subscribe(value => {
      this.validateInput(value);
    });

  }
 

  closeModal(){
     this.isCloseModal=true
     this.showModal = false;
     this.closeModalEmitter.emit(true);
  }

  onSubmit(){ 
    this.formSubmitted=true
    if(this.addMemberForm.valid){
    this._loadingSpinnerService.show()
    this.groupName=localStorage.getItem("aliasGroupName")
    let data = {
      "shortId": this.addMemberForm.value.userId.toUpperCase(),
      "profileId": this.groupName
    }
    this.dataServiceProfile.postMember(data).subscribe((res:any)=>{
      let data = res.body
      if(data){ 
        this.closeModal() 
        this.getMemberListData.emit(true);
      }
    }, (error:any) =>{
      this._loadingSpinnerService.hide()
      if(error["error"]["error-code"] == '1719'){
        this.errorAddMember = true 
        this.addMemberForm.controls['userId'].setErrors({ 'incorrect': true});
        this.errorMessage = error["error"]["error-message"]
      }
    }
    
  )
}
  }
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  validateInput(value: string): void {
    this.errorAddMember =  false;
    this.formSubmitted=false;
  }
}
