<ng-container *ngIf="!showNotFoundPage">
    <div *ngIf="showToast" class="toast-style">
        <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition" [placeholderObject]="placeholderObject" >
        </app-toast>
    </div>
    <app-status-modal *ngIf="isAddCircuit" [statusTitle]="statusTitle" [statusMessage]="statusMessage"
        [statusSubMessage]="statusSubMessage" [statusImage]="statusImage" [isShowStatus]="isAddCircuit">
    </app-status-modal>
    <div class="geo-header-style">
        <app-layout-details-header [primaryButtonTitle]="primaryButtonTitle" [title]="trackDetailsName"
            [secondaryButtonTitle]="secondaryButtonTitle" [primaryButtonColor]="primaryButtonColor"
            (primaryButtonClick)="primaryButtonClick()" (secondaryButtonClick)="secondaryButtonClick()"
            [isDisablePrimaryButton]="isDisablePrimaryButton">
        </app-layout-details-header>
    </div>

    <div class="map-style" *ngIf="mapValues">
        <app-circuit-leaflet-map class="geoman-class" [apiKey]="apiKey" [trackData]="trackData" [zoom]="zoom"
            [location]="locations" [radius]="radius" [isEditCircleFence]="isEditFence" [selectedOption]="selectedButton"
            [isZoomControlEnabled]="isZoomControlEnabled" (enableButton)="enableSaveButton($event)" [showLayerSwitchMenu]="showLayerSwitchMenu"
            (emitNotification)="updateNotification($event)" (emitRadius)="sendRadiusValuetoInput($event)" (enableRadiusBar)="enableRadiusInput($event)" >
        </app-circuit-leaflet-map>
        
    </div>
    <div class="notification-main">
        <app-geofence-toolbar (radiusValue)="sendRadiusValuetoMap($event)" (selectedTool)="optionSelected($event)" 
        [notificationMessage]="notificationMessage" [selectedButton]="selectedButton" [enableRadiusInputBox]="enableRadiusInputBox" [radius]="radiusValue"></app-geofence-toolbar>
    </div> 
    <app-common-alert-modal [title]="alertTitle" [message]="alertMessage" [isModalOpen]=isModalOpen
        [actionType]="actionType" (isModalResponseClicked)="getCancelModalResponse($event)"
        [cancelBtnTitle]="cancelBtnTitle" [actionBtnTitle]="actionBtnTitle"></app-common-alert-modal>
</ng-container>
<div *ngIf="showNotFoundPage">
    <app-page-not-found></app-page-not-found>
</div>