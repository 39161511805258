import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { appReducer } from "./admin-stores/app.reducer";
import { hydrationMetaReducer } from "./hydration/hydration.reducer";

export const reducers: ActionReducerMap<any> = {
  businessPortal: appReducer 
}

export const metaReducers: MetaReducer[] = [
  hydrationMetaReducer
]