import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModalService } from 'src/app/modules/events/services/common-modal.service';
import { LocationsHttpService } from '../../services/locations-http.service';
import { finalize } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { AppConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-audio-upload',
  templateUrl: './audio-upload.component.html',
  styleUrls: ['./audio-upload.component.scss']
})
export class AudioUploadComponent implements OnInit {
  @ViewChild('audioUpload') audioUpload: TemplateRef<any> | undefined;
  @Output() hideAudioUpload = new EventEmitter<boolean>();
  @Input() tourGuid: string = ''

  showModal: boolean = true;
  type:string='medium-popup'
  listOfLanguages: { title: string; value: string; }[] = [];
  showProgess: boolean = false;
  userLanguage: string = '';
  guid: string = '';
  fileUploadSuccessful: boolean = false;
  uploadedFileDetails: { name: any; size: any; dateUploaded: any, userLanguage: string } = <any>null;
  showToast: boolean = false;
  message: string = '';
  toastType: any;
  position: string = '';
  leftPosition: string = '';
  translationData: any;
  closeBtnTitle: string = ''
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS

  constructor(private _commonModalService: CommonModalService, 
    private _locationService: LocationsHttpService,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _translationService: TranslationService
    ) {
    this.route.params.subscribe(params => {
      this.guid = params.guid
    });
    this.listOfLanguages = [
      { title: 'BP_LANG_ENG', value: 'en_EN' },
      { title: 'BP_LANG_DE', value: 'de_DE' }
    ];
   }

  ngOnInit(): void {
    this.buttonNames = AppConstants.COMMON_BUTTON_LABELS;
    this.showModal = true;
    this._commonModalService._modalHeaderIsCloseBtnVisible.next(true)
    this._commonModalService._modalHeaderIsSaveBtnVisible.next(false)
    this.closeBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CLOSE;
    this._commonModalService._modalHeaderTitle.next('BP_LM_UPLOAD_AUDIO_FILE_LBL');
    this._toastService.toastDetails.subscribe((status:any) => {
      this.showToast = status.isShown;
      this.message = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  getUserSelectedLang(event: {title: string, value: string}): void {
    this.userLanguage = event.value;
  }

  /**
   * @description Calls upload tour guide API
   * Accepts json file only and only 1 file at a time
   * @param file 
   */
  onFileSelected(file: any): void {
    const selectedFile = file.target.files[0];
    this.uploadedFileDetails = {
      name: file.target.files[0].name,
      size: file.target.files[0].size,
      dateUploaded: file.target.files[0].lastModifiedDate.toLocaleDateString(),
      userLanguage: this.userLanguage == 'en_EN' ? 'BP_LANG_ENG':'BP_LANG_DE'
    }
    this.showProgess = true;
    this._locationService.uploadTourSpeechJson(selectedFile, this.guid ?? this.tourGuid, this.userLanguage)
      .pipe(finalize(() => {
        this.showProgess = false;
        file.target.value = null;
      })).subscribe({
        next: (response: any) => {
          // Do something
          this.fileUploadSuccessful = true;
          this.showToastMessage(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TOUR_SENT_TO_CAR_SUCCESS);
        }, error: err => {
          this.uploadedFileDetails = <any>null;
          this.showToastMessage(ToastType.Error, AppConstants.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG);
        }
      });
  }

  public closeModal(): void {
    this.showModal = false;
    this.hideAudioUpload.emit(false);
  }

  public showToastMessage(type: any, message: string): void {
    this._toastService.show(type, message, '8%', '40%');
    setTimeout(() => {
      this._toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }


}
