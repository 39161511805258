import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaCarouselService } from '../../services/media/media-carousel.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { Subscription } from 'rxjs';
import { ELAND_MARK } from '../../interfaces/business-tour-create.interface';
import {AppConstants} from 'src/app/shared/constants/constants'
@Component({
  selector: 'app-dealer-categories',
  templateUrl: './dealer-categories.component.html',
  styleUrls: ['./dealer-categories.component.scss']
})
export class DealerCategoriesComponent implements OnInit {

  // @Input() highlightDataLoaderPage: any[] = [];
  @Input() categoryList: any ;
  @Input() showLoader:any = true;
  @Input() modalType: any = 'category';
  @Output() backClicked= new EventEmitter<any>();
  @Output() sendSelectedCategory= new EventEmitter<any>();
  addBorder:any = false;
  animationList :string[] = [];
  highlightContainer: any;
  highlightIndex:number = 0;
  translationData: any;
  checkboxToggleValue: boolean = false;
  subscription: Subscription = new Subscription();
  public isCuratedTourEnabled: boolean = false;
  public buttonNames = AppConstants.COMMON_BUTTON_LABELS

  constructor(private _mediaCarouselService: MediaCarouselService,
              private translationService: TranslationService) { }

  ngOnInit(): void {
    this.buttonNames = AppConstants.COMMON_BUTTON_LABELS;
    if (!this.checkboxToggleValue) {
     //empty if     
    } else {
      this._mediaCarouselService.highlightList.push(this._mediaCarouselService.highlightList[0]);
    }  
    this.startAnimation();
    this.subscription = this.translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
      this.isCuratedTourEnabled = this.translationService.featureFlags.locations.enableCuratedTour;
    });
  }

  sendCategory(category: any, curatedTour: boolean) {
    const event = {
      backSelect: false,
      curatedTour
    }
    this._mediaCarouselService.hideContentOnLoadersPage.next(true);
    if (!curatedTour) {
      const categorySelected = category;
      this.sendSelectedCategory.emit(categorySelected);
    } else {
      const categorySelected = this.categoryList.find((category: any) => 'FASTEST' === category.category?.toUpperCase());
      this.sendSelectedCategory.emit(categorySelected);
    }
    this.backClicked.emit(event);
  }

  sendBackClick(){
    const event = {
      backSelect: true,
      curatedTour: false
    }
    if(this.modalType === 'loader') {
      this._mediaCarouselService.hideContentOnLoadersPage.next(false);
    }
    this.backClicked.emit(event);
  }

  public startAnimation(): void {
    if ( this._mediaCarouselService.highlightList?.length) {
      // Why are we using spread + push???
      this.animationList.push(...this._mediaCarouselService.highlightList);
      if (!this.checkboxToggleValue) {
        // This code will rearrange start, highlight and destination. Start should always be at top and destination at bottom of array.
        // any better way to handle?
        const originIndex = this.animationList.findIndex((highlight: string) => highlight.includes(ELAND_MARK.ORIGIN));
        const destinationIndex = this.animationList.findIndex((highlight) => highlight.includes(ELAND_MARK.DESTINATION));
        if (originIndex !== 0) {
          const origin = this.animationList.find((highlight) => highlight.includes(ELAND_MARK.ORIGIN));
          this.animationList.splice(originIndex, 1);
          this.animationList.unshift(origin ?? '');
        }
        if (destinationIndex !== this.animationList.length - 1) {
          const destination = this.animationList.find((highlight) => highlight.includes(ELAND_MARK.DESTINATION));
          this.animationList.splice(destinationIndex, 1);
          this.animationList.push(destination ?? '');
        }
      }
      this.animationList = this.animationList.map((animation: string) => animation?.split('-')[0]);
      setInterval(() => {
        this.animateWaypoints();
      }, 1000);
    }
    
  }

  animateWaypoints () {
    this.highlightIndex ++
    if(this.highlightIndex > this.animationList.length-1){
      this.highlightIndex = 0;
    }
  }

  /**
   * @description used the category name from categories response- and displays it on UI
   * The method also handles translations
   * @param name 
   * @returns string
   */
  public formatCategoryTitle(name: string): string {
    const categories: any = {
      'SIGHT_SEEING': 'BP_LM_CTG_SIGHT_SEEING',
      'WATER_VIEW': 'BP_LM_CTG_WATER_VIEW',
      'CULTURAL': 'BP_LM_CTG_CULTURAL',
      'EXPLORE_NATURE': 'BP_LM_CTG_EXPLORE_NATURE',
      'PANORAMA': 'BP_LM_CTG_PANORAMA',
      'FASTEST': 'BP_LM_CTG_FASTEST',
      'UPS_AND_DOWNS' : 'BP_LM_CTG_UPS_DOWNS',
      'STRAIGHT_AHEAD': 'BP_LM_CTG_STRAIGHT_AHEAD',
      'FIELD_TRIP': 'BP_LM_CTG_FIELD_TRIP',
      'LUXURY_PERFORMANCE': 'BP_LM_CTG_LUX_PRFRMNC',
      'SCENIC_POINTS': 'BP_LM_CTG_SCNC_PNTS'
    }
    const categoryName = (name: string) => categories[name.toUpperCase()] || name.replace('_', ' ');
    return categoryName(name);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
