import { Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-switch',
  templateUrl: './profile-switch.component.html',
  styleUrls: ['./profile-switch.component.scss']
})
export class ProfileSwitchComponent implements OnInit,OnDestroy {

  constructor(
    private sharingData:SharingDataService,
    private authAmplifyService: AuthAmplifyService,
    private translationService:TranslationService
  ) { } 
  logoutUrl: string = environment.logout;
  test:boolean=false
  isprofileswitchselected:boolean=false
  isMobileMenuVisible:boolean=false
  profileVisible:boolean=false
  isAliasSelected:any
  menuVisible:boolean=true
  userName:any
  userEmail:any
  subscriptions: Subscription[] = [];
  translationData:any
  ngOnInit(): void {
    this.userName=localStorage.getItem('userName')
    this.userEmail=localStorage.getItem('userEmail')
    this.isAliasSelected=localStorage.getItem("profile")
    this.subscriptions.push(this.sharingData.getProfileSwitched().subscribe((data)=>{
      if(data){
        this.isprofileswitchselected=true
      }
    }))
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      }));
    this.sharingData.getProfileSelected().subscribe((data)=>{
      if(data){
        this.isprofileswitchselected=false
      }
    })
    
  }
  logout() {
    // this.authAmplifyService.logout();
    this.authAmplifyService.logout().subscribe(response => {
      window.location.href = response.body.data;
      console.log(response)
    });
  }
 
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
