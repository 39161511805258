import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  CanLoad,
  UrlSegment,
} from "@angular/router";
import { Auth } from "aws-amplify";
import { Observable } from "rxjs";
import { AuthAmplifyService } from "../shared/service/auth-amplify.service";
import { TranslationService } from "../shared/service/translation.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanLoad, CanActivate {
  userData: any;
  jwtData: any;
  aliasListSelected: any;

  constructor(
    private router: Router,
    private authAmplifyService: AuthAmplifyService,
    private _translationService: TranslationService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    if (localStorage.getItem("profile")) {
      this.aliasListSelected = true;
    }
    const userData = localStorage.getItem('loggedIn')

    if (userData) {
      let isAccessible = false;
      const enableProfileFeature = this._translationService.featureFlags?.profile.enableProfileFeature;
      if (enableProfileFeature) {
        if (state.url.includes("/locations") &&
            this.aliasListSelected
        ) {
          //.includes('LocationAdministrators') will be used once role based access comes into picture
          isAccessible = this.handleFlagBasedLocationRedirects(state);
        } else if (
          state.url.includes("/events")&&
          this.aliasListSelected
        ) {
          isAccessible = true;
        } else if (state.url.includes("/profile")) {
          isAccessible = this.handleFlagBasedProfileRedirects(state);
        } else {
          this.router.navigate(["profile"]);
        }
      } else {
        if (
          state.url.includes("/locations")
        ) {
          //.includes('LocationAdministrators') will be used once role based access comes into picture
          isAccessible = this.handleFlagBasedLocationRedirects(state);
        } else if (
          state.url.includes("/events")
        ) {
          isAccessible = true;
        } else {
          this.router.navigate(["events"]);
        }
      }
      return isAccessible;
    } else {
      // return Auth.currentSession().then(
      //   (res) => {
      //     let accessToken = res.getAccessToken();
      //     let jwt = accessToken.getJwtToken();
      //     let jwt_expires_in = accessToken.getExpiration();
      //     let refreshToken = res.getRefreshToken();
      //     let jwtRefreshToken = refreshToken.getToken();
      //     let idToken = res.getIdToken();
      //     let jwtIdToken = idToken.getJwtToken();
      //     let fullData = {
      //       id_token: jwtIdToken,
      //       access_token: jwt,
      //       refresh_token: jwtRefreshToken,
      //       expires_in: jwt_expires_in,
      //       token_type: "Bearer",
      //     };
      //     this.authAmplifyService.setUserLoginStatus(true);
      //     this.authAmplifyService.setCurrentUserToken(fullData);
      //     let isAccessible = false;
      //     this.aliasListSelected = localStorage.getItem("profile");
      //     const enableProfileFeature = this._translationService.featureFlags.profile.enableProfileFeature;
      //     if (enableProfileFeature) {
      //       if (state.url.includes("/locations") && this.aliasListSelected) {
      //         isAccessible = this.handleFlagBasedLocationRedirects(state);
      //       } else if (
      //         state.url.includes("/events") &&
      //         this.aliasListSelected
      //       ) {
      //         isAccessible = true;
      //       } else if (state.url.includes("/profile")) { 
      //         isAccessible = this.handleFlagBasedProfileRedirects(state);
      //       } else {
      //         isAccessible = true;
      //       }
      //     } else {
      //       if (state.url.includes("/locations")) {
      //         isAccessible = this.handleFlagBasedLocationRedirects(state);
      //       } else if (state.url.includes("/events")) {
      //         isAccessible = true;
      //       }
      //       else {
      //         this.router.navigate(["events"]);
      //       }
      //     }
      //     return isAccessible;
      //   },
      //   (_res) => {
      //     const fullPath = state.url;
      //     // localStorage.setItem("redirectUrl", fullPath);
      //     this.router.navigate(["/auth"]);
      //     return false;
      //   }
      // );
          // let isAccessible = false;
          // this.aliasListSelected = localStorage.getItem("profile");
          // const enableProfileFeature = this._translationService.featureFlags.profile.enableProfileFeature;
          // if (enableProfileFeature) {
          //   if (state.url.includes("/locations") && this.aliasListSelected) {
          //     isAccessible = this.handleFlagBasedLocationRedirects(state);
          //   } else if (
          //     state.url.includes("/events") &&
          //     this.aliasListSelected
          //   ) {
          //     isAccessible = true;
          //   } else if (state.url.includes("/profile")) { 
          //     isAccessible = this.handleFlagBasedProfileRedirects(state);
          //   } else {
          //     isAccessible = true;
          //   }
          // } else {
          //   if (state.url.includes("/locations")) {
          //     isAccessible = this.handleFlagBasedLocationRedirects(state);
          //   } else if (state.url.includes("/events")) {
          //     isAccessible = true;
          //   }
          //   else {
          //     this.router.navigate(["events"]);
          //   }
          // }
          // return isAccessible;
          this.router.navigate(['auth'])
          return false;
    }
  }

  private handleFlagBasedProfileRedirects(state: RouterStateSnapshot): boolean {
    let isAccessible = false;
    const profileFeatureFlags = this._translationService.featureFlags?.profile;
    switch (true) {
      case state.url.includes("member-list") && profileFeatureFlags.enableManageBusiness:
      case state.url.includes("fleet") && profileFeatureFlags.enableManageFleet:
        isAccessible = true;
        break;
      case state.url.includes("member-list") && !profileFeatureFlags.enableManageBusiness:
      case state.url.includes("fleet") && !profileFeatureFlags.enableManageFleet:
        this.router.navigate(["not-found"]);
        break;
      default:
        isAccessible = true;
        break;
    }
    return isAccessible
  }

  private handleFlagBasedLocationRedirects(state: RouterStateSnapshot): boolean {
    let isAccessible = false;
    const locationFeatureFlags = this._translationService.featureFlags.locations
    switch (true) {
      case state.url.includes("business-tour") && locationFeatureFlags.enableEmotionTourFeature:
      case state.url.includes("circuit") && locationFeatureFlags.enableCircuitFeature:
        isAccessible = true;
        break;
      case state.url.includes("business-tour") && !locationFeatureFlags.enableEmotionTourFeature:
      case state.url.includes("circuit") && !locationFeatureFlags.enableCircuitFeature:
        this.router.navigate(["not-found"]);
        break;
      default:
        isAccessible = true;
        break;
    }
    return isAccessible;
  }


  getRoleGroup(jwt: string) {
    try {
      return JSON.parse(atob(jwt.split(".")[1]));
    } catch (e) {
      return null;
    }
  }

  canLoad(
    route: Route,
    _segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const enableProfileFeature = this._translationService.featureFlags?.profile.enableProfileFeature;
    if (enableProfileFeature) {
      return true;
    } else {
      this.router.navigate(['/page-not-found'])
      return false;
    }
  }
}
