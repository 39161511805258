import { translationsArray } from './../../shared/pipes/translations-fallback';
import { AppConstants } from 'src/app/shared/constants/constants';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  message: string = AppConstants.COMMON_ERROR_MSGS.PAGE_NOT_FOUND_MSG;
  translationsArray = translationsArray;

  ngOnInit(): void {
    this.message = AppConstants.COMMON_ERROR_MSGS.PAGE_NOT_FOUND_MSG;
  }

}
