import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CustomTranslationPipe } from "src/app/shared/pipes/custom-translation.pipe";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { environment } from "../../../../../environments/environment";
import { TrackuploadComponent } from "../../pages/trackupload/trackupload.component";
import { LocationsHttpService } from "../../services/locations-http.service";
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { LoadingSpinnerService } from "src/app/shared/service/loading-spinner.service";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { AppConstants } from "src/app/shared/constants/constants";
import { CircuitsServicesService } from "../../services/circuits-services.service";

declare let require: any;
let polyline = require("google-polyline");

@Component({
  selector: "app-circuit-info-modal",
  templateUrl: "./circuit-info-modal.component.html",
  styleUrls: ["./circuit-info-modal.component.scss"],
})
export class CircuitInfoModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public circuitDetails: any;
  private emptyImageSrc = "assets/locations/coverImage.png";
  apiKey = environment.apikey;
  navigateUrl = '';
  navigateMessage = "";
  trackLength = "";
  isApiCallDispatched = false;
  public location:any=[];
  @Input()
  public emotionTourDetails: any;
  @Input()
  public navigateTo: any;
  @Input()
  public isshowDropDown:any=true;
  public emotionTourTrackencodedGpsPoints: any;
  @Output() public isShowMapView = new EventEmitter<object>();
  alertTitle:any;
  alertMessage:any;
  isModalOpen:any;
  actionType:any;
  alertCancelBtnTitle:any;
  alertActionBtnTitle:any;

  isMenuDropdownVisible = false;
  isDeleteSelected=false;
  isEditSelected=false;
  isReadMore:boolean = true;
  imageData:any;
  imageDataUrl: any;
  translationData: any;
  phrasePipe: any;
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  public locationConstants = LOCATIONS_CONSTANTS;
  copyrightInfo: any;
  

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private _spinnerService: LoadingSpinnerService,
    private locationsHttpService: LocationsHttpService,
    private translationService: TranslationService,
    private _circuitService: CircuitsServicesService
  ) {}

  ngOnInit(): void {

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );

    this.phrasePipe = new CustomTranslationPipe
    
    this.isshowDropDown=true;
    this.trackLength =
      "Track Layouts " + "(" + (this.circuitDetails?.layouts.length ?? 0) + ")";

    this.getTrackLength();
    this.navigateMessage = "BP_LM_BACK_TO_LOCATIONS_BTN_LBL";  
    this.getCoverImageDetails();  
    
  }

  getCoverImageDetails() {
    this.locationsHttpService.getCircuit(this.circuitDetails?.guid).subscribe({
      next: (item: any) => {
        const circuitDetails = item.body;
        this.imageDataUrl = circuitDetails.media?.image[0].originalUrl ?? '../../../../../assets/locations/coverImage.jpg'; 
        this.copyrightInfo = circuitDetails.media?.image[0].copyright
      },
      error: (error: any) => {
      },
    });
  }


  ngOnChanges() {
    if (this.circuitDetails) {
      this.getTrackLength();
    }
    if(this.isshowDropDown && this.isMenuDropdownVisible){
      this.isMenuDropdownVisible=!this.isMenuDropdownVisible
      }
  }
  navigateBack() {
    this.router.navigateByUrl(`/locations`);
  }

  getCancelModalResponse(event: any) {
    this.isEditSelected = false;
    this.isDeleteSelected = false;
    this.isModalOpen = false;
    if (event.action && event.actionType == "delete-circuit") {
      if (this.circuitDetails?.guid) {
        this._spinnerService.show();
        this.isApiCallDispatched = true;
        this.locationsHttpService
          .deleteCircuit(this.circuitDetails.guid)
          .subscribe({
            next: (res: any) => {
              this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_DELETE_SUCCESS_MSG, '8%', '45%');
              setTimeout(() => {
                this._spinnerService.hide();
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
              setTimeout(() => {
                this.isShowMapView.emit({ showSearch: true, deletedCircuitGuid: this.circuitDetails?.guid });
                this.router.navigateByUrl(`/locations`);
              }, 2000);
              this.alertModalService.onAction.next(null);
              this.isApiCallDispatched = false;
              this.isMenuDropdownVisible = false;
            },
            error: (error: any) => {
              this._spinnerService.hide();
              const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
              this.toastService.show(ToastType.Error, errorMsg, '1%', '40%'); setTimeout(() => {
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
              this.alertModalService.onAction.next(null);
              this.isApiCallDispatched = false;
              this.isMenuDropdownVisible = false;
            },
          });
      }
    } else {
      this.isModalOpen = false;
      this.isDeleteSelected = false;
      this._spinnerService.hide();
    }
  }


  getTrackLength() {
    this.trackLength =
      "Track Layouts " + "(" + (this.circuitDetails?.layouts.length ?? 0) + ")";
      this.location=this.circuitDetails?.location?.split(",")
  }

 

  goToCircuitDetails() {
    let prevData = { state: { isprevPagelocation:false ,shareable: true} }
    this.router.navigateByUrl(`/locations/circuit/${this.circuitDetails.guid}/edit`, prevData);
  }  addNewLayout() {
    this.dialog.open(TrackuploadComponent, {
      backdropClass: "backdropBackground",
      width: "520px",
      height: "312px",
      panelClass: "track-upload",
    });
  }
  dropdownFixPosition(event:any) {
    this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    if(!this.isshowDropDown && !this.isMenuDropdownVisible){
    this.isshowDropDown=!this.isshowDropDown
    this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    }
    event.stopPropagation();

  }
  hideDropDown(){
    if(this.isshowDropDown && this.isMenuDropdownVisible){
      this.isshowDropDown=!this.isshowDropDown;
    }
  }

  public deleteCircuit(): void {
    this.isDeleteSelected=true;
    this.isEditSelected=false;
    this.isModalOpen = true;
    this.alertTitle = "BP_LM_CONFIRM_DELETE_TITLE";
    this.alertMessage = "BP_LM_CONFIRM_DELETE_MESSAGE"
    this.actionType = "delete-circuit"
    this.alertCancelBtnTitle = this.buttonNames.CANCEL;
    this.alertActionBtnTitle = this.buttonNames.DELETE;
  }

  public editCircuit(): void {
    this.isEditSelected=true;
    this.isDeleteSelected=false;
  }
  
  showSearch(search:any){
    this.navigateUrl=this.navigateTo;
    this.isShowMapView.emit({showSearch:search.showSearch});
    
      }
  ngOnDestroy() {
    this.circuitDetails = null;
  }
 
}


