<div class="search-box-main">
  <div class="loc-form-group">
    <div class="input-group">
      <span *ngIf="!showAnnotationsSearch" class="icon icon_search"></span>
      <input id="{{searchId}}" class="form-control" type="text"
        [ngClass]="isDisabled?'disabled-text':''" [formControl]="searchTerm"
        *ngIf="showSearchResult && !showAnnotationsSearch"
        placeholder="{{placeholderText | customTranslation:translationData}}" autocomplete="off" [disabled]="isDisabled"
        (mousedown)="emitChange()" [ngModel]="selectedLocationName" (focus)="showRecentSearch=true"/>
      <input id="{{searchId}}" class="form-control waypoints-class" type="text"
        [ngClass]="isDisabled?'disabled-text':''" [formControl]="searchTerm"
        *ngIf="showSearchResult && showAnnotationsSearch"
        placeholder="{{placeholderText | customTranslation:translationData}}" autocomplete="off" [disabled]="isDisabled"
        (mousedown)="emitChange()" />

      <input id="{{searchId}}" (keyup)="SearchEmotionTour(selectedValue)" class="form-control "
        [ngClass]="isDisabled?'disabled-text':''" [(ngModel)]="selectedValue" autocomplete="off" [disabled]="isDisabled"
        *ngIf="!showSearchResult && !showAnnotationsSearch" type="text" (mousedown)="emitChange()" />
      <input id="{{searchId}}" (keyup)="SearchEmotionTour(selectedValue)" class="form-control waypoints-class"
        [(ngModel)]="selectedValue" [ngClass]="isDisabled?'disabled-text':''"
        *ngIf="!showSearchResult && showAnnotationsSearch" type="text" [disabled]="isDisabled" autocomplete="off"
        (mousedown)="emitChange()" />
      <span class="icon icon_input_close"
        *ngIf=" ((searchTerm.value != null && searchTerm.value != '') || selectedValue?.length) || showAnnotationsSearch "
        (click)="cancle(); emitChange()" alt="..."></span>
    </div>
  </div>
  <div class="result-sec">
    <div [ngClass]="
        (quickSearchData?.length && quickSearchData.type != 'emotion_tour')
          ? 'main-div'
          : ''
      " class="search-result">
      <!-----------Search Changes for Emotion Tour Locations, Circuit Locations Start Here--------->
      <ng-container *ngIf="this.page && !isCreateEmotionTour && this.type != 'addDealerAnnotation' && showRecentSearch">
          <ul *ngFor="let location of recentSearchData; let i = index">
            <li *ngIf="location.position && location.title && i < 5" (click)="selectLocation(location)">
              <span class="icon icon_recent_search"></span><div innerHTML="{{ location.name | highlightSearch: searchTerm.value }}"  ></div>
            </li>
          </ul>
        <ul *ngFor="let location of quickSearchData">
          <li *ngIf="location.address" (click)="selectLocation(location)">
            <div innerHTML="{{ location.address?.label | highlightSearch: searchTerm.value }}"  ></div>
          </li>
        </ul>
        <ul>
          <li *ngIf=" searchTerm?.value != null && searchTerm?.value != '' && isNoResult ">
            <div>{{'BP_LM_NO_SEARCH_RESULTS' | customTranslation : translationData}}</div>
          </li>
        </ul>
      </ng-container>
      <!-----------Search Changes for Emotion Tour Locations, Circuit Locations End Here--------->

      <ng-container *ngIf="this.page && isCreateEmotionTour && this.type != 'addDealerAnnotation'">
        <ng-container *ngIf="this.showSearch">
          <ul *ngFor="let location of quickSearchData">
            <li *ngIf="location.address" (click)="selectEmotionTourLocation(location)">
              <div innerHTML="{{ location.address?.label | highlightSearch: selectedValue }}" ></div>
            </li>
          </ul>
        </ng-container>

        <ul>
          <li *ngIf=" selectedValue != null && selectedValue != '' && isNoResult ">
            <div>No search results</div>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="this.type === 'addDealerAnnotation'">
        <ng-container *ngIf="this.showSearch">
          <ul *ngFor="let location of quickSearchData.features">
            <li (click)="selectEmotionTourLocation(location)">
            <div innerHTML="{{ location.properties?.osm_name?.name | highlightSearch: selectedValue }}" ></div>
          </li>
        </ul>
      </ng-container>
      
      <ul>
          <li *ngIf=" selectedValue != null && selectedValue != '' && isNoResult ">
            <div>No search results</div>
          </li>
        </ul>
      </ng-container>


      <!-----------------Main Page Search Changes for Locations Start Here--------------->
      <ng-container *ngIf="!this.page" #elseTemplateName>
        <ul *ngFor="let location of quickSearchData">
          <li (click)="selectCircuit(location, null)">
            <img class="pin-icon" [src]="location.Icon" alt="..." />
            <div class="list">
              <div innerHTML="{{ location.name | highlightSearch: searchTerm.value  }}" ></div>
            </div>
          </li>
          <div *ngIf="location.layouts">
            <li *ngFor="let track of location.layouts">
              <div class="track-list" (click)="selectCircuit(location, track)"
                innerHTML="{{ track.name | highlightSearch: searchTerm.value }}"></div>
            </li>
          </div>
        </ul>
        <ul>
          <li *ngIf=" searchTerm?.value != null && searchTerm?.value != '' && isNoResult && !quickSearchData.length  ">
            <div>No search results</div>
          </li>
        </ul>
      </ng-container>
      <!-----------------Main Page Search Changes for Locations End Here--------------->
    </div>
  </div>
</div>