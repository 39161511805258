import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from '../../service/translation.service';
type Typedeclare = string | string[]|Set<string>;
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() title !:string;
  @Input() buttonClass !: Typedeclare;
  @Input() isIconVisible: boolean = false;
  @Input() isTitleVisible: boolean = false;
  @Input() buttonType: string  = 'button';
  @Input() buttonDisabled!:boolean;
  @Input() iconEleClass!: Typedeclare;
  @Input() buttonRole: string="button";
  @Input() titleEleClass!: Typedeclare;
  @Input() buttonId: any;

  translationData: any;

  
  constructor(private translationService: TranslationService) {}
  
  @Output() functionEmitter =new EventEmitter<string>();
 
  customFunctiionName(){
      this.functionEmitter.emit();
  }

  ngOnInit(): void {
    
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }
  

}

