import { CarouselData, CuratedTourRequest, ETOUR_CREATION_STEPS, Geometry, RecentSearch, WaypointDataToDelete } from './../../interfaces/business-tour-create.interface';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as L from "leaflet";
import { Location } from "@angular/common";
import { Subscription, concatMap, finalize, interval, lastValueFrom, startWith, switchMap } from "rxjs";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../services/locations-http.service";
import { DealerTourDbService } from "./dealerflow.db";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { AutoSuggestResponse, BusinessTourPreview, CategoriesResponse, ELAND_MARK, Feature, Position } from "../../interfaces/business-tour-create.interface";
import { AlertModalService } from '../../services/modal/alert-modal.service';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { RandomNumberGeneratorService } from 'src/app/shared/service/random-number-generator.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { AmgAnnoDtlsResponse } from '../../interfaces/amg-annotation.interface';
import { CreateMapService } from '../../services/create-map.service';
import { ELOCATION_SCREENS, EALL_TRACKS  } from '../../interfaces/search-location.interface';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
let polyline = require("google-polyline");
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';
import { error } from 'console';

@Component({
  selector: "app-create-dealer-flow",
  templateUrl: "./create-dealer-flow.component.html",
  styleUrls: ["./create-dealer-flow.component.scss"],
})
export class CreateDealerFlowComponent implements OnInit {
  mapInstance: any;
  tileLayer: any;
  apiKey = environment.apikey;
  coordinate = [48, 9];
  currentStep: any = ETOUR_CREATION_STEPS.SECOND;
  locationOnMap: any;
  startAdded: any = false;
  destinationAdded: any = false;
  waypointAdded: any = false;
  popupValues: any = [];
  startIcon: any;
  destinationIcon: any;
  startMarker: any;
  destinationMarker: any;
  waypointsData: any;
  waypointsList: any = [];
  startPointData: any;
  destinationPointData: any;
  startCoordinates: any;
  destinationCoordinates: any;
  encodedcoordinates: any;
  waypointslatlngs: Position[] = [];
  waypolyline: any;
  waypointsCoordinatesList: any;
  categories: any;
  showCategories = false;
  annotationResponse: any;
  modalType: any = "spinner";
  categorySelected: any;
  isTourCreated: any;
  tourName: string | undefined;
  tourDesc: string | undefined;
  disableGenerateButton: boolean = true;
  showLoader: boolean = true;
  tourPublished: boolean = false;
  emotionTourTrackencodedGpsPoints: BusinessTourPreview = <any>null;
  createdTourGuid: string = '';
  EmotionTourMarkers: any;
  polyline: any;
  timeInterval: any;

  showToast: any = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;
  tourDescription: string = ''; // Unused variable
  tourPublishStage: boolean = false;
  abortDataOnCancel: boolean = false;
  waypointIcon: any;
  waymarker: any;
  annotationData: any;
  // toggleSelected: boolean = true;
  startLocationButtonClicked: boolean = false;
  disablePublishButton: boolean = false;
  showLoaderUntillGeoJsonCompletes: boolean = false;
  highlightDataLoaderPage: any[] = [];
  annotationDataSet: any;
  showAddIconMapHover: boolean = false;
  streatViewZoomlevel: number = LOCATIONS_CONSTANTS.MAX_STATE_ZOOM_FOR_MAP_PINS;
  startMarksDisabled: boolean = false;
  destinationMarksDisabled: boolean = false;
  waymarkerMarksDisabled: boolean = false;
  dropPinCanceledIfSearchSelected: boolean = false;
  wayPointsDataObj: any;
  opacityMarkers: any[] = [];
  waypointArray: any = [];
  annotationDataSetArray: any[] = [];
  subscription: Subscription[] = [];
  deleteHighlightflag: boolean = false;
  clickInsiderPointCancelFlag: boolean = false;
  mediaFiles: File[] = [];

  // Post Tour Publish variable declarations go here
  public displayPublishedDetails: boolean = false;
  public tourInfoPostPublish: { guid: string | undefined, 
    createdDate: string | undefined; 
    nickName: string | undefined;
    tourDescription: string | undefined;
    tourName: string | undefined;
    trackVersions: string[] | [];
  } | undefined;
  public coverImg: string = '';
  public selectedAnnotationPinFromMap: string = '';
  showModal: any;
  nameOfTour: any;
  gpxUploaded:boolean = false;
  uploadedTourName: string = '';
  editActive: boolean = false;
  editedPinIndex: any;
  deleteEditedPin: boolean = false;
  mapPinIndex: any;
  markerToBeEdited: any;
  storedEditCoordinates: any;
  editStartPin: boolean  = false;
  editDestinationPin: boolean = false;
  editHighlightPin: boolean = false;
  createdHighlightList: any = [];
  editedHighlightInfo:any;
  disableEdit:boolean = true;
  addEditedDetails:boolean = false; 
  tourId: any;
  zoom: number = 3;
  mapInitialCoordinates: any = [52.520007, 13.404954]; 
  initialCoordinates: any = {
    coords: {
      latitude: 52.520007,
      longitude: 13.404954
    }
  };
  language: string = '';
  public isRoundTrip: boolean = false;
  normalTile: any;
  satelliteLayer: any;
  layerControl: any;
  selectedLayer: any = this._createMapService.selectedLayer ?? 'normal';
  mapCoordinates: any;
  mapZoom: number = LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC;
  routeTourId: string = '';
  public displayFleetManagement: boolean = false;
  tourFormDetails: any = {};
  public disableUpdateBtn: boolean = false;
  editAmgAnnotationLoader: boolean = false;
  zoomControl: L.Control.Zoom = L.control.zoom();
  autoAnnotate:boolean = true;
  createdAnnotationId:string[] = []
  removedTours:string[] = [];
  editAfterPublishActive: boolean = false;
  initiateWaypointSoftDelete: WaypointDataToDelete ={
    index: 0,
    name: '',
    annotationId: ''
  } ;
  public isOffroadTourFlow: boolean = false;


  constructor(
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private _actRoute: ActivatedRoute,
    private db: DealerTourDbService,
    private toastService: ToastService,
    private mediaCarouselService: MediaCarouselService,
    private _location: Location,
    private alertModalService: AlertModalService,
    private stateService: CircuitsServicesService,
    private changeDetectorRef: ChangeDetectorRef,
    private languageService: LanguageService,
    private randomNumberService: RandomNumberGeneratorService,
    private cdr: ChangeDetectorRef,
    private _createMapService: CreateMapService,
    private _circuitService: CircuitsServicesService,
    private _spinnerService: LoadingSpinnerService,
    private _storeManagerService: StoreManagerService
  ) {
    if (!this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid')) {
      this.removeIndexedDb();
    } else {
      this._createMapService.getUserLocation().then((res) => {
        this.mapCoordinates = res;
        // Shreya to check with Ashrith - When we are creating a new tour, the map should be centered to user location
        // If yes, this code is needed else we can get rid if this.
        // this.recentreToUserLoc(this.mapCoordinates, ELOCATION_SCREENS.LANDING_PAGE, this.currentStep);
      })
    }
    this._createMapService.getCurrentMapData();
    this.mapCoordinates = this._createMapService._currentMapDetails.latLng
    this.zoom = this._createMapService._currentMapDetails.zoom;
  }
  @ViewChild("mapElement") el!: ElementRef<any>;

  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTab($event: any) {
    $event.returnValue = 'Your data will be lost!';
  }

  async removeIndexedDb() {
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db: any) => { window.indexedDB.deleteDatabase(db.name) })
    await this.db.openDb();
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.isOffroadTourFlow = this._actRoute.snapshot.params['trackType'] === 'offroad-trail';
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
    if (this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid')) {
      this.routeTourId = this._actRoute.snapshot.params.guid;   
      this.locationsHttpService.getEmotionTourWithAnnotations(this.routeTourId, 'location_module')
      .pipe(finalize(() => {
        // Issue Fixed: On refresh,  when user clicks on map screen, start marker is showing up
        // This is added to avoid showing marker on click post publishing of tour
        if (this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid/edit')) {
          this.currentStep = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH;
          this.mediaCarouselService.CancelCurrentStep.next('EDIT_AFTER_PUBLISH');
        } else {
          this.currentStep = 'TOUR_PUBLISHED';
        }
        this.startAdded = true;
        this.destinationAdded = true;
      }))
      .subscribe({
       next: (data:any) => {
        let res:BusinessTourPreview = data.body
        const route_index = res.features.findIndex((feature: Feature) => ELAND_MARK.ROUTE === feature.properties.landmark_type)
        this.displayPublishedDetails = true;
        this.createPublishedTourDeatails(res, route_index);
        this.autoAnnotate = res.features[route_index].properties['auto_annotate'] ;
      }, 
      error:(error: any) => {
        // const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
      this.toastService.show(ToastType.Error, error, '1%','40%');
        setTimeout(() => {
          this.router.navigateByUrl('/locations');
          this.toastService.hide();
        }, AppConstants.MIN_TIME_TOASTER);
      }
    });
    } else {
      this.displayPublishedDetails = false;
      this.subscription.push(this.alertModalService.onAction.subscribe((data: any) => {
        if (data && data['page'] === 'cancel_current_step' && data['action'] === true && this.editedPinIndex) {
          if (this.editedPinIndex !== 'start' && this.editedPinIndex !== 'destination') { // When the edit mode is cancelled and the pin edited is not start or destination
            this.cancelWayPointEditStep();
          } else if(this.editedPinIndex === 'start') {  // When the edited pin is start
            let pinToBeDeleted = this.editedPinIndex;
            this.clearWaypoints(pinToBeDeleted);
            this.startMarker = L.marker(this.storedEditCoordinates, {
              draggable: false,
              icon: this.startIcon              
            });
            this.startMarker.name = 'start'
            // Important: This code can be moved to a common function and updated in other 2 places.
            // Note: Each time we are changing properties of any markers, dragend event should be reinitialized on it
            this.startMarkerDragEventHandler();
            this.opacityMarkers.push(this.startMarker);
            this.mapInstance.addLayer(this.startMarker);
          } else {                                    // When the edited pin is destination
            let pinToBeDeleted = this.editedPinIndex;
            this.clearWaypoints(pinToBeDeleted);
            this.destinationMarker = L.marker(this.storedEditCoordinates, {
              draggable: false,
              icon: this.destinationIcon            
            });
            this.destinationMarker.name = 'destination'
            this.destinationMarkerDragEventHandler();
            this.opacityMarkers.push(this.destinationMarker);
            this.mapInstance.addLayer(this.destinationMarker);
          }
          this.resetEditMode();
          this.drawTourPolyline();
          this.removeOpacity();
        }
      }));

      this.subscription.push(this.mediaCarouselService.startLocationButtonClicked.subscribe((data: boolean) => {
        if (data && !this.isOffroadTourFlow) {
          this.startLocationButtonClicked = data;
        }
      }));

      this.subscription.push(this.mediaCarouselService.showPlusIconOnMapHover.subscribe((data: boolean) => {
        if (data && !this.isOffroadTourFlow) {
          this.showAddIconMapHover = data && !this.displayPublishedDetails;
          this.dropPinCanceledIfSearchSelected = false;
        }
      }));

      this.subscription.push(
        this.mediaCarouselService.deleteAnnotationRedrawMap.subscribe((index: number) => {
          if (index >= 0) {
            index = index + 1;
            this.mapInstance.removeLayer(this.waypointArray[index - 1]);
            this.waypointsList.splice(index, 1);
            this.waypointArray.splice(index - 1, 1);
            // When insider spot is deleted, all arrays should be updated. This will remove the
            // deleted icon from opacity list.
            const i = this.opacityMarkers.findIndex((i: any) => i.name === 'waypoint' + index)
            this.opacityMarkers.splice(i, 1)
            if (this.waypointArray.length >= index) {
              this.waypointArray.map((data: any, i: number) => {
                if (i + 1 >= index) {
                  let updatedPin = Number(data?.options?.icon?.options?.html) - 1;
                  let icon2 = L.divIcon({
                    className: "dealer-number-icon",
                    iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
                    iconAnchor: [10, 35],
                    html: updatedPin.toString(),
                  });
                  data.setIcon(icon2);
                  // Post deleting rename the icon correcly
                  data.name = 'waypoint' + updatedPin.toString()
                }
              });
            }
            this.clearPolylineFromMap();
            this.drawTourPolyline();
            this.waypointslatlngs.splice(index - 1, 1);
            this.deleteHighlightflag = true;
            this.resetEditMode();
          }
        })
      );
    }

    this.tourId = this.randomNumberService.randomNumber().toString();
    this.mediaCarouselService.CancelCurrentStep.subscribe((data: string) => {
      if (data) {
        this.currentStep = data;
        // Once we cancel from edit mode, we need to reset the edit mode for edit after publish
        if (data === 'EDIT_AFTER_PUBLISH') {
          if (this.editActive && this.editHighlightPin)
          this.resetEditMode();
        }
      }
      if(data === 'EDIT_AFTER_PUBLISH_CANCELLED'){
        this.plotInitialTourOnMap();
      }
    });
    this.stateService.getSendTourToFleet().subscribe((response: boolean) => {
      this.displayFleetManagement = response;
    })
  }

  public cancelWayPointEditStep(): void {
    if (!this.storedEditCoordinates) {
      return;
    }
    let pinToBeDeleted = 'waypoint' + this.editedPinIndex;
    this.clearWaypoints(pinToBeDeleted);
    this.mapPinIndex = this.editedPinIndex.toString()
    this.waypointIcon = L.divIcon({
      className: "dealer-number-icon",
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: [10, 35],
      html: this.mapPinIndex,
    });
    let waypointsCoords = {
      lat: this.storedEditCoordinates.lat,
      lon: this.storedEditCoordinates.lng,
    };
    this.waypointslatlngs.splice((this.editedPinIndex - 1), 1, waypointsCoords)
    this.waymarker = L.marker(this.storedEditCoordinates, {
      draggable: false,
      icon: this.waypointIcon,
    })
      .bindPopup(this.popupValues[0])
      .openPopup();
    if (this.editedPinIndex) {
      this.waymarker["name"] = "waypoint" + this.editedPinIndex;
    }
    this.wayPointDragEventHandler();
    this.opacityMarkers.push(this.waymarker);
    this.mapInstance.addLayer(this.waymarker);
    this.waypointArray.splice((this.editedPinIndex - 1), 1, this.waymarker)
  }

  public plotInitialTourOnMap(): void {
    if (this.routeTourId && this.currentStep === 'EDIT_AFTER_PUBLISH_CANCELLED') { // fix for guid going null on cancelling from edit stage
    this.locationsHttpService.getEmotionTourWithAnnotations(this.routeTourId, 'location_module')
      .pipe(finalize(() => {
        this.currentStep = 'TOUR_PUBLISHED';
        this.startAdded = true;
        this.destinationAdded = true;
      }))
      .subscribe({
       next: (res:BusinessTourPreview) => {
        this.displayPublishedDetails = true;
        const route_index = res.features.findIndex((feature: Feature) => ELAND_MARK.ROUTE === feature.properties.landmark_type)
        this.createPublishedTourDeatails(res, route_index);
      }, 
        error: (error: any) => {
          const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
          this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
          setTimeout(() => {
            this.router.navigateByUrl('/locations');
            this.toastService.hide();
          }, AppConstants.MIN_TIME_TOASTER);
        }
    });
  }
  }

  /**
   * @description Called when we come out edit mode and discard insider point
   */
  public resetEditMode(): void {
    if (!this.editAfterPublishActive) {
      this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
    }
    this.editActive = false;
    this.disableEdit = true;
    this.stateService.setEditActiveStatus(false)
    this.editHighlightPin = false;
    this.editedHighlightInfo = null;
    this.editedPinIndex = null;
  }

  ngAfterViewInit() {
    this.setUpLeaflet();
    this.cdr.detectChanges();
  }

  public recentreToUserLoc(coordinates: any, source: string, currentStep: any): void {
    let coords :any = {
      lat: 0,
      long: 0,
    };
    if(!coordinates){
      let initialCoords :any = {
        lat: this.initialCoordinates.coords.latitude,
        lon: this.initialCoordinates.coords.longitude,
      };
      this.mapInstance.setView(initialCoords, LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC, { animate: false });

    } 
    switch(currentStep){

      case ETOUR_CREATION_STEPS.THIRD:
        if(this.startAdded && this.destinationCoordinates==null){
        coords={
          lat: this.startCoordinates.lat,
          long: this.startCoordinates.lon,
        }
        
      }
      else{
        coords={
          lat: this.destinationCoordinates.lat,
          long: this.destinationCoordinates.lon,
        }
      }
      this.zoomIntoMarker(coords.lat, coords.long);
      break;
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDITING_START:
        coords={
          lat: this.startCoordinates.lat,
          long: this.startCoordinates.lon,
        }
      this.zoomIntoMarker(coords.lat, coords.long);
      break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        coords={
          lat: this.waypointslatlngs[this.waypointslatlngs.length-1].lat,
          long: this.waypointslatlngs[this.waypointslatlngs.length-1].lon,
        }
        this.zoomIntoMarker(coords.lat, coords.long); 
      break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
        this.zoomBasedOnType('polyline');
      break; 
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION: 
        coords={
          lat: this.destinationCoordinates.lat,
          long: this.destinationCoordinates.lon,
        }
        this.zoomIntoMarker(coords.lat, coords.long);
      break;
      default: {
        const zoom = ELOCATION_SCREENS.LANDING_PAGE === source ? this.zoom: 3; // Zoom Level on recenter: TBD
    if (coordinates && currentStep=== ETOUR_CREATION_STEPS.SECOND) {
      this.mapInstance.setView(coordinates, zoom, { animate: false });
    }
      }
   
    }
  }

  /**
   * @description Called when we click on recenter icon and to recenter the map when a tour is plotted
   */
  public recenterToTourPublished(): void {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const emToPx = (em: number) => em * fontSize;
    const topLeftPaddingEm: number[] = [parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--left-floating-panel-width')), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--header-height')))];
    const bottomRightPaddingEm: number[] = [parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large'))), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--carousel-reg-height'))) - parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large')))]
    const topLeftPaddingPx = topLeftPaddingEm.map(emToPx);
    const bottomRightPaddingPx = bottomRightPaddingEm.map(emToPx);
    this.polyline.type = "emotionTourPolyline";
    this.mapInstance.addLayer(this.polyline);
    this.mapInstance.flyToBounds(this.polyline.getBounds(), {
      // first value is horizontal and second value is vertical
      paddingTopLeft: topLeftPaddingPx,
      paddingBottomRight: bottomRightPaddingPx,
      zoom: LOCATIONS_CONSTANTS.CIRCUIT_PUBLISHED_ZOOM,
      duration: 2.5
    });
  }

  /**
   * @description Called when we click on map layer - Switches the map view from normal to satellite and vice-versa
   */
  public switchMapView(): void {
    if (this._createMapService.selectedLayer === 'normal') {
      this.mapInstance.addLayer(this._createMapService.satelliteView);
      this.mapInstance.removeLayer(this._createMapService.normalView);
      this._createMapService.selectedLayer = this.selectedLayer = 'satellite';
    } else if (this._createMapService.selectedLayer === 'satellite') {
      this.mapInstance.addLayer(this._createMapService.normalView);
      this.mapInstance.removeLayer(this._createMapService.satelliteView);
      this._createMapService.selectedLayer = this.selectedLayer = 'normal';
    }
    this._storeManagerService.setMapThemeToStore(this.selectedLayer);
  }

  public loadMapForLocation(): void {
    this.mapInstance = L.map(this.el.nativeElement, this._createMapService.loadMap(this.apiKey, this.routeTourId ? ELOCATION_SCREENS.EMOTION_TOUR_INFO: ELOCATION_SCREENS.LANDING_PAGE));
    this.zoomControl = L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(this.mapInstance);
    this.layerControl = L.control.layers(this._createMapService.getBaseMapDetails()).addTo(this.mapInstance);
  }

  /**
   * @description Called when we click on the map to add a pin
   */
  public setUpLeaflet(): void {
    this.loadMapForLocation();
      // Do not execute this code once the tour is published
      // This code is added to add start/destination or edit start/destination address
    this.mapInstance.on("click", (e: any) => {
      const check = (): boolean => {
        return (((this.currentStep === ETOUR_CREATION_STEPS.SECOND || this.currentStep === ETOUR_CREATION_STEPS.THIRD) && !this.startAdded) 
        || (this.currentStep === ETOUR_CREATION_STEPS.START_ADDED) || (this.currentStep === ETOUR_CREATION_STEPS.SECOND) || ((this.editActive && this.editStartPin) && (this.currentStep != ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_DESTINATION && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_START)) && !this.emotionTourTrackencodedGpsPoints)
      }
      if(this.dropPinCanceledIfSearchSelected || (this.isOffroadTourFlow && this.currentStep === ETOUR_CREATION_STEPS.SECOND)) {
        e?.preventDefault();
      }
      this.showAddIconMapHover = false;
      if (check()) {
        if (!this.startLocationButtonClicked || (this.editStartPin && !this.editDestinationPin)) {
          this.startMarksDisabled = false;
          this.getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);
          if (this.currentStep === ETOUR_CREATION_STEPS.EDITING_START || this.currentStep === ETOUR_CREATION_STEPS.EDITING_DESTINATION) {
            this.currentStep = this.currentStep === ETOUR_CREATION_STEPS.EDITING_START ? ETOUR_CREATION_STEPS.EDIT_START : ETOUR_CREATION_STEPS.EDIT_DESTINATION;
          } else {
            this.currentStep = ETOUR_CREATION_STEPS.THIRD;
          }
          this.startIcon = L.icon({
            iconUrl: "assets/locations/tour-pins/start-pin.svg",
            iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
            iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON
          });
          this.startCoordinates = {
            lat: e.latlng.lat,
            lon: e.latlng.lng,
          };

          this.startMarker = L.marker(e.latlng, {
            draggable: true,
            icon: this.startIcon
          });
          this.startMarker.name = 'start'
          if (!this.editActive) {
            this.opacityMarkers.push(this.startMarker);
          }
          this.zoomIntoMarker(e.latlng.lat, e.latlng.lng);
          this.startMarkerDragEventHandler();
          this.startAdded = true;
          this.mapInstance.addLayer(this.startMarker);
          this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
        } else if ((this.startLocationButtonClicked) || this.editDestinationPin) { // editDestinationPin is active only in the destination pin editing scenario
          this.destinationMarksDisabled = false;
          this.getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);
          if (this.currentStep === ETOUR_CREATION_STEPS.EDITING_START || this.currentStep === ETOUR_CREATION_STEPS.EDITING_DESTINATION) {
            this.currentStep = this.currentStep === ETOUR_CREATION_STEPS.EDITING_START ? ETOUR_CREATION_STEPS.EDIT_START : ETOUR_CREATION_STEPS.EDIT_DESTINATION;
          } else {
            this.currentStep = ETOUR_CREATION_STEPS.THIRD;
          }
          this.destinationIcon = L.icon({
            iconUrl: "assets/locations/tour-pins/destination-pin.svg",
            iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
            iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON
          });
          this.destinationCoordinates = {
            lat: e.latlng.lat,
            lon: e.latlng.lng,
          };
          this.destinationMarker = L.marker(e.latlng, {
            draggable: true,
            icon: this.destinationIcon,
          });
          this.destinationMarker.name = 'destination'
          if (!this.editActive) {
            this.opacityMarkers.push(this.destinationMarker);
          }
          this.zoomIntoMarker(e.latlng.lat, e.latlng.lng);
          this.destinationMarkerDragEventHandler();
          this.destinationAdded = true;
          this.mapInstance.addLayer(this.destinationMarker);
          this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
        }
      } else if ((this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT || (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT &&
         this.editActive && !this.editStartPin) || this.currentStep === ETOUR_CREATION_STEPS.SECOND || 
         (this.isRoundTrip && this.currentStep === ETOUR_CREATION_STEPS.START_ADDED) || (this.isRoundTrip && this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED)) && !this.waypointAdded) {
        this.waymarkerMarksDisabled = false;
        this.clearPolylineFromMap();
        this.getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);
        this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS;
        // Fix: the map pin index cannot have start or destination as index
        if(this.editedPinIndex && this.editedPinIndex !== 'start' && this.editedPinIndex !== 'destination'){
          this.mapPinIndex = this.editedPinIndex.toString()
        }else{
          this.mapPinIndex = (this.waypointslatlngs.length + 1).toString()
        }
        this.waypointIcon = L.divIcon({
          className: "dealer-number-icon",
          iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
          iconAnchor: [10, 35],
          html: this.mapPinIndex,
        });
        this.searchDealerAnnotation(10, 0, "", [
          e.latlng.lat,
          e.latlng.lng,
        ]);
        let waypointsCoords = {
          lat: e.latlng.lat,
          lon: e.latlng.lng,
        };
        if(this.editedPinIndex){
          this.waypointslatlngs.splice((this.editedPinIndex - 1),1,waypointsCoords)
        }else{
          this.waypointslatlngs.push(waypointsCoords);
        } 
        this.waymarker = L.marker(e.latlng, {
          draggable: true,
          icon: this.waypointIcon,
        })
          .bindPopup(this.popupValues[0])
          .openPopup();
          if(this.editedPinIndex){
            this.waymarker["name"] = "waypoint" + this.editedPinIndex;
          }else{
            this.waymarker["name"] = "waypoint" + this.waypointslatlngs.length;
          }        
          this.opacityMarkers.push(this.waymarker);
        
        this.zoomIntoMarker(e.latlng.lat, e.latlng.lng);
        this.wayPointDragEventHandler();
        this.waypointAdded = true;
        this.mapInstance.addLayer(this.waymarker);
        if(this.editedPinIndex){
          this.waypointArray.splice((this.editedPinIndex - 1),1,this.waymarker)
        }else{
          this.waypointArray.push(this.waymarker);
        }
        this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
        
      }
    });
  }

  public destinationMarkerDragEventHandler(): void {
    this.destinationMarker.on("dragend", (event: any) => {
      const marker = event.target;
      const position = marker.getLatLng();
      this.destinationCoordinates = {
        lat: position.lat,
        lon: position.lng,
      };
      marker.setLatLng(new L.LatLng(position.lat, position.lng), {
        draggable: "true",
      });
      this.mapInstance.panTo(new L.LatLng(position.lat, position.lng));
      this.getAddressFromCoordinates(position.lat, position.lng);
      this.zoomIntoMarker(position.lat, position.lng);
    })
  }

  public startMarkerDragEventHandler(): void {
    this.startMarker.on("dragend", (event: any) => {
      const marker = event.target;
      const position = marker.getLatLng();
      this.startCoordinates = {
        lat: position.lat,
        lon: position.lng,
      };
      marker.setLatLng(new L.LatLng(position.lat, position.lng), {
        draggable: "true",
      });
      this.mapInstance.panTo(new L.LatLng(position.lat, position.lng));
      this.getAddressFromCoordinates(position.lat, position.lng);
      this.zoomIntoMarker(position.lat, position.lng);
    });
  }

  public wayPointDragEventHandler(): void {
    this.waymarker.on("dragend", (event: any) => {
      let marker = event.target;
      let position = marker.getLatLng();      
      let waypointsCoordinates = {
        lat: position.lat,
        lon: position.lng,
      }
      if(this.editedPinIndex){
        this.waypointslatlngs.splice((this.editedPinIndex - 1),1,waypointsCoordinates)
      }else{
        this.waypointslatlngs.pop();
        this.waypointslatlngs.push(waypointsCoordinates);
      }
      this.searchDealerAnnotation(10, 0, '', [position.lat, position.lng]);
      marker.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });
      this.mapInstance.panTo(new L.LatLng(position.lat, position.lng))
      this.getAddressFromCoordinates(position.lat, position.lng);      
    });
  }

   /**
   * @description This is used to populate the annotation ids once tour is created
   */
   public setAnnotationIdsArray(){
    const updateAnnotationArray = (): string[] => {
      if (this.emotionTourTrackencodedGpsPoints) {
        const updatedIdList = this.emotionTourTrackencodedGpsPoints.features
        .map((feature: Feature) => feature.id)
        .filter((id: string) => !id.includes('route'));
        return updatedIdList;
      }
      return [];
    }
    if((this.createdTourGuid || this.editAfterPublishActive) && !this.autoAnnotate) {
      const existingIds: string[] = updateAnnotationArray()
      this.createdAnnotationId = [...new Set(existingIds), ...new Set(this.createdAnnotationId)];
      this.createdAnnotationId = this.createdAnnotationId.filter((data)=>{
        return !this.removedTours.includes(data);
      })
      let filteredIds:any = []
      this.createdAnnotationId.forEach((ids:any)=>{
        filteredIds.push(this.checkWhetherAMG(ids))
      })
      this.createdAnnotationId = filteredIds? filteredIds : []
      this.removedTours = []
    }
  }

  checkWhetherAMG(annotationId: any) {
    let firstCharacter = annotationId.slice(1, 5)
    if (firstCharacter === '-en-') {
      return annotationId.slice(5)
    } else {
      return annotationId
    }
  }

  /**
   * @description called for pin drops/selecting from recent serch
   * @param lat 
   * @param long 
   */
  zoomIntoMarker(lat: number, long:number) {
    this.mapInstance.flyTo([lat, long], LOCATIONS_CONSTANTS.MAX_STATE_ZOOM_FOR_MAP_PINS, { duration: 1 });
  }

  /**
   * @description This is called when we select a location from dropdown
   * @param bound 
   */
  zoomIntoBound(bound: any) {
    const southWest = L.latLng(bound.south, bound.west), 
    northEast = L.latLng(bound.north, bound.east);
    const bounds = L.latLngBounds(southWest, northEast);
    this.mapInstance.flyToBounds(bounds);
  }

  public zoomBasedOnType(type: string): void {
    // Note: These are not any random values.
    // These values are in px and are fetched from variables.scss - we have converted em to px
    if (type === "markerlist") {
      this.mapInstance.flyToBounds(this.waypointsList.getBounds(), {
        paddingTopLeft: LOCATIONS_CONSTANTS.DEVICE_TOP_LEFT_PADDING,
        paddingBottomRight: LOCATIONS_CONSTANTS.DEVICE_BOTTOM_RIGHT_PADDING
      });
    } else if (type === "polyline") {
      // We have used accuarute values for padding here. These are width and height of the panels
      this.mapInstance.flyToBounds(this.waypolyline.getBounds(), {
        paddingTopLeft: LOCATIONS_CONSTANTS.DEVICE_TOP_LEFT_PADDING,
        paddingBottomRight: LOCATIONS_CONSTANTS.DEVICE_BOTTOM_RIGHT_PADDING,
        maxZoom: LOCATIONS_CONSTANTS.MAX_STREET_ZOOM_FOR_MAP_PINS
      });
    }
  }

  getAddressFromCoordinates(lat: number, lng: number) {
    this.disableUpdateBtn = true;
    this.locationsHttpService
      .getAddressFromCoordinates(lat, lng)
      .then((res: any) => {
        this.locationOnMap = {
          name: res.items[0].address?.label ?? res.items[0].title,
          title: res.items[0].title,
          position: res.items[0].position
        } 
        if (!(this.mediaCarouselService.recentSearchHistory.some((search: RecentSearch) => search.title === this.locationOnMap.title && 
        search.position.lat === this.locationOnMap.position.lat && search.position.lng === this.locationOnMap.position.lng))) {
          this.mediaCarouselService.recentSearchHistory = this.locationOnMap;
        }
        this.disableUpdateBtn = false;
      });
  }

  /**
   * @description called when we select location from search dropdown
   * @param event 
   */
  getSelectedLocationName(event: AutoSuggestResponse | any) {
    this.showAddIconMapHover = false;
    if (((this.currentStep === ETOUR_CREATION_STEPS.SECOND || this.currentStep === ETOUR_CREATION_STEPS.THIRD) && !this.startAdded) 
    || (this.currentStep === ETOUR_CREATION_STEPS.START_ADDED) || (this.currentStep === ETOUR_CREATION_STEPS.SECOND)||
   (this.editActive && this.editStartPin)) {
      if(!this.startLocationButtonClicked|| (this.editStartPin && !this.editDestinationPin)) {
        this.startMarksDisabled = false;
        this.dropPinCanceledIfSearchSelected = true;
        if (this.currentStep === ETOUR_CREATION_STEPS.EDIT_START) {
          this.mapInstance.removeLayer(this.startMarker)
        }
        this.startIcon = L.icon({
          iconUrl: "assets/locations/tour-pins/start-pin.svg",
          iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
          iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON
        });        
        this.zoomIntoMarker(event.position.lat, event.position.lng ? event.position.lng: 0);
        this.startCoordinates = {
          lat: event.position.lat,
          lon: event.position.lng,
        };
        this.startMarker = L.marker(<any>event.position, {
          draggable: true,
          icon: this.startIcon,
        });
        this.startMarker.name = 'start'
        if (!this.editActive) {
          this.opacityMarkers.push(this.startMarker);
        }
        this.startMarkerDragEventHandler();
        this.startAdded = true;
        if (this.editStartPin) {
          this.currentStep = ETOUR_CREATION_STEPS.EDIT_START;
        } else {
          this.currentStep = ETOUR_CREATION_STEPS.THIRD;
        }
        this.mapInstance.addLayer(this.startMarker);
      } else if((this.startLocationButtonClicked)|| this.editDestinationPin) {
        this.destinationMarksDisabled = false;
        this.dropPinCanceledIfSearchSelected = true;
        if (this.currentStep === ETOUR_CREATION_STEPS.EDIT_DESTINATION) {
          this.mapInstance.removeLayer(this.destinationMarker)
        }
        this.destinationIcon = L.icon({
          iconUrl: "assets/locations/tour-pins/destination-pin.svg",
          iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
          iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON
        });
        this.currentStep = this.editDestinationPin ? ETOUR_CREATION_STEPS.EDIT_DESTINATION :  ETOUR_CREATION_STEPS.THIRD;
          this.zoomIntoMarker(event.position.lat, event.position.lng ? event.position.lng: 0);
        this.destinationCoordinates = {
          lat: event.position.lat,
          lon: event.position.lng,
        };
        this.destinationMarker = L.marker(<any>event.position, {
          draggable: true,
          icon: this.destinationIcon,
        });
        this.destinationMarker.name = 'destination'
        if (!this.editActive) {
          this.opacityMarkers.push(this.destinationMarker);
        }
        this.destinationMarkerDragEventHandler();
        this.destinationAdded = true;
        if (this.editDestinationPin) {
          this.currentStep = ETOUR_CREATION_STEPS.EDIT_DESTINATION;
        } else {
          this.currentStep = ETOUR_CREATION_STEPS.THIRD;
        }
        this.mapInstance.addLayer(this.destinationMarker);
      } 
    } else if ((this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT || (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT && this.editActive) || this.currentStep === ETOUR_CREATION_STEPS.SECOND || (this.currentStep === ETOUR_CREATION_STEPS.START_ADDED) || (this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED)) && !this.waypointAdded) {
      this.waymarkerMarksDisabled = false;
      this.clearPolylineFromMap();
      if(this.editedPinIndex){
        this.mapPinIndex = this.editedPinIndex.toString()
      }else{
        this.mapPinIndex = (this.waypointslatlngs.length + 1).toString()
      }
      this.waypointIcon = L.divIcon({
        className: "dealer-number-icon",
        iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
        iconAnchor: [10, 35],
        html: this.mapPinIndex,
      });
      this.searchDealerAnnotation(10, 0, "", [
        event.position.lat,
        event.position.lng,
      ]);
      let waypointsCoords = {
        lat: event.position.lat,
        lon: event.position.lng,
      };
      if(this.editedPinIndex){
        this.waypointslatlngs.splice((this.editedPinIndex - 1),1,waypointsCoords)
      }else{
        this.waypointslatlngs.push(waypointsCoords);
      }
      
  
        this.zoomIntoMarker(event.position.lat, event.position.lng ? event.position.lng: 0);
      
      this.waymarker = L.marker(<any>event.position, { draggable: true, icon: this.waypointIcon })
      if(this.editedPinIndex){
        this.waymarker["name"] = "waypoint" + this.editedPinIndex;
        this.waymarker['id'] = this.editedPinIndex;
      }else{
        this.waymarker["name"] = "waypoint" + this.waypointslatlngs.length;
        this.waymarker['id'] = (this.waypointslatlngs.length + 1);
      } 
        this.opacityMarkers.push(this.waymarker);
        if (this.editAfterPublishActive || ((this.emotionTourTrackencodedGpsPoints || this.createdTourGuid) && this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT)) { 
        this.locationOnMap = {
          name: event.title,
          title: event.title,
          position: event.position
        } 
        // To make sure the add details page opens automatically in edit after publish flow
        if (!(this.mediaCarouselService.recentSearchHistory.some((search: RecentSearch) => search.title === this.locationOnMap.title &&
          search.position.lat === this.locationOnMap.position.lat && search.position.lng === this.locationOnMap.position.lng))) {
          this.mediaCarouselService.recentSearchHistory = this.locationOnMap;
        }
        this.disableUpdateBtn = false;
      }
      this.waymarker.on('dragend', (dragendevent: any) => {
        let marker = dragendevent.target;
        let position = marker.getLatLng();
        
          const waypointsCoordinates = {
            lat: position.lat,
            lon: position.lng,
          }
          if(this.editedPinIndex){
            this.waypointslatlngs.splice((this.editedPinIndex - 1),1,waypointsCoordinates)
          }else{
            this.waypointslatlngs.pop();
            this.waypointslatlngs.push(waypointsCoordinates);
          }
        this.searchDealerAnnotation(10, 0, '', [waypointsCoords.lat, waypointsCoords.lon ? waypointsCoords.lon: 0]);

        marker.setLatLng(new L.LatLng(position.lat, position.lng), {
          draggable: "true",
        });
        this.mapInstance.panTo(new L.LatLng(position.lat, position.lng));
        this.getAddressFromCoordinates(position.lat, position.lng);
        
      });
      this.mapInstance.addLayer(this.waymarker);
      if(this.editedPinIndex){
        this.waypointArray.splice((this.editedPinIndex - 1),1,this.waymarker)
      }else{
        this.waypointArray.push(this.waymarker);
      }
      this.waypointAdded = true;
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS;
      if (this.emotionTourTrackencodedGpsPoints) {
        this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
      }
    } else {
      this.readjustCurrentMarker(event);
    }
  }

  public readjustCurrentMarker(event: any): void {
    if (event && (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT || this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS)) {
      const pinToRemove = 'waypoint' + this.mapPinIndex;
      this.clearWaypoints(pinToRemove);
      this.waypointslatlngs.pop();
      this.waypointArray.pop();
      this.waypointAdded = false;
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT;
    } else if (this.currentStep === ETOUR_CREATION_STEPS.THIRD && this.startAdded && !this.destinationAdded) {
      // Do something
      this.clearMap();
      this.startAdded = false;
      this.currentStep = ETOUR_CREATION_STEPS.SECOND;
    } else if (this.currentStep === ETOUR_CREATION_STEPS.THIRD && this.startAdded && this.destinationAdded) {
      // Do somthing
      this.mapInstance.removeLayer(this.destinationMarker);
      this.destinationAdded = false;
      this.currentStep = ETOUR_CREATION_STEPS.SECOND;
    }
    this.getSelectedLocationName(event);
  }

  /**
   * @description called post adding a point - emitted from modal
   * @param event - startAdded, destinationAdded, waypointAdded
   */
  sendHighlightStep(event: string) {
    this.currentStep = event;
    if(this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED && this.mediaCarouselService.isRoundTripSelected) { this.startRoundTripCreateProcess(); }
    if(this.currentStep !== ETOUR_CREATION_STEPS.WAY_POINT_ADDED && this.currentStep !== ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS && this.currentStep !== ETOUR_CREATION_STEPS.DESTINATION_CANCEL && this.currentStep !== ETOUR_CREATION_STEPS.DESTINATION_ADDED) {
      this.setOpacity();
    } else {
      this.removeOpacity();
      this.makeMarkersNonDraggable()
    }
  }

  public startRoundTripCreateProcess(): void {
    this.isRoundTrip = this.mediaCarouselService.isRoundTripSelected;
    this.dropPinCanceledIfSearchSelected = this.destinationAdded = true;
    this.destinationPointData = this.startPointData;
    if (!this.editActive) {
      this.mediaCarouselService.highlightList = [this.destinationPointData.name + '-' + ELAND_MARK.DESTINATION];
    }
    this.makeMarkersNonDraggable();
    this.showAddIconMapHover = false;
    this.mapInstance.removeLayer(this.startMarker);
    if (this.destinationMarker) { this.mapInstance.removeLayer(this.destinationMarker); }
    this.opacityMarkers.pop();
    this.startIcon = L.icon({
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
      iconUrl: "assets/locations/tour-pins/round-trip-pin.svg",
    });
    const latlng = L.latLng(this.startCoordinates?.lat, this.startCoordinates?.lon)
    this.startMarker = L.marker(latlng, {
      draggable: false,
      icon: this.startIcon
    });
    this.startMarker.name = 'start'
    // This is done so that round trip marker is moveable while editing of the card during creation process.
    // !!Important-Pending: To make this as a common function and call in all 3 places
    this.startMarkerDragEventHandler();
    this.opacityMarkers.push(this.startMarker);
    this.mapInstance.addLayer(this.startMarker);
  }

  setOpacity() {
    if(this.opacityMarkers.length > 0) {
      this.opacityMarkers.forEach(function(marker) {
        marker.setOpacity(0.6);
      });
    }
  }

  removeOpacity() {
    if(this.opacityMarkers.length > 0) {
      this.opacityMarkers.forEach(function(marker) {
        marker.setOpacity(1);
      });
    }
  }

  /**
   * @description return the cooredinates of pin currently placed on map
   * @param size 
   * @param pageNo 
   * @param name 
   * @param query 
   */
  async searchDealerAnnotation(size: any, pageNo: any, name: any, query: any) {
    this.disableUpdateBtn = true;
    const response: any = this.locationsHttpService
      .dealerAnnotationSearch(size, pageNo, name, query)
    if (response) {
      this.annotationResponse = await lastValueFrom(response);
      this.annotationResponse = this.annotationResponse.body
      console.log(this.annotationResponse)
      for (let item of this.annotationResponse.features) {
        this.popupValues = [];
        this.popupValues.push(item.properties.name);
      }
      this.disableUpdateBtn = false;
    }
    
  }

  cancelButtonClicked(event: boolean) {
    this.clickInsiderPointCancelFlag = event;
    this.showAddIconMapHover = false;
    this.clickInsiderPointCancelFlag = false;
  }

  sendSearchClosed(event: any) {
    if (event === "start") {
      this.clearMap();
      this.startAdded = false;
      this.currentStep = ETOUR_CREATION_STEPS.SECOND;
      this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
    } else if (event == "destination") {
      this.mapInstance.removeLayer(this.destinationMarker);
      this.destinationAdded = false;
      this.currentStep = ETOUR_CREATION_STEPS.START_ADDED;
      this.mediaCarouselService.CancelCurrentStep.next(this.currentStep);
    } else {
      // Fix map pins were not getting removed correctly if we click on x or cancel.
      // Expected: waypoint with highlightnumber
      const pinToRemove = 'waypoint' + this.mapPinIndex;
      this.clearWaypoints(pinToRemove);
      this.waypointslatlngs.pop();
      // Fix: Map pin numbering issue when we delete. 
      // This array is populated each time we drop and pin or chose from drop down but was not cleared 
      this.waypointArray.pop();
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT;
      this.waypointAdded = false;
      this.drawTourPolyline();
    }
  }

  clearMapAndAddEditedPoint(event:any){
    if (this.currentStep !== ETOUR_CREATION_STEPS.EDIT_DESTINATION && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_START) {
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT
    } else {
      this.currentStep = event !== 'destination' ? ETOUR_CREATION_STEPS.EDITING_START: ETOUR_CREATION_STEPS.EDITING_DESTINATION;
    }
    if(event !== 'start' && event !== 'destination'){
      let pinToBeDeleted = 'waypoint'+event;
      this.clearWaypoints(pinToBeDeleted);
      this.deleteEditedPin = true;
    } else{
      this.clearWaypoints(event);
    }    
  }

  /**
   * @description called when we click Confirm from Confirm Delete Modal
   * @param event 
   */
  cancelCurrentWaypoint(event: string) {
    if (event === "start") {
      this.clearMap();
      this.startAdded = false;
    } else if(event === "destination") {
      this.mapInstance.removeLayer(this.destinationMarker);
      this.destinationAdded = false;  
    } else if(typeof(event) == 'number') {
      this.clearWaypoints(event);
      this.waypointsList.splice(event, 1);
      this.drawTourPolyline();
      this.waypointAdded = false;
    } else {
      const pinToRemove = 'waypoint' + this.mapPinIndex;
      this.clearWaypoints(pinToRemove);
      this.waypointslatlngs.pop();
      this.waypointArray.pop();
      this.waypointAdded = false;
    }
  }

  clearMap() {
    this.mapInstance.eachLayer((layer: any) => {
      if (!layer["_url"]) {
        this.mapInstance.removeLayer(layer);
      }
    });
  }

  makeMarkersNonDraggable() {
    if (this.startMarker && !this.startMarksDisabled) {
      this.startMarker.dragging.disable();
      this.startMarksDisabled = true;
    } 
    if(this.destinationMarker && !this.destinationMarksDisabled) {
      this.destinationMarker.dragging.disable();
      this.destinationMarksDisabled = true;
    } 
    if(this.waymarker && !this.waymarkerMarksDisabled) {
      this.waymarker.dragging.disable();
      this.waymarkerMarksDisabled = true;
    }
  }

  /**
   * @description called when we click on generate tour
   * @param event 
   */
  public sendTourInfo(event: {tourName: string, tourDesc: string}): void {
    this.tourName = event.tourName;
    this.tourDesc = event.tourDesc;
    if (this.waypointslatlngs.length > 0) {
      this.disableGenerateButton = false;
    }
  }

  clearPolylineFromMap() {
    if (this.createdTourGuid) {
      return;
    }
    if (this.waypolyline) {
      this.mapInstance.eachLayer((layer: any) => {
        if (layer.name === "polylineAdded") {
          this.mapInstance.removeLayer(layer);
          layer.remove();
          layer = null;
        }
      });
    }
  }

  clearWaypoints(data: any) {
    this.clearPolylineFromMap();
    this.mapInstance.eachLayer((layer: any) => {
      if (layer.name === data) {
        this.mapInstance.removeLayer(layer);
        layer.remove();
        layer = null;
      }
    });
  }

  /**
   * @description called when we have added a way point and clicked on Save button
   * @param event 
   */
  public addWaypointDetailsToArray(event: {name: string, description?: string, media_details?: any}): void {
    this.wayPointsDataObj = event;
    this.updateTourCoordinatesArray();
    this.addTourToCache();
    this.drawTourPolyline();
    this.makeMarkersNonDraggable();
    if(this.editActive){      
      if (this.isRoundTrip) {
        this.startRoundTripCreateProcess();
      }
      this.addOpacityToMarkers();
      this.editHighlightPin = false;
      this.editedHighlightInfo = null;
      this.editStartPin = false;
      this.editDestinationPin = false;
      this.editedPinIndex = null;
      this.markerToBeEdited?.dragging.disable();
    }    
  }

  /**
   * @description Called when we click on Update details from left panel; in Edit mode.
   * Initially we are removing the marker to be edited from this array while in edit mode.
   * We then have to add back the same into array
   */
  public addOpacityToMarkers(): void {
    if (this.editStartPin && !this.editDestinationPin) {
      this.opacityMarkers.push(this.startMarker);
      this.mapInstance.addLayer(this.startMarker);
    } else if (this.editDestinationPin) {
      this.opacityMarkers.push(this.destinationMarker);
      this.mapInstance.addLayer(this.destinationMarker);
    } else {
      // Marker to be edited will be pushed back
       this.opacityMarkers.push(this.markerToBeEdited);
    }
  }

  /**
   * @description called up on adding staert point's save route
   * @param event 
   */
  sendStartInfo(event: {name: string, description: string}): void {
    this.startPointData = event;
    this.mediaCarouselService.highlightList = [this.startPointData.name + '-' + ELAND_MARK.ORIGIN]    
    this.makeMarkersNonDraggable();
    this.dropPinCanceledIfSearchSelected = false;
  }

  /**
   * @description called when we select destination's save
   * @param event 
   */
  sendDestinationInfo(event: {name: string, description: string}): void {
    if(this.waypointsList?.length > 0) {
      this.clearPolylineFromMap();
    }
    this.destinationPointData = event;
    const destIndex = this.mediaCarouselService.highlightList.findIndex((dest) => dest.includes(ELAND_MARK.DESTINATION));
    if (destIndex >= 0) {
      // Each time we covert A-B to round trip to A-B, new Destination is appended, so we will remove previous and add new one??
      this.mediaCarouselService.highlightList.splice(destIndex, 1);
    }
    this.mediaCarouselService.highlightList = [this.destinationPointData.name + '-' + ELAND_MARK.DESTINATION];
    
    this.updateTourCoordinatesArray();
    this.drawTourPolyline();
    this.makeMarkersNonDraggable();
    this.editHighlightPin = false;
    this.dropPinCanceledIfSearchSelected = false;
  }

  addTourToCache() {
    let index = 0;
    if(!this.editAfterPublishActive && !this.emotionTourTrackencodedGpsPoints){
      this.encodeTour();
     }
     if (this.emotionTourTrackencodedGpsPoints) {
      index = this.emotionTourTrackencodedGpsPoints.features.findIndex((feature: Feature) => feature.properties.landmark_type === ELAND_MARK.ROUTE);
     }
     if (this.editActive && this.emotionTourTrackencodedGpsPoints && !this.emotionTourTrackencodedGpsPoints.features[index].properties.auto_annotate) {
      if (this.editedPinIndex === 'start') {
        const origin = this.emotionTourTrackencodedGpsPoints.features.find((feature: Feature) => ELAND_MARK.ORIGIN === feature.properties.landmark_type);
        this.wayPointsDataObj['annotationId'] = origin?.id;
      } else if (this.editedPinIndex === 'destination') {
        const destination = this.emotionTourTrackencodedGpsPoints.features.find((feature: Feature) => ELAND_MARK.DESTINATION === feature.properties.landmark_type);
        this.wayPointsDataObj['annotationId'] = destination?.id;
      }
    }
    if (this.editHighlightPin && (this.wayPointsDataObj.annotationId || this.locationOnMap.id)) { //Since annotation details page is accessed through create and edit flow
      this.addEditedDetails = true;
      this.editAnnotation();
    } else {
      if (this.wayPointsDataObj?.description !== '' && this.wayPointsDataObj?.media_details.length !== 0) {
        this.createAnnotation();
      } else {
        this.waypointsData = this.wayPointsDataObj;
        this.mediaCarouselService.highlightList = [this.waypointsData.name];
      }
    }    
    if (this.tourName !== "") {
      this.disableGenerateButton = false;
    }
    let tourCoordinates = {
      start: this.startCoordinates,
      end: this.destinationCoordinates,
      waypoints: this.waypointslatlngs,
      dealertourId: this.tourId,
      encodedcoordinates: this.encodedcoordinates,
      time: new Date().valueOf(),
    };
    this.db.getTour(this.tourId).then((data: any) => {
      if (data) {
        this.db.updateTour(this.tourId, tourCoordinates);
      } else {
        this.db.addTour(tourCoordinates);
      }
    });
    this.waypointAdded = false;
  }

  /**
   * @description called when we click generate tour from Modal
   * @param _event 
   */
  public fetchCategories(): void {
    let latLngData;
    this.waypointsCoordinatesList = [];
    if (this.waypointslatlngs.length > 0) {
      this.waypointslatlngs.forEach((element: any) => {
        let wayCoordinates = {
          lat: element.lat,
          lon: element.lon,
        };
        this.waypointsCoordinatesList.push(wayCoordinates);
      });
    }
    if (this.waypointsCoordinatesList.length > 0) {
      latLngData = {
        start: this.startCoordinates,
        destination: this.destinationCoordinates, // send start coordinates for rount trip
        waypoints: this.waypointsCoordinatesList,
      };
    } else {
      latLngData = {
        start: this.startCoordinates,
        destination: this.destinationCoordinates// send start coordinates for rount trip
      };
    }
    this.showCategories = true;
    this.locationsHttpService.getCategories(latLngData).subscribe({
      next: (result: any) => {
        this.modalType = "category";
        this.categories = result.body;
        this.categories?.journey_categories.forEach((element: any) => {
          element.categoryTitle = element.category;
          element.backgroundImage = "assets/locations/categories/" + element.category?.toUpperCase() + ".jpg";
        });
        this.db.getTour(this.tourId).then((data: any) => {
          if (data != undefined) {
            this.db.updateTour(this.tourId, { categories: this.categories?.journey_categories });
          }
        })
      }, error: (_error) => {
        this.showCategories = false;
        this.categories = null;
        const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(_error.error['error-code']);
        this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, AppConstants.MIN_TIME_TOASTER)
      }
    });
  }

  backClicked(event: any) {
    if (event.backSelect === true) {
      this.showCategories = false;
      this.modalType = "category";
    } else {
      this.modalType = "spinner";
      this.createTour(event.curatedTour);
    }
  }

  encodeTour() {
    let coordinates = [];
    coordinates.push([this.startCoordinates.lat, this.isRoundTrip ? this.startCoordinates.lon: this.destinationCoordinates.lon]);
    this.waypointslatlngs.forEach((data: any) => {
      coordinates.push([data.lat, data.lng]);
    });
    this.encodedcoordinates = polyline.encode(coordinates);
  }

  /**
   * @description called post selection of destination and clicking on save
   */
  updateTourCoordinatesArray() {
    this.waypointsList = [];
    this.waypointsList.push(
      L.latLng(this.startCoordinates?.lat, this.startCoordinates?.lon)
    );
    this.waypointslatlngs.forEach((item: any, _index: any) => {
      this.waypointsList.push(L.latLng(item.lat, item.lon));
    });
    if (this.isRoundTrip) {
      this.waypointsList.push(
        L.latLng(this.startCoordinates?.lat, this.startCoordinates?.lon)
      );
    } else {
      this.waypointsList.push(
        L.latLng(this.destinationCoordinates?.lat, this.destinationCoordinates?.lon)
      );
    }
  }

  drawTourPolyline() {
    if(!this.editAfterPublishActive && !this.emotionTourTrackencodedGpsPoints){// Do not plot dotted line after tour published and is being edited
      this.waypolyline = L.polyline(this.waypointsList, {
        color: "#0078d6",
        weight: 5,
        dashArray: "12, 10",
        opacity: 1,
        smoothFactor: 1,
        lineJoin: 'miter',
        lineCap: 'butt'
      });
      this.waypolyline.name = "polylineAdded";
      this.mapInstance.addLayer(this.waypolyline);
      this.zoomBasedOnType("polyline");
    }    
  }

  /**
   * @description called when a categoty is clicked
   * @param event 
   */
  getSelectedCategory(event: CategoriesResponse) {
    this.categorySelected = event;
  }

  /**
   * @description called when we click Confirm in Confirm Delete popup upon click Back
   * @param event boolean
   */
  public abortDataSendOnClick(event: boolean): void {
      this.abortDataOnCancel = event;
  }

  public handleAnnotationDetailsResponse(isEdit: boolean, annotationData: AmgAnnoDtlsResponse): void {
    let highlightList = { annotationList: [] }
    this.wayPointsDataObj.media_details = [];
    this.wayPointsDataObj.media_details = annotationData?.properties?.media;
    this.waypointsData = this.wayPointsDataObj;
    if (!isEdit) {
      this.mediaCarouselService.highlightList = [this.waypointsData.name];
      this.createdHighlightList.push(annotationData);
      this.mediaCarouselService.annotationIds = [annotationData.id];
    } else {
      this.createdHighlightList.forEach((element: any) => {
        if (element.id === annotationData.id) {
          element.properties.description = annotationData.properties.description;
          element.properties.name = annotationData.properties.name;
          if (annotationData?.properties?.media.length > 0) {
            annotationData.properties.media.forEach((img: any) => {
              element.properties.media.push(img)
            });
          }
        }
      });
    }
    highlightList = {
      annotationList: this.createdHighlightList
    }
    this.db.updateTour(this.tourId, highlightList);
    this.editActive = false;
    // Reset all edit flags after adding annotations
    this.editHighlightPin = false;
    this.wayPointsDataObj = null
    this.disableEdit = true;
    this.stateService.clearMediaValue();
    this.mediaCarouselService.resetMediaWithCopyRightData();
    while(this._circuitService.getHighlightMediaValue()?.length > 0) {
      this._circuitService.getHighlightMediaValue().pop();
    }
    this.stateService.setEditActiveStatus(false);
  }

  /**
   * @description Called while creating an AMG annotation. There are 2 service calls - save and get
   */
  public createAnnotation(): void {
    if (this.annotationResponse && !this.abortDataOnCancel) {
      this.annotationResponse.features[0].properties.name = this.wayPointsDataObj?.title ?? this.wayPointsDataObj?.name;
      this.annotationResponse.features[0].properties.description = this.wayPointsDataObj?.description;
      if (this.editActive) {
        this.editAmgAnnotationLoader = true
      } else {
        this.showLoaderUntillGeoJsonCompletes = true;
      }
      this.locationsHttpService.saveAnnotationWithMedia(this.annotationResponse, this.wayPointsDataObj?.media_details)
        .subscribe(
          {
            next: (data) => {
              if (data?.body.message && data?.body.id) {
                this.wayPointsDataObj.id = data?.body.id;
                this.locationsHttpService.getAnnotationDetails(data.body.id)
                
                  // .pipe(finalize(() => {
                  //  
                  // }))
                  .subscribe((data: any) => {
                    this.editAmgAnnotationLoader = this.showLoaderUntillGeoJsonCompletes = false;
                    let annotationData:AmgAnnoDtlsResponse = data.body
                    if (annotationData) {
                      this.handleAnnotationDetailsResponse(false, annotationData);
                  }
                    this.createdAnnotationId.push(annotationData.id)
                    this.stateService.clearMediaValue();
                  });
                
                this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.INSIDER_SPOT_CREATE_SUCCESS_MSG, "1%", "40%");
                setTimeout(() => {
                  this.toastService.hide();
                }, AppConstants.MIN_TIME_TOASTER);
              }
            }, error: (err) => {
              this.handleAmgAnnotationError(err, false);
            },
          }
        );
    } else {
      this.abortDataOnCancel = false;
    }
  }

  /**
   * @description Called while editing an AMG annotation
   */
  public editAnnotation(): void {
    let formData = {
      "annotationId": this.wayPointsDataObj.annotationId ? this.wayPointsDataObj.annotationId : this.locationOnMap.id,
      "description": this.wayPointsDataObj.description,
      "name": this.wayPointsDataObj.title ?? this.wayPointsDataObj.name
    }
    this.editAmgAnnotationLoader = true;
    this.locationsHttpService.editAnnotationWithMedia(formData, this.wayPointsDataObj?.media_details)
      .subscribe(
        {
          next: (data) => {
            if (data?.body.message && data?.body.id) {
              this.wayPointsDataObj.id = data?.id;
              this.locationsHttpService.getAnnotationDetails(data.body.id)
                // .pipe(finalize(() => {
                  
                // }))
                .subscribe((data: any) => {
                  this.editAmgAnnotationLoader = false;
                  let annotationData = data.body
                  if (annotationData) {
                    this.handleAnnotationDetailsResponse(true, annotationData);
                  }
                });
              this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.INSIDER_SPOT_UPDATE_SUCCESS_MSG, "1%", "40%");
              setTimeout(() => {
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
            }
          }, error: (err) => {
            this.handleAmgAnnotationError(err, true);
          },
        }
      );
  }

  public handleAmgAnnotationError(err: any, isEditFlow: boolean): void {
    this.editActive = false;
    this.disableEdit = true;
    this.stateService.setEditActiveStatus(false);
    this.showLoaderUntillGeoJsonCompletes = false;
    this.editAmgAnnotationLoader = false;
    this.waypointAdded = false;
    if (!isEditFlow) {
    // Fix: BETRACKPRO-18643: When AMG annotation creation/update fails, remove the last waypoint added
    // and do not add it to the list of waypoints or create a new card in carousel
      const index = this.waypointArray.length - 1;
      this.mapInstance.removeLayer(this.waypointArray[index - 1]);
      this.waypointsList.pop();
      this.waypointArray.pop();
      this.waypointslatlngs.pop();
    }
    const errorMsg = err && err.error['error-code'] ? this._circuitService.getToastErrMessagesBasedOnCode(err.error['error-code']): err;
    this.toastService.show(ToastType.Error, errorMsg, '1%','40%');
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MAX_TIME_TOASTER);
  }

  positionZoomElement() {
    let zoomElement = document.getElementsByClassName("leaflet-control-zoom");
    if (zoomElement) {
      zoomElement[0].classList.add("zoom-margin");
    }
  }

  /**
   * @description called up on selection of tour
   */
  public createTour(isCuratedTour: boolean): void {
    this.isTourCreated = true;
    if (this.categories) { this.categories['curatedTour'] = isCuratedTour; }
    let tourData: any = {
      tour_id: this.categorySelected?.tour_id,
      name: this.tourName,
    };
    if (!isCuratedTour) {
      this.locationsHttpService.createEmotionTourPreview(tourData).subscribe({
        next: (data: any) => {
          let result:BusinessTourPreview = data.body
          this.handleJouneyApiResponse(result, isCuratedTour);
        },
        error: (error) => {
          this.handleJourneyApiError(error);
        }
      }
      );
    } else {
      const curatedTourRequest: CuratedTourRequest = {
        tourDetails: tourData,
        annotationIds: this.mediaCarouselService.annotationIds?.length ? this.mediaCarouselService.annotationIds : []
      }
      this.autoAnnotate = false;
      this.locationsHttpService.createCuratedBusinessTour(curatedTourRequest)
        .subscribe({
          next: (response: BusinessTourPreview) => {
            this.handleJouneyApiResponse(response, isCuratedTour);
          }, error: (error) => {
            this.handleJourneyApiError(error);
          }
        }
        );
    }
  }

  /**
   * @description this method is called for both curated and journey APIs, post success tour will be generated and
   * geoJson call will be made. Journey API returns all POIs whereas CuratedJourney APIs returns only the highlights
   * added by admin.
   * @param result 
   * @returns void
   */
  public handleJouneyApiResponse(result: BusinessTourPreview, isCuratedTour: boolean): void {
    this.tourPublishStage = true;
    this.db.updateTour(this.tourId, { tourNotCreated: false });
    let emotionTourdata: BusinessTourPreview = result;
    this.showCategories = false;
    this.plotEmotionTourOnMap(emotionTourdata);
    this.emotionTourTrackencodedGpsPoints = emotionTourdata;
    const route_index = result.features.findIndex((feature: Feature) => feature.properties.landmark_type === ELAND_MARK.ROUTE);
 emotionTourdata.features.forEach((item: Feature) => {
      if (item.properties.landmark_type === ELAND_MARK.ROUTE) {
        this.createdTourGuid = item.properties.guid;
        this.isTourCreated = false;
        this.autoAnnotate = item.properties.auto_annotate ?? !isCuratedTour;
        if (item.geometry.coordinates[0][0] === item.geometry.coordinates[item.geometry.coordinates.length - 1][0] && item.geometry.coordinates[0][1] === item.geometry.coordinates[item.geometry.coordinates.length - 1][1]) {
          emotionTourdata.isRoundTrip = true;
        }
        this.setTourInfo(result.features[route_index]);
        if (result.features.length > 3) {
          let emotionTourdataResult = result;
        emotionTourdataResult.features.forEach((tourItem: Feature) => {
            if (tourItem.properties.landmark_type === ELAND_MARK.ROUTE) {
              if (tourItem.geometry.coordinates[0][0] === tourItem.geometry.coordinates[tourItem.geometry.coordinates.length - 1][0] && tourItem.geometry.coordinates[0][1] === tourItem.geometry.coordinates[tourItem.geometry.coordinates.length - 1][1]) {
                emotionTourdataResult.isRoundTrip = true;
              }
            }
            if (tourItem.properties.landmark_type !== ELAND_MARK.ROUTE &&
              tourItem.properties.landmark_type !== ELAND_MARK.ORIGIN &&
              tourItem.properties.landmark_type !== ELAND_MARK.DESTINATION) {
              const annotationsData = {
                id: tourItem.id,
                name: tourItem?.properties?.name,
                provider: tourItem?.properties.provider,
                description: tourItem?.properties?.description,
                media_details: ((tourItem?.properties.hasOwnProperty('media')) ? (tourItem?.properties?.media && tourItem?.properties?.media?.length > 0) ? tourItem?.properties?.media : [] : []),
              };
              this.annotationDataSetArray.push(annotationsData);
            }
            if (tourItem.properties.landmark_type === ELAND_MARK.ORIGIN) {
              this.startPointData = {
                name: tourItem.properties.name,
                description: tourItem.properties.description,
              };
            }
            if (tourItem.properties.landmark_type === ELAND_MARK.DESTINATION) {
              this.destinationPointData = {
                name: tourItem.properties.name,
                description: tourItem.properties.description,
              };
            }
          });
          this.plotEmotionTourOnMap(emotionTourdataResult);
          this.emotionTourTrackencodedGpsPoints = emotionTourdataResult;          
        } else {
          this.getAnnotationsForEmotionTour(this.createdTourGuid);
        }
      }
    });
  }

  /**
   * 
   * @param error
   * @returns void
   */
  public handleJourneyApiError(error: any, skipBackClick?: boolean): void {
    {
      this.isTourCreated = false;
      const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
      this.toastService.show(ToastType.Error, errorMsg, '1%','40%');
        if(this.gpxUploaded){
          this.currentStep = ETOUR_CREATION_STEPS.SECOND;
          this.gpxUploaded = false;
        }
      setTimeout(() => {
        if(!skipBackClick) {
          const val = {
            backSelect: true,
            curatedTour: this.categories?.curatedTour
          }
          this.backClicked(val);
          this.mediaCarouselService.hideContentOnLoadersPage.next(false);
        }
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
      if (skipBackClick) { this.timeInterval?.unsubscribe() };
    }
  }

  /**
   * @description this will have an object of all required information that is required to show on the modal on left-side of the screen
   * handles routing, delete, displaying od tour info
   * @param feature 
   */
  public setTourInfo(feature: Feature): void {
    this.tourInfoPostPublish = {
      'guid': this.createdTourGuid ? this.createdTourGuid : this._actRoute.snapshot.params.guid,
      'createdDate': feature.properties.createdDate,
      'nickName': feature.properties.owner?.nickName ? feature.properties.owner?.nickName: feature.properties.owner?.name ? feature.properties.owner?.name: feature.properties.owner?.ciamId,
      'tourDescription': feature.properties.description,
      'tourName': feature.properties.name,
      'trackVersions': feature.properties.trackVersions ?? [],
    }
  }

  plotEmotionTourOnMap(geojsonEmotionTour: BusinessTourPreview) {
    this.clearMap();
    this.showCategories = false;
    let endMrakerIcon = {
      icon: L.icon({
        iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
        iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
        // specify the path here
        iconUrl: "assets/locations/tour-pins/destination-pin.svg",
        className: 'destination-marker'
      }),
    };
    let startMrakerIcon = {
      icon: L.icon({
        iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
        iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
        // specify the path here
        iconUrl: "assets/locations/tour-pins/start-pin.svg",
        className: 'start-marker'
      }),
    };
    let amgIcon = {
      icon: L.icon({
        iconUrl: "assets/locations/tour-pins/amg-pin.svg",
        iconSize: [28, 32],
        iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
      })
    };
    let annotationsIcon = {
      icon: L.icon({
        iconSize: [38, 39],
        iconAnchor: [19, 39],
        // specify the path here
        iconUrl: "assets/locations/tour-pins/annotations.svg",
      }),
    };
    let roundTripIcon = {
      icon: L.icon({
        iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
        iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
        // specify the path here
        iconUrl: "assets/locations/tour-pins/round-trip-pin.svg",
      }),
    };
    let highlightedAnnotationsMarkers = L.icon({
      iconUrl: "assets/locations/annotationshighlight.svg",
      iconSize: [56, 56],
      iconAnchor: [23, 56],
    });
    let amgSelectedMarker = L.icon({
      iconUrl: "assets/locations/tour-pins/amg-pin-selected.svg",
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
    });
    let highlightedStartMarkers = L.icon({
      iconUrl: "assets/locations/tour-pins/start-pin-selected.svg",
      iconSize: [60, 62],
      iconAnchor: [30, 47],
      className: 'selected-start'
    });
    let highlightedEndMarkers = L.icon({
      iconUrl: "assets/locations/tour-pins/destination-pin-selected.svg",
      iconSize: [61, 62],
      iconAnchor: [31, 47],
      className: 'selected-destination'
    });
    let highlightedRoundTripMarker = L.icon({
      iconUrl: "assets/locations/tour-pins/round-trip-pin-selected.svg",
      iconSize: [61, 62],
      iconAnchor: [31, 47],
    });
    let resetStartIcon = L.icon({
      iconUrl: "assets/locations/tour-pins/start-pin.svg",
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
      className: 'start-marker'
    });
    let resetEndIcon = L.icon({
      iconUrl: "assets/locations/tour-pins/destination-pin.svg",
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
      className: 'destination-marker'
    });
    let resetAnnotationsMarker = L.icon({
      iconUrl: "assets/locations/tour-pins/annotations.svg",
      iconSize: [38, 39],
      iconAnchor: [19, 39],
    });
    let resetAmgMarker = L.icon({
      iconUrl: "assets/locations/tour-pins/amg-pin.svg",
      iconSize: [28, 32],
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
    });
    let resetRoundTripIcon = L.icon({
      iconUrl: "assets/locations/tour-pins/round-trip-pin.svg",
      iconSize: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_SIZE_COMMON,
      iconAnchor: <any>LOCATIONS_CONSTANTS.ET_PIN_ICON_POSITION_COMMON,
    });
    const landmarkType = geojsonEmotionTour.features.find((feature: any) => feature.properties.landmark_type === ELAND_MARK.ROUTE)
    const polyineColor = landmarkType?.properties.type === EALL_TRACKS.OFFROAD_TRACK ? 'rgb(204, 131, 41)': 'rgb(48, 136, 197)';
    const className = landmarkType?.properties.type === EALL_TRACKS.OFFROAD_TRACK ? 'off-road-polyline': 'emotion-tour-polyline';
    this.polyline = L.geoJSON(<any>geojsonEmotionTour, {
      style: {
        color: polyineColor,
        weight: 4,
        className: className,
      },
      pointToLayer: (feature: any, latlng: any) => {
        if (feature.properties.landmark_type.toLowerCase() === "origin") {
          if (geojsonEmotionTour.isRoundTrip) {
            this.EmotionTourMarkers = L.marker(latlng, roundTripIcon);
            this.EmotionTourMarkers.type = "roundtripMarker";
            return this.EmotionTourMarkers;
          } else {
            this.EmotionTourMarkers = L.marker(latlng, startMrakerIcon);
            this.EmotionTourMarkers.type = "startMarker";
            return this.EmotionTourMarkers;
          }
        } else if (
          feature.properties.landmark_type.toLowerCase() === "destination" &&
          !geojsonEmotionTour.isRoundTrip
        ) {
          this.EmotionTourMarkers = L.marker(latlng, endMrakerIcon);
          this.EmotionTourMarkers.type = "endMarker";
          return this.EmotionTourMarkers;
        } else if (
          feature.properties.landmark_type.toLowerCase() !== "origin" &&
          feature.properties.landmark_type.toLowerCase() !== "destination"
        ) {
          if (landmarkType?.properties.type === EALL_TRACKS.OFFROAD_TRACK) {
            this.EmotionTourMarkers = L.marker(latlng, amgIcon);
            this.EmotionTourMarkers.type = "amgMarker";
          } else {
            this.EmotionTourMarkers = L.marker(latlng, annotationsIcon);
            this.EmotionTourMarkers.type = "annotationsMarker";
          }
          return this.EmotionTourMarkers;
        }
      },
      onEachFeature: (feature, layer) => {
        let customPopup =
          "<h1>" +
          feature.properties.name +
          "</h1><small>Distance from start</small><p>" +
          (feature.properties.route_position/ 1000).toFixed(2) +
          " km" + "</p>";
        // specify popup options
        let customOptions = {
          maxWidth: 200,
          className: "dealer-custompopup",
        };
        layer.bindPopup(customPopup, customOptions);
        layer.on("popupopen", (evt: any) => {
          this.selectedAnnotationPinFromMap = evt.target?.feature ? evt.target.feature.id: '';
          if (this.selectedAnnotationPinFromMap) {
            let coords = {
              lat: evt.target?.feature.geometry?.coordinates[1],
              long: evt.target?.feature.geometry?.coordinates[0]
            }
            this.zoomIntoMarker(coords.lat, coords.long); 
          }
          if (evt.target.type === "annotationsMarker") {
            evt.target.setIcon(highlightedAnnotationsMarkers);
          } else if (evt.target.type === "amgMarker") {
            evt.target.setIcon(amgSelectedMarker);
          } else if (evt.target.type === "startMarker") {
            evt.target.setIcon(highlightedStartMarkers);
          } else if (evt.target.type === "endMarker") {
            evt.target.setIcon(highlightedEndMarkers);
          } else if (evt.target.type === "roundtripMarker") {
            evt.target.setIcon(highlightedRoundTripMarker);
          }
        });
        layer.on("popupclose", (evt: any) => {
          let popuplayer = evt.target;
          this.selectedAnnotationPinFromMap = evt.target?.feature && this.selectedAnnotationPinFromMap !== evt.target.feature.id ? evt.target.feature.id: '';
          if (evt.target.type === "annotationsMarker") {
            popuplayer.setIcon(resetAnnotationsMarker);
          } else if (evt.target.type === "amgMarker") {
            popuplayer.setIcon(resetAmgMarker);
          } else if (evt.target.type === "startMarker") {
            popuplayer.setIcon(resetStartIcon);
          } else if (evt.target.type === "endMarker") {
            popuplayer.setIcon(resetEndIcon);
          } else if (evt.target.type === "roundtripMarker") {
            evt.target.setIcon(resetRoundTripIcon);
          }
        });
      },
    });
    this.polyline.type = "emotionTourPolyline";
    this.mapInstance.addLayer(this.polyline);    
    this.recenterToTourPublished();
    this.tourPublishStage = true;
    this.mediaCarouselService.hideContentOnLoadersPage.next(false);
    this.showLoaderUntillGeoJsonCompletes = false;
    this.disablePublishButton = false;
  }

  public getAnnotationsForEmotionTour(guid: string): void {
    this.showLoaderUntillGeoJsonCompletes = true;
    this.disablePublishButton = true;
    this.emotionTourTrackencodedGpsPoints = <any>null;
    this.db.getTour(this.tourId).then((data: any) => {
      let emotionTourdata: BusinessTourPreview;
      let noOfTimesCalled = 0;
      this.timeInterval = interval(6000)
        .pipe(
          startWith(0),
          switchMap(() =>
            this.locationsHttpService.getEmotionTourWithAnnotations(guid, "location_module")))
          .subscribe({
          next: (result: any) => {
            let data:BusinessTourPreview = result.body
            const route_index = data.features.findIndex((feature: Feature) => ELAND_MARK.ROUTE === feature.properties.landmark_type)
            this.autoAnnotate = data.features[0].properties['auto_annotate'] ;
            this.setTourInfo(data.features[0]);
            if (data.features.length > 3) {
              emotionTourdata = data;
              emotionTourdata.features.forEach((item: Feature) => {
                if (item.properties.landmark_type === ELAND_MARK.ROUTE) {
                  if (item.geometry.coordinates[0][0] === item.geometry.coordinates[item.geometry.coordinates.length - 1][0] && item.geometry.coordinates[0][1] === item.geometry.coordinates[item.geometry.coordinates.length - 1][1]) {
                    emotionTourdata.isRoundTrip = true;
                  }
                }
                if (item.properties.landmark_type !== ELAND_MARK.ROUTE && item.properties.landmark_type !== ELAND_MARK.ORIGIN && item.properties.landmark_type !== ELAND_MARK.DESTINATION) {
                  const annotationsData = {
                    id: item.id,
                    name: item?.properties?.name,
                    provider: item?.properties.provider,
                    description: item?.properties?.description,
                    media_details: ((item?.properties.hasOwnProperty('media')) ? (item?.properties?.media && item?.properties?.media.length > 0) ? item?.properties?.media : [] : []),
                  };
                  this.annotationDataSetArray.push(annotationsData);
                }
              });
              this.plotEmotionTourOnMap(emotionTourdata);
              this.resetTourDetailsPostGeoJson(data);
              this.timeInterval?.unsubscribe();
            }
            noOfTimesCalled = noOfTimesCalled + 1;
            // If the tour is NOT auto annotated, then we need to call the API only once
            if (noOfTimesCalled > LOCATIONS_CONSTANTS.GEOJSON_MAX_COUNT || 
              (!this.gpxUploaded && this.categories.curatedTour && noOfTimesCalled === 1)
            || !this.autoAnnotate) {
              this.timeInterval?.unsubscribe();
              this.emotionTourTrackencodedGpsPoints = <any>null;
              this.plotEmotionTourOnMap(data);
              this.resetTourDetailsPostGeoJson(data);
            }
            const feature = data.features[route_index];
            this.setTourInfo(feature);
          },
          error: (error) => {
            this.handleJourneyApiError(error, true);
          },
        });
      // }
    });
  }

  private resetTourDetailsPostGeoJson(data: BusinessTourPreview): void {
    this.showLoaderUntillGeoJsonCompletes = false;
    this.emotionTourTrackencodedGpsPoints = data;
    this.annotationDataSetArray.sort((a, b) => a.distance - b.distance);
    this.disablePublishButton = false;
    this.disableGenerateButton = false;
  }

  /**
   * @description Called to get cover image of tour. This will be sent to publish API
   */
  public getMediaForPublish(): void {
    this.mediaFiles = [];
      this.stateService.mediaValueForTrailCover$.subscribe((data) => {
        if(data && data.length > 0){
          this.mediaFiles.push(data[0]);
        }        
      });
  }

  public callGeoJsonApi(event: boolean) {
    if (event) {
      this.isTourCreated = true;
      this.locationsHttpService.getEmotionTourWithAnnotations(this.createdTourGuid, 'location_module')
        .subscribe({
          next: (data:any) => {
            let res: BusinessTourPreview = data.body
            const route_index = res.features.findIndex((feature: Feature) => ELAND_MARK.ROUTE === feature.properties.landmark_type)
            this.displayPublishedDetails = true;
            this.isTourCreated = false;
            if (res) {
              const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.OFFROAD_CREATE_SUCCESS_MSG : LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.EMOTION_TOUR_CREATE_SUCCESS_MSG;
              this.toastService.show(ToastType.Success, msg, "1%", "40%");
              setTimeout(() => {
                this.disablePublishButton = false;
                this.mediaFiles = [];
                this.createdAnnotationId = [];
                this.stateService.clearMediaValue();
                this._location.replaceState(this.language + '/locations/business-tour/' + this.createdTourGuid);
                this.createPublishedTourDeatails(res, route_index);
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
            }
          }, error: (error: any) => {
            this.disablePublishButton = false;
            this.mediaFiles = [];
            this.stateService.clearMediaValue();
            this.isTourCreated = false;
            const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
            this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
            setTimeout(() => {
              this.toastService.hide();
            }, AppConstants.MIN_TIME_TOASTER);
          },
        });
    } else {
      this.disablePublishButton = false;
    }
  }

  /**
   * @description called when we click on publish button
   */
  public initiateTourPublish(event?:any): void {
    this.getMediaForPublish()
    this.disablePublishButton = true;
    let data;
    if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH || this.editAfterPublishActive ){
      data = event;
      this.createdTourGuid = data.guid;
    }else{
      data = {
        guid: this.createdTourGuid,
        visibility: "PERMANENT",
        name: (this.uploadedTourName === event?.value.tourName) ? null : event.value.tourName,
        description: event?.value.tourDesc ? event.value.tourDesc : this.tourDesc,
      };
    }    
    this.setAnnotationIdsArray();
    this.updateTourDetailsIfNewAnnotationExists(data); 
    if(this.editAfterPublishActive){
      this.callUpdateTourAPI(this.tourFormDetails);
    } 
  }

  public updateTourDetailsIfNewAnnotationExists(data: any): void {
    if(this.createdAnnotationId.length > 0){
      data.annotationIds = this.createdAnnotationId;
    }
    this.tourFormDetails = data;
  }

  public callUpdateTourAPI(data:any): void {
    this._spinnerService.show();
    let media = this.mediaFiles?.length > 0 ? this.mediaFiles : {}

    const userId = "Admin";
    if (data.name !== "") {
      this.isTourCreated = true;
      this.locationsHttpService.UpdateTrackDetails(userId, data)
        .pipe(concatMap(() => {
          return this.locationsHttpService.getEmotionTourWithAnnotations(this.createdTourGuid, 'location_module')
        }))
        .subscribe({
          next: (response: any) => {
            let res:BusinessTourPreview  = response.body
            const route_index = res.features.findIndex((feature: Feature) => ELAND_MARK.ROUTE === feature.properties.landmark_type)
            this.displayPublishedDetails = true;
            this.isTourCreated = false;
            this._spinnerService.hide();
            if ( res?.features) {
              if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH ){
              const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.OFFROAD_UPDATE_SUCCESS_MSG : LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.EMOTION_TOUR_UPDATE_SUCCESS_MSG;
              this.toastService.show(ToastType.Success, msg, "1%", "40%");
              this.createdAnnotationId = [];
              } else{
                const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.OFFROAD_CREATE_SUCCESS_MSG : LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.EMOTION_TOUR_CREATE_SUCCESS_MSG;
              this.toastService.show(ToastType.Success, msg, "1%", "40%");
              }
              setTimeout(() => {
                this.disablePublishButton = false;
                this.mediaFiles = [];
                this.createdAnnotationId = [];
                this.stateService.clearMediaValue();
                this._location.replaceState(this.language +'/locations/business-tour/' + this.createdTourGuid);
                this.createPublishedTourDeatails(res, route_index);
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
            }
          }, error: (error: any) => {
            this._spinnerService.hide();
            this.disablePublishButton = false;
            this.mediaFiles = [];
            this.stateService.clearMediaValue();
            this.createdAnnotationId = [];
            this.isTourCreated = false;
            const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
            this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
            this.modalType = "loader";
            this.showCategories = false;
            setTimeout(() => {
              this.toastService.hide();
            }, AppConstants.MIN_TIME_TOASTER);
          },
        });
    } else {
      this.disablePublishButton = false;
      this._spinnerService.hide();
    }
  }

  /**
   * @description this is common code reused for Publish click and on ngOnInit for refresh scenario i.e when 
   * clicking on pins on landing page
   * @param tourPreview 
   * @param source 
   */
  public createPublishedTourDeatails(tourPreview: BusinessTourPreview, route_index: number): void {
    const operation = (geometry: Geometry) => {
      return (geometry && geometry.coordinates[0][0] === geometry.coordinates[geometry.coordinates.length - 1][0] &&
        geometry.coordinates[0][1] ===
        geometry.coordinates[
        geometry.coordinates.length - 1
        ][1]);
    }
    this.annotationDataSetArray = [];
    tourPreview.features.forEach((feature) => {
      if (ELAND_MARK.ROUTE !== feature.properties.landmark_type && 
        ELAND_MARK.DESTINATION !==  feature.properties.landmark_type
        && ELAND_MARK.ORIGIN !== feature.properties.landmark_type) {
        const annotationsData = {
          id: feature.id,
          name: feature?.properties?.name,
          provider: feature?.properties.provider,
          description: feature?.properties?.description,
          media_details: ((feature?.properties.hasOwnProperty('media')) ? (feature?.properties?.media && feature?.properties?.media.length > 0) ? feature?.properties?.media : [] : []),
          distance: (feature?.properties.route_position / 1000).toFixed(2),
        };
        this.annotationDataSetArray.push(annotationsData);
      }
      if (ELAND_MARK.ROUTE === feature.properties.landmark_type) { 
        this.autoAnnotate = feature.properties.auto_annotate;
        tourPreview.isRoundTrip = operation(feature.geometry);
      }    
    });
    this.annotationDataSetArray.sort((a, b) => a.distance - b.distance);
    const landMarkRoute = tourPreview.features[route_index]
    this.routeTourId = landMarkRoute?.properties.guid  ?  landMarkRoute?.properties.guid : this._actRoute.snapshot.params.guid;   
    this.coverImg = landMarkRoute?.properties.media && landMarkRoute.properties.media[0].thumb_url ? landMarkRoute.properties.media[0].thumb_url: 'assets/locations/cover-emotiontour.png';
    this.stateService.sendCoverMediaValue(landMarkRoute?.properties.media);
    this.plotEmotionTourOnMap(tourPreview);
    this.setTourInfo(landMarkRoute);
    const mapCurrentData = {
      latLng: landMarkRoute.geometry.coordinates[0][1] + "," +landMarkRoute.geometry.coordinates[0][0],
      zoom: this.zoom,
    }
    this._storeManagerService.setCurrentMapDataToStore(mapCurrentData)
    this.emotionTourTrackencodedGpsPoints = tourPreview;
  }  

  /**
   * @description this is called when child component(carousel) slide is clicked, we are emitting data that has id, name, media details
   * Once we recieve that day, we call openPopup of hereMaps that is responsible to show highlighted pin. Hence, with this method,
   * we have achieved data binding from child to parent
   * @param event 
   */
  public selectedAnnotationActionOnMap(event: {highlightPoint: CarouselData, from: string}): void {
    this.polyline?.eachLayer((feature: any) => {
      if (feature?.feature && event && event.highlightPoint && feature.feature.id === event.highlightPoint.id) {
        feature.openPopup();
      } else {
        feature.closePopup();
      }
    });
  }

  publishgpxUploadedTour(event:any){
    this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
    this.uploadedTourName = event.uploadedName;
    this.createdTourGuid = event.uploadedguid;
    this.gpxUploaded = true;
    this.getAnnotationsForEmotionTour(this.createdTourGuid);
  }

  /**
   * @description Called when we click on edit icon of carousel
   * @param event 
   */
  public getEnableStatusForEdit(event: any): void {  
    const clearOpacityMarker = (markerName: string) => {
      const index = this.opacityMarkers.findIndex((i: any) => i.name === markerName)
      this.opacityMarkers.splice(index, 1);
      this.setOpacity();
    }  
    this.addEditedDetails = false;
    let indexToCompare = ''
    this.editActive = true;
    this.disableEdit = false;
    this.stateService.setEditActiveStatus(true);
    this.locationOnMap = {
      id: event.id? event.id : '',
      name: event.name,
      title: event.title,
      description: event.description? event.description :'',
      media_details: event.media_details ?? [...event.media]
    };
    this.editedPinIndex = event.index;
    this.clearPolylineFromMap();
    if(event.isHighlight){
      this.editHighlightPin = true;
      this.getHighlightDataForEdit(event.id)
    }
    if (this.editedPinIndex === 'start') {
      this.destinationMarksDisabled = true;
      this.waymarkerMarksDisabled = true;
      indexToCompare = this.editedPinIndex;
      this.currentStep = ETOUR_CREATION_STEPS.EDIT_START;
      this.startMarker.dragging.enable();
      this.storedEditCoordinates = this.startMarker._latlng;
      this.editStartPin = true;
      this.markerToBeEdited = this.startMarker;
      clearOpacityMarker(this.editedPinIndex)
    } else if (this.editedPinIndex === 'destination') {
      indexToCompare = this.editedPinIndex;
      this.currentStep = ETOUR_CREATION_STEPS.EDIT_DESTINATION;
      this.editStartPin = true;
      this.markerToBeEdited = this.destinationMarker;
      this.editDestinationPin = true; // Enabled to edit the destination pin from map, in the case of A to B tour
      this.showAddIconMapHover = false;
      this.destinationMarker.dragging.enable();
      this.storedEditCoordinates = this.destinationMarker._latlng;
      clearOpacityMarker(this.editedPinIndex)
    }
    else {
      indexToCompare = 'waypoint' + event.index;
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS;
      clearOpacityMarker(indexToCompare)
      this.mapInstance.eachLayer((layer: any) => {
        if (layer.name === indexToCompare) {
          this.waymarker = this.markerToBeEdited = layer;
          if (!event.isHighlight) {
            layer.dragging.enable();
          } 
          this.storedEditCoordinates = layer._latlng;
        }
      });
      this.db.getTour(this.tourId).then((data: any) => {
        data?.waypoints.forEach((element: any) => {
          if (element.lat === this.storedEditCoordinates.lat && element.lon === this.storedEditCoordinates.lng) {
            this.addEditedDetails = true;
            this.changeDetectorRef.detectChanges();
          }
        });
      })
    }
  }

  /**
   * @description Called when we need to edit any highlight tagged as an AMG annotation, will have all name, desc details.
   * We are fetching this info from our dummy DB.
   * @param highlightId 
   */
  public getHighlightDataForEdit(highlightId: string): void {
    if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH || (this.emotionTourTrackencodedGpsPoints && !this.autoAnnotate)){
      this.editPublishedAnnotations(highlightId);
    } else {
      this.db.getTour(this.tourId).then((data: any) => {
        data.annotationList.forEach((el: AmgAnnoDtlsResponse) => {
          if (el.id === highlightId) {
            this.editedHighlightInfo = el;
            this.changeDetectorRef.detectChanges();
          }
        });
      });
    }
    
  }

  editPublishedAnnotations(annotationData: any) {
    let annotationId = annotationData.id ? annotationData.id : annotationData
    this.locationsHttpService.getAnnotationDetails(annotationId)
      .subscribe((response: any) => {
        let data = response.body
        if (data) {
          this.editedHighlightInfo = data;
          this.locationOnMap = {
            id: data.id,
            name: data.properties.name,
            title: data.properties.title,
            description: data.properties.description,
            media_details: data.properties.media
          };
          this.stateService.sendMediaValueForAnnotations(data.properties.media);
          // this._circuitService.setHighlightMediaValue(this.locationOnMap)
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  getEditFlowTrigger(data:boolean){
    this.editAfterPublishActive = data
  }

  getRemovedTour(event:any){
    this.removedTours.push(event)

  }

  getDeleteCardFlowTrigger(event:any){
    this.initiateWaypointSoftDelete = event;
    this.currentStep = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH
  }

  ngOnDestroy() {
    // Added to empty the array when component is destroyed
    this.mediaCarouselService.annotationIds.splice(0, this.mediaCarouselService.highlightList.length);
    this.mediaCarouselService.highlightList.splice(0, this.mediaCarouselService.highlightList.length);
    this.subscription.forEach(subs => subs.unsubscribe());
    this.timeInterval?.unsubscribe();
    this.stateService.clearMediaValue();
    this.stateService.clearCoverMediaValue();
    this.mediaCarouselService.copyrightInfoDialog = {
      showModal: false,
      saveData: false,
      updateCopyrightOnFileSelect: false
    } 
    this.stateService.setSendTourToFleet(false);
  }
}
