import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { CircuitListService } from '../../services/circuit-list/circuit-list.service';
import { Subscription, concatMap } from 'rxjs';
import { CircuitDetailsResponse, Layout } from '../../interfaces/search-location.interface';
import { environment } from 'src/environments/environment';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { LocationsHttpService } from '../../services/locations-http.service';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { NgForm } from '@angular/forms';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-track-detail-modal',
  templateUrl: './track-detail-modal.component.html',
  styleUrls: ['./track-detail-modal.component.scss']
})
export class TrackDetailModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  public translationData: any;
  public currentStep: string = '';
  selectedTrackDetails: Layout = <any>null;
  private _language: string = 'en';
  @Output() public displayDiscardCloseModal = new EventEmitter<any>();
  public isFormValueChanged: boolean = false
  @ViewChild('layoutForm') layoutForm: NgForm | undefined;
  public layoutDetails: {layoutName: string, layoutDesc: string} = {layoutName: '', layoutDesc: ''};
  @Output() public updatedCircuitDataEmitter = new EventEmitter<CircuitDetailsResponse>();
  public currentUserProfile: string = '';
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  public commonLabels: any = AppConstants.COMMON_LABELS;
  @Output() updateTrackVisibility: EventEmitter<any> = new EventEmitter<any>();
  public profileName: string = '';
  showLayoutUrl: boolean = false;

  constructor(private _translationService: TranslationService, 
    private _circuitListService: CircuitListService, 
    private _locationsService: LocationsHttpService,
    private _toastService: ToastService,
    private _spinnerService: LoadingSpinnerService,
  private _storeManagerService: StoreManagerService) { 
      this.currentUserProfile = localStorage.getItem('profileName') ?? ''
    }

  ngOnInit(): void {
    this._language = this._storeManagerService.getUserSelectedLanguageFromStore() ?? this._language;
    this._translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
    });
    this.showLayoutUrl = this._translationService.featureFlags.locations.showLayoutUrl;
    this.subscriptions.push(this._circuitListService.showTrackDetails.subscribe((trackData: {show: boolean, layout: Layout}) => {
      if (trackData.show) {
        console.log('trackData :>> ', trackData);
        this.selectedTrackDetails = trackData.layout;
        this.changeCurrentStep('VIEW_TRACK_DETAILS');
        this.selectedTrackDetails['layoutUrl'] = `${environment.routeBaseURL}/${this._language}/explore/circuit/${this.selectedTrackDetails.circuitGuid}`;
        this.layoutDetails.layoutDesc = this.selectedTrackDetails.description ?? '';
        this.layoutDetails.layoutName = this.selectedTrackDetails.name;
        this.profileName = this.getProfileNameForDisplay();
      }
    }));
  }

  private getProfileNameForDisplay(): string {
    if (this.selectedTrackDetails && this.selectedTrackDetails.owner) {
      return this.selectedTrackDetails.owner.nickName ? this.selectedTrackDetails.owner.nickName: this.selectedTrackDetails.owner.name;
    } else {
      return '';
    }
  }

  public getPrimaryButtonName(): string {
    switch(this.currentStep) {
      case 'VIEW_TRACK_DETAILS':
        return this.buttonNames.EDIT;
      case 'EDIT_TRACK_DETAILS':
      return LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_UPDATE;
      default:
        return this.buttonNames.EDIT;
    }
  }

  public getSecondaryButtonName(): string {
    switch(this.currentStep) {
      case 'VIEW_TRACK_DETAILS':
        return this.buttonNames.DELETE;
      case 'EDIT_TRACK_DETAILS':
      return this.buttonNames.CANCEL;
      default:
        return this.buttonNames.DELETE;
    }
  }

  public decidePrimaryBtnActn(): void {
    switch(this.currentStep) {
      case 'VIEW_TRACK_DETAILS':
        this.changeCurrentStep('EDIT_TRACK_DETAILS');
        this._circuitListService.setSelectedTrackStatus = true;
        this.scrollToTop();
        break;
      case 'EDIT_TRACK_DETAILS':
        this.saveUpdatedTrackDetails();
        break;
    }
  }

  public decideSecondaryBtnActn(): void {
    switch(this.currentStep) {
      case 'VIEW_TRACK_DETAILS':
        // Show confrimarion Modal to delete
        this.showConfirmationPopup(LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DELETE_TRACK_MSG, 'DELETE');
        break;
      case 'EDIT_TRACK_DETAILS':
        if (!this.isFormValueChanged) {
          this.changeCurrentStep('VIEW_TRACK_DETAILS');
          this._circuitListService.setSelectedTrackStatus = false;
          this.scrollToTop();
        }
        else {
          this.showConfirmationPopup(LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_EDIT_INSIDER_SPOT_MSG, 'CANCEL');
        }
        break;
    }
  }

  /**
   * @description Show conformation popup for delete and cancel step
   * @param msg 
   */
  public showConfirmationPopup(msg: string, from?: string): void {
    let modalDetails = {};
    const createModal = (title: string, primaryBtnTitle: string, secondaryBtnTitle: string, actionType: string) => {
      modalDetails = {
        show: true,
        title: title,
        message: msg,
        primaryBtnTitle: primaryBtnTitle,
        secondaryBtnTitle: secondaryBtnTitle,
        actionType: actionType
      }
    }
    switch(this.currentStep) {
      case 'VIEW_TRACK_DETAILS':
        createModal(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.DELETE_TRACK_TITLE, AppConstants.COMMON_BUTTON_LABELS.DELETE, AppConstants.COMMON_BUTTON_LABELS.CLOSE, 'DELETE_TRACK');
       break;
      case 'EDIT_TRACK_DETAILS':
        createModal(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EDITING_CARD_DTLS, AppConstants.COMMON_BUTTON_LABELS.DISCARD, AppConstants.COMMON_BUTTON_LABELS.CLOSE, from === 'BACK' ? 'HIDE_TRACK': 'DISCARD_STEP');
        break;
    }
    this.displayDiscardCloseModal.emit(modalDetails);
  }

  /**
   * 
   * @param from to decide what actions has to be performed for specific use cases
   */
  public hideModalDetails(from?: string): void {
    if (from === 'DELETE_TRACK') {
      this._locationsService.getCircuit(this.selectedTrackDetails.circuitGuid).subscribe({
        next: (data: HttpResponse<any>) => {
          let response = data.body
          response['guid'] = this.selectedTrackDetails.circuitGuid;
          this.updatedCircuitDataEmitter.emit(response);
          this._circuitListService.showTrackDetails = {show: false, layout: this.selectedTrackDetails};
        }, error: () => {}
      })
    } else {
      if (this.isFormValueChanged) {
        this.showConfirmationPopup(LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_EDIT_INSIDER_SPOT_MSG, 'BACK');
      } else {
        this._circuitListService.showTrackDetails = {show: false, layout: this.selectedTrackDetails};
      }
    }
  }

  scrollToTop(): void{
    let el = document.getElementById("target");
        if (el) {
          el.scrollIntoView();
        } 
  }

  /**
   * @description Called when we click on download gpx button.
   * Downloads track in gpx format and saves with same name as track name
   */
  public downloadSelectedTrack(): void {
    this._spinnerService.show();
    this._locationsService.downloadTrackDetails(this.selectedTrackDetails.guid)
      .subscribe({
        next: (response:any) => {
          const blob = new Blob([response['body']], {
            type: "application/zip",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.download = this.selectedTrackDetails.name;
          anchor.href = url;
          anchor.click();
          this._spinnerService.hide();
        }, error: () => {
          this.displayToastMessage(ToastType.Error, AppConstants.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG);
          this._spinnerService.hide();
        }
      });
  }

  public changeCurrentStep(step: string) {
    this.isFormValueChanged = false;
    this._circuitListService.circuitTrackState = step;
    this._circuitListService._selectedTrackActiveSubject$.next(false);
    this.currentStep = step;
    if (!this.isFormValueChanged) {
      this.layoutDetails.layoutDesc = this.selectedTrackDetails.description ?? '';
      this.layoutDetails.layoutName = this.selectedTrackDetails.name;
    }
  }

  /**
   * @description Called when Save and Update button is clisked. API call that accepts changed values.
   * Post that we will call track API to get updated details
   */
  public saveUpdatedTrackDetails(): void {
    let request: any = {
      guid: this.selectedTrackDetails.guid,
      visibility: 'PERMANENT'
    }
    if (this.layoutForm?.controls['layoutName'].dirty) {
      request['name'] = this.layoutForm.controls['layoutName'].value;
    }
    if (this.layoutForm?.controls['layoutDesc'].dirty) {
      request['description'] = this.layoutForm.controls['layoutDesc'].value;
    }
    this._spinnerService.show();
    this._locationsService.UpdateTrackDetails('Admin', request).pipe(concatMap(() => {
      return this._locationsService.getTrackDetails(this.selectedTrackDetails.guid)
    })).subscribe({
      next: (data: any) => {
        let response = data.body
        this.selectedTrackDetails.name = response.name;
        this.selectedTrackDetails.description = response.description;
        this.updateTrackVisibility.emit(response);
        this.changeCurrentStep('VIEW_TRACK_DETAILS');
        this.displayToastMessage(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_UPDATE_SUCCESS_MSG);
        this._spinnerService.hide();
        this.scrollToTop();
      }, error: () => {
        this.displayToastMessage(ToastType.Error, AppConstants.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG);
        this._spinnerService.hide();
      }
    })
  }

  /**
   * @description API call to delete the selected track
   */
  public deleteTrackOnConfirm(): void {
    this._spinnerService.show();
    this._locationsService.deleteTrack(this.selectedTrackDetails.guid).subscribe({
      next: () => {
        this.displayToastMessage(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_DELETE_SUCCESS_MSG);
        this.hideModalDetails('DELETE_TRACK');
        this._spinnerService.hide();
        this._circuitListService.setSelectedTrackStatus = false;
      }, error: () => {
        this.displayToastMessage(ToastType.Error, AppConstants.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG);
        this._spinnerService.hide();
        this._circuitListService.setSelectedTrackStatus = false;
      }
    })
  }

  public displayToastMessage(type: any, msg: string): void {
    this._toastService.show(type, msg, '1%', '4%');
    setTimeout(() => {
      this._toastService.hide()
    }, LOCATIONS_CONSTANTS.MAX_TIME);
  }

  public copyUrl(url: string): void {
    navigator.clipboard.writeText(url).then(() => {
      this.displayToastMessage(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.URL_COPIED_SUCCESS_MSG);
    });
  }

  public ngOnDestroy(): void {
    this._circuitListService.setSelectedTrackStatus = false;
    this._circuitListService.circuitTrackState = '';
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
