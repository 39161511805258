import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from "src/app/shared/service/loading-spinner.service";
import { DataServiceServiceProfile } from '../services/profile-data-service.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { ToastService, ToastType} from 'src/app/shared/service/toast.service';

@Component({
  selector: 'app-alias-group-members',
  
  templateUrl: './alias-group-members.component.html',
  styleUrls: ['./alias-group-members.component.scss']
})
export class AliasGroupMembersComponent implements OnInit, OnDestroy {

  constructor(public viewContainerRef: ViewContainerRef, 
    private dataService:DataServiceServiceProfile,
    private loadingSpinner:LoadingSpinnerService,
    private translationService:TranslationService,
    private toastService:ToastService
    ) { 

  }
  isModalOpen:boolean=false;
  showToast: boolean = false;
  isToasterShown: boolean = false;
  toastMessage: string='';
  toastType: any = 0;
  position: any;
  leftPosition: any;
  subscriptions: Subscription[] = [];
  members:any
  groupName:any
  translationData:any
  profileName:any
  public enableEditProfile: boolean = false;
  profileDetails:any;
  isIconInfoShown : boolean = false;

  ngOnInit(): void {
    this.groupName=localStorage.getItem("aliasGroupName")
    this.profileName=localStorage.getItem("profileName")
    this.getMemberList()
    this.toastService.toastDetails.subscribe((status:any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }
  isMenuClicked:boolean=false;
  showIndex:any;
  dp:any='assets/images/profile.svg'
  linkout:any='assets/images/alias/resize.svg'
  info:any='assets/images/alias/information.svg'
  componentName:string='alias-group-members'
  isCloseModal:boolean=false
  closeButtonTitle:string=AppConstants.COMMON_BUTTON_LABELS.CANCEL
  saveButtonTitle:string='Invite'
  editProfileClicked:boolean=false
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  
getMemberList(){
  this.subscriptions.push(this.dataService.getMemberList(this.groupName).subscribe((res:any)=>{
    let data = res.body
    if(data){
    this.members=data.members
    this.profileDetails=data.profile
    this.loadingSpinner.hide()
    if(this.isToasterShown){
      this.showToast=true;
      let toastMessage= "BP_PM_SUCCESS_ADD"
      this.toastService.show(ToastType.Success,`${toastMessage}`,"1%" , "40%")
      setTimeout(()=>{
      this.showToast=false
      this.isToasterShown=false;
      }, AppConstants.MIN_TIME_TOASTER)
      }
    }
  }))
  this.subscriptions.push(this.translationService
    .getTranslationDataAsObservable()
    .subscribe((translationData: any) => {
      this.translationData = translationData;
      this.enableEditProfile = this.translationService.featureFlags?.profile.enableEditProfile;
    }));
}  
menuClicked(i:any){
  this.isMenuClicked=!this.isMenuClicked
  this.showIndex=i
}
closeDialog(){
  this.isMenuClicked=false
}
addMember(){
  this.isModalOpen = true;
}
editProfile(){
 this.editProfileClicked=true
}
cancelClicked(event:any){
if(event){
  this.editProfileClicked=false
}
}
closeModal(){
  this.isModalOpen = false
}
saveModal(){
  this.isToasterShown = true;
  this.getMemberList()   
}
ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => {
    subscription.unsubscribe();
  });
}


}