import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationsHttpService } from "../../services/locations-http.service";
import { CircuitListService, TrackLayoutAction } from "../../services/circuit-list/circuit-list.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { AppConstants } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { CircuitsServicesService } from "../../services/circuits-services.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-trackupload",
  templateUrl: "./trackupload.component.html",
  styleUrls: ["./trackupload.component.scss"]
})
export class TrackuploadComponent implements OnInit {
  translationData: any;
  trackType: string = 'offroad-trail';
  hideHeadersConditionally: boolean = true;
  constructor(
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private circuitListService: CircuitListService,
    private toastService: ToastService,
    private _translationService: TranslationService,
    private _circuitService: CircuitsServicesService,
    private _actRoute: ActivatedRoute
  ) {}
  @ViewChild("fileField") fileField: any;
  progress: number = 0;
  eventTotal: number = 100;
  showProgress = false;
  showSuccessDailog = false;
  progressTimeInterval: number = 100;
  @Output() closeModal = new EventEmitter<any>();
  @Input() public isShowModal:boolean=false;
  @Input() public page:any;
  @Input() public heading:string = 'Add a new layout';
  trackDetails: any;
  browse:string=AppConstants.COMMON_BUTTON_LABELS.BROWSE
  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  addprogressBar() {
    if(this.page === 'emotiontourCreate') {
      this.progressTimeInterval = 5000;
    }
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        this.progress += Math.round(this.eventTotal / 2);
        this.eventTotal /= 2;
        if (this.progress >= 100) {
          this.progress = 100;
          clearInterval(progressInterval);
        } else if (this.progress == 0) {
          this.addprogressBar();
        }
      }, this.progressTimeInterval);
    }, this.progressTimeInterval);
  }

  async fileChange(event: any) {
    try {
      const file = event.target.files[0];
      const allowedExtensions = ["gpx", "zip", "kml"];
      const fileExtension = file.name.split(".").pop();

      this.fileField.nativeElement.value = "";
      event.target.value = null;

      if (!allowedExtensions.includes(fileExtension)) {
        this.showErrorToast(LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.WRONG_FILE_UPLOADED_ERR);
        return;
      }

      this.trackType = this._actRoute.snapshot.params['trackType'];
      this.hideHeadersConditionally = !['offroad-trail', 'business-tour'].includes(this.trackType);
      this.isShowModal = true;
      this.showProgress = true;
      this.progress = 0;

      this.addprogressBar();
      await this.uploadGpxFile(file);
    } catch (error: any) {
      console.error("File change error:", error);
      this.showErrorToast(error);
    }
  }

  private showErrorToast(message: string) {
    this.toastService.show(ToastType.Error, message, '8%', '45%');
    setTimeout(() => {
      this.toastService.hide();
      if (this.fileField && this.fileField.nativeElement) {
        this.fileField.nativeElement.value = null;
      }
    }, LOCATIONS_CONSTANTS.MAX_TIME);
  }

  hideModal(){
    this.progress = 0;
    this.showProgress = false;
    this.closeModal.emit({showModal:false});
  }

  public async uploadGpxFile(file: any): Promise<void> {
    try {
      const res = await firstValueFrom(this.locationsHttpService.uploadTrack(file));
      const data = res.body;

      if (data?.guid) {
        const response = await firstValueFrom(this.locationsHttpService.getTrackDetails(data.guid));
        const trackData = response.body;
        this.trackDetails = { ...trackData, guid: data.guid };

        this.locationsHttpService.getLatestTrackDetails(this.trackDetails);

        if (this.trackDetails.guid) {
          this.showProgress = false;
          this.showSuccessDailog = true;

          setTimeout(() => {
            this.closeModal.emit({
              showModal: false,
              gpxuploaded: true,
              uploadedguid: trackData.guid,
              uploadedName: trackData.name,
              trackLength: trackData.trackLength,
            });

            if (this.page === 'emotiontourCreate') {
              console.log('trackData', trackData);
            } else {
              this.circuitListService.trackLayoutAction.next(TrackLayoutAction.New);
              this.router.navigateByUrl(`locations/circuit/${trackData.circuitGuid}/layout/${trackData.guid}`);
            }
          }, 200);
        }
      }
    } catch (error) {
      console.error("Upload GPX file error:", error);
      this.handleUploadError(error);
    }
  }

  private handleUploadError(error: any): void {
    let errorMsg = error;
    this.progress = 0;
    this.eventTotal = 100;
    this.showProgress = false;
    this.isShowModal = false;
    this.hideModal();
    if (this.fileField  && this.fileField.nativeElement) {
      this.fileField.nativeElement.value = null;
    }

    if (error?.error && error?.error['error-code']) {
      errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
    }

    this.showErrorToast(errorMsg);
  }
}