import { LOCATIONS_CONSTANTS } from 'src/app/modules/locations/interfaces/locations-constants';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../services/locations-http.service";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { CircuitsServicesService } from "../../services/circuits-services.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { CustomTranslationPipe } from "src/app/shared/pipes/custom-translation.pipe";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { RandomNumberGeneratorService } from "src/app/shared/service/random-number-generator.service";
import { LanguageService } from "src/app/shared/service/language.service";
import { ETOUR_CREATION_STEPS } from "../../interfaces/business-tour-create.interface";
import { TrackDetailModalComponent } from "../../components/track-detail-modal/track-detail-modal.component";
import { Subscription,finalize, of, switchMap } from "rxjs";
import { CircuitListService } from "../../services/circuit-list/circuit-list.service";
import { CircuitDetailsResponse, EALL_TRACKS, ESEARCH_PAGE_STRING, Layout, MapViewResponse, OffRoadTourListEntity } from "../../interfaces/search-location.interface";
import { SearchPanelDB } from './search-panel.db';
import { AppConstants } from 'src/app/shared/constants/constants';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';
declare let require: any;
let polyline = require("google-polyline");

@Component({
  selector: "app-map-view-circuit-list",
  templateUrl: "./map-view-circuit-list.component.html",
  styleUrls: ["./map-view-circuit-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MapViewCircuitListComponent implements OnInit {
  circuitList: any;
  emotionTourList: any;
  limitForEmotionTours: number;
  limitForCircuits: number;
  mapradius: any;
  public currentCoordinates: string;
  showAutoList: number;
  circuitInfo: any;
  apiKey: any;
  selectedType: string;
  subscribeType: any;
  copyCicuitInfo: any;
  copyEmotionTour: any;
  emotionTourInfo: any;
  locationData: any;
  showMapView: boolean;
  isShowCircuitInfo: boolean;
  isShowTrackInfo: boolean;
  isShowEmotionTourInfo: boolean;
  emotionTourDetails: any;
  searchLocation: boolean;
  emotionTourTrackencodedGpsPoints: any;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  trackDetailsName: string;
  primaryButtonColor: string;
  trackName: any;
  circuitName: any;
  urls: any;
  emotionTourData: any;
  circuitData: any;
  trackdetails: any;
  layoutstext: any;
  slides: any;
  sectorPointsDetails: any;
  circuitRadius: any;
  currentDate: string;
  selectedTrack: any;
  trackLength: number;
  updatedRadius: boolean;
  previousType: string;
  paramGuid: any;
  circuitGuid: any;
  circuitDetails: any;
  showCircuit: boolean = false;
  showEarthGif: boolean;
  showEarth: boolean;
  toastMessage: string;
  toastType: any;
  showToast: boolean;
  position: any;
  leftPosition: any;
  isshowDropDown: boolean;
  deletedCircuitGuid: any;
  notificationMessage: any;
  public copyDeletedCircuit: any;
  optionToBePassed: any;
  selectedButton: string;
  isShowAddCircuitSearch: boolean;
  isShowCircuitGeofence: boolean;
  isShowZoombutton: boolean;
  public mapHeight;
  mapValues: any;
  locations: any;
  statusTitle: any;
  statusMessage: any;
  statusSubMessage: any;
  statusImage: any;
  isAddCircuit: any = false;
  alertTitle: any = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
  alertMessage: any = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG;
  alertCancelBtnTitle: any = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
  alertActionBtnTitle: any = AppConstants.COMMON_BUTTON_LABELS.CONTINUE;
  isModalOpen: any;
  actionType: any;
  isDisablePrimaryButton: any = true;
  setZoomInLocation: any = {};
  zoomCount: any = 0;
  isNavigateBack: any = false;
  type: any;
  currentURL: any;
  mySubscription: any;
  homeURL: string;
  newSelection: any;
  isDropdownOpen: any;
  searchHeader: string;
  searchDescription: string;
  searchPlaceholderText: string ="BP_LM_SEARCH_LBL";
  @Output() public isShowMapView;
  showSearchAndCreate: any;
  selectedAnnotationsId: any;
  page: any;
  selectedLocationAddress: any;
  selectedTrackFromSearch: any;
  enableRadiusInputBox: any;
  radiusValue: any;
  radiusValueFromTool: any;
  clearDrawnItems: any;
  showModal: any;
  userLocation=[];
  selectedUserType: any = EALL_TRACKS.ALL;
  yourTracks: any;
  public searchResults: any[] = [];
  mapCircuitsList: any;
  mapEmotionTourList: any;
  translationData: any;
  phrasePipe: any;
  enableFeature: boolean;
  language: string = '';
  passedList: any[] = [];
  subscriptions: Subscription[] = [];
  @ViewChild('trackDetailsComponent') trackDetailsComponent: TrackDetailModalComponent | undefined;
  public offRoadTours: OffRoadTourListEntity[] = [];
  displaySpinnerForClusters: boolean = false
  totalNumberOfTours: number = 0;
  searchApiSubscription: Subscription = new Subscription;

  placeholderObject = {
    placeholderTrackType : 'Circuit'
  }

  // Feature flags
  private _enableEmotionTourFeature: boolean = false;
  public enableLeftPanelView: boolean = false;
  public enableCreatedByYouFilter: boolean = false;
  private _enableCircuitFeature: boolean = false;
  private _enableoffroadfeature: boolean = false;

  constructor(
    private locationsHttpService: LocationsHttpService,
    private location: Location,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private alertModalService: AlertModalService,
    private circuitService: CircuitsServicesService,
    private translationService: TranslationService,
    private mediaCarousalService: MediaCarouselService,
    private randomNumberService: RandomNumberGeneratorService,
    private languageService: LanguageService,
    private _circuitListService: CircuitListService,
    private _db: SearchPanelDB,
    private _storeManagerService: StoreManagerService
  ) {
    this.route.paramMap.subscribe((paramMap) => {
      let guid: any = paramMap.get("guid");
      this.paramGuid = guid;
    });
    this.limitForEmotionTours = 250;
    this.limitForCircuits = 100;
    this.mapradius = LOCATIONS_CONSTANTS.MAP_RADIUS;
    this.currentCoordinates = this._storeManagerService.getCurrentMapDataFromStore()?.latLng;
    this.showAutoList = 0; // use???
    this.selectedType = EALL_TRACKS.ALL;
    this.copyCicuitInfo = [];
    this.copyEmotionTour = [];
    this.showMapView = false;
    this.isShowCircuitInfo = false;
    this.isShowTrackInfo = false;
    this.isShowEmotionTourInfo = false;
    this.searchLocation = false;
    this.primaryButtonTitle = LOCATIONS_CONSTANTS.BUTTON_NAMES.NEXT;
    this.secondaryButtonTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
    this.trackDetailsName = "SELECT LOCATION";
    this.primaryButtonColor = "primary"; // use???
    this.currentDate = "";
    this.updatedRadius = false;
    this.trackLength = 0;
    this.previousType = EALL_TRACKS.ALL;
    this.showCircuit = false;
    this.showEarthGif = true;
    this.showEarth = true;
    this.toastMessage = "";
    this.showToast = false;
    this.isshowDropDown = true;
    this.showSearchAndCreate = true;
    this.selectedButton = "";
    this.isShowAddCircuitSearch = false;
    this.isShowCircuitGeofence = false;
    this.isShowZoombutton = false;
    this.isAddCircuit = false;
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
    this.mapHeight = "100%";
    this.isDisablePrimaryButton = true;
    this.alertMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG;
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CONTINUE
    this.setZoomInLocation = {};
    this.zoomCount = 0;
    this.isNavigateBack = false;
    this.homeURL = "locations";
    this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
    this.searchDescription = environment.environment === 'dev' ? 'BP_LM_landingPage_locationsSearchText' : "BP_LM_landingPage_tracksSearchText";
    this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
    this.apiKey = environment.apikey;
    this.enableFeature = environment.enableFeature;
    this.isShowMapView = new EventEmitter<object>();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
    this.removeIndexedDb()
  }

  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(event: Event) {
    if (this.currentURL !== "locations") {
      localStorage.setItem("isBackButtonClicked", "true");
      this.router.navigate([this.homeURL]);
    } else {
      localStorage.setItem("isBackButtonClicked", "false");
      history.go(-2);
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.selectedButton = 'clear' + this.randomNumberService.randomNumber();
  }

  async removeIndexedDb() {
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db: any) => { window.indexedDB.deleteDatabase(db.name) })
    await this._db.openDb();

  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.location.replaceState(`/${this.language}/locations`);
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        const locationFeatureFlags = this.translationService.featureFlags?.locations;
        this.translationData = translationData;
        this._enableEmotionTourFeature = locationFeatureFlags?.enableEmotionTourFeature;
        this.enableLeftPanelView = locationFeatureFlags?.enableLeftPanelView;
        this.enableCreatedByYouFilter = locationFeatureFlags?.enableCreatedByYouFilter;
        this._enableCircuitFeature = locationFeatureFlags?.enableCircuitFeature;
        this._enableoffroadfeature = locationFeatureFlags?.enableoffroadfeature;
      }
    );
    this.phrasePipe = new CustomTranslationPipe;
    localStorage.removeItem("form-data");
    localStorage.removeItem("form-image");
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
      this.placeholderObject = status.placeholderObject;
    });

    this.subscriptions.push(this._circuitListService.showTrackDetails.subscribe((track: {show: boolean, layout: Layout}) => {
      this.isShowTrackInfo = track.show;
    }));
    
    this.currentURL = this.location.path();
    this.displaySpinnerForClusters = true;

    this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.BROWSE_LOCATIONS_MSG;
  }

  getYourMapData(coordinates: any) {
    if (coordinates === null) {
      coordinates = '24.911536,84.187022'
    }
    this.locationsHttpService
      .fetchYourTracks('Dummy', coordinates)
      .subscribe(
        {
          next: (data) => {
        this.yourTracks = data;
      },
       error: (error: any) => {
          //Empty error block
        }
       } )
  }

  public updatedCircuitData(event: CircuitDetailsResponse) {
    this.locationData = event;
    if (this.locationData.layouts) {
      this.layoutstext =
        "Layouts" + " " + "(" + this.locationData.layouts.length + ")";
      this.locationData.layouts = this.locationData.layouts.sort(
        (current: any, next: any) => {
          return (
            +new Date(next.updatedDate) - +new Date(current.updatedDate)
          );
        }
      );
    }
    this.updateSlides();
  }

  updateTrackVisibility(event: any) {
    const currentTrack = this.slides.find((slide: any) => slide.guid === event.guid);
    if (currentTrack) {
      currentTrack.visibility = event.visibility
    }
  }

  /* Commenting code for now, likely to be implemented in future
  formatYourCircuitData() {
    this.yourTracks.circuitInfoList = this.yourTracks.circuitInfoList?.map((element: any) => {
      element.type = 'circuit';
      element.totalNumberOfLayouts = element.layouts;

      let imageDataUrl = element.media?.image[0].originalUrl;
      if (imageDataUrl) {
        this.downloadBlobImage(imageDataUrl, element.guid);
      } else {
        element.imageUrl = "assets/locations/redcircuit.png";
      }
      return element
    });
    this.yourTracks.emotionTourList?.forEach((element: any) => {
      element.type = 'emotion_tour'
    });

  }*/

 

  public getMapData(emotionTourLimit: number, circuitLimit: number, offRoadTourLimit: number, point: string, radius: any): void {
    this.displaySpinnerForClusters = true;
    const coords = point.includes('undefined') ? this.currentCoordinates : point;
    radius = radius ? radius : this.mapradius;
    circuitLimit = this._enableCircuitFeature ? circuitLimit : 0;
    emotionTourLimit = this._enableEmotionTourFeature ? emotionTourLimit : 0;
    offRoadTourLimit = this._enableoffroadfeature ? offRoadTourLimit : 0;
    this.searchApiSubscription.unsubscribe(); // Cancel previous API calls
    this.searchApiSubscription = this.locationsHttpService
      .getAllMapViewData(emotionTourLimit, circuitLimit, offRoadTourLimit, coords, radius)
      .pipe(switchMap((data: MapViewResponse) => {
       return of(data);
      }))
      .pipe(finalize(() => {
        this.searchApiSubscription.unsubscribe();
      this.currentCoordinates = point;
      this.mapradius = radius;
      }))  
      .subscribe({
        next: (res: any) => {
          let data:any = res['body']
          this.handleMapViewApiResponse(data);
          this.passedList = [];
          this.passedList = this.circuitService.handleSearchApiResponse(data);
            this.displaySpinnerForClusters = false;
        }
      });
  }

  /**
   * @description 
   * @param data 
   */
  public handleMapViewApiResponse(data: MapViewResponse): void {
    if (data) {  
      this.passedList = [] 
      this.circuitList = this.mapCircuitsList = this.emotionTourList = this.offRoadTours = [];
      this.circuitList = data.circuitInfoList;
      this.mapCircuitsList = this.circuitList;
      this.emotionTourList = data.emotionTourList;
      this.offRoadTours = data.offRoadTourList;
      this.circuitList.viewType = EALL_TRACKS.CIRCUIT;
      this.emotionTourList.viewType = EALL_TRACKS.EMOTION_TOUR;
      data.emotionTourList = this._enableEmotionTourFeature ? data.emotionTourList : [];
      this.mapEmotionTourList = this.emotionTourList;
      // Do we need for offraod?
      this.copyCicuitInfo = this.copyCicuitInfo.concat(
        data.circuitInfoList
      );
      this.copyEmotionTour = this.copyEmotionTour.concat(
        data.emotionTourList
      );
      // This is for deleed circuit edge case, do not remove
      if (this.copyDeletedCircuit) {
        this.deletedCircuitGuid = this.copyDeletedCircuit;
        this.copyDeletedCircuit = null;
        this.isShowCircuitInfo = false;
        this.showSearchAndCreate = true;
        this.isShowEmotionTourInfo = false;
        this.searchLocation = false;
        this.isShowTrackInfo = false;
      }
    }
    setTimeout(() => {
      this.showEarthGif = false;
    });
  }

  /**
   * @description 
   * @param event 
   */
  getpannedCircuitData(event: any) {  
      if (event.guid && event.type) {
        let param;
        this.selectedTrackFromSearch = null;
        if (event.isSearch) {
          this.searchLocation = event;
        }
        if (event.selectedTrack) {
          this.selectedTrackFromSearch = event.selectedTrack;
        }
        if (event.type === EALL_TRACKS.CIRCUIT) {
          param = EALL_TRACKS.CIRCUIT.toLowerCase();
          this.circuitGuid = event.guid;
        } else if (
          event.type == EALL_TRACKS.EMOTION_TOUR) {
          param = "business-tour";
        }else if (event.type == EALL_TRACKS.OFF_ROAD || event.type === EALL_TRACKS.OFFROAD_TRACK) {
          param = "offroad-trail";
        }
        if ("business-tour" === param || "offroad-trail" === param) {
          this.router.navigateByUrl(`/locations/${param}/${event.guid}`);
        } else {
          this.location.replaceState(`/${this.language}/locations/${param}/${event.guid}`);
        }
      } else if (this.selectedType == EALL_TRACKS.ALL) {
        this.getMapData(
          this.limitForEmotionTours,
          this.limitForCircuits,
          this.limitForCircuits,
          event.lat + "," + event.lng,
          event.radius
        );
      } else if (this.selectedType == EALL_TRACKS.CIRCUIT) {
        this.getMapData(
          0,
          this.limitForCircuits,
          0,
          event.lat + "," + event.lng,
          event.radius
        );
      } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR) {
        this.getMapData(
          this.limitForEmotionTours,
          0,
          0,
          event.lat + "," + event.lng,
          event.radius
        );
        this.previousType = this.selectedType;
      } else if (this.selectedType == EALL_TRACKS.OFF_ROAD || this.selectedType === EALL_TRACKS.OFFROAD_TRACK) {
        this.getMapData(
          0,
          0,
          this.limitForCircuits,
          event.lat + "," + event.lng,
          event.radius
        );
        this.previousType = this.selectedType;
      }
      this.currentURL = this.location.path();
  }

  showLocationInfo(location: any) {
    if (location.viewType == EALL_TRACKS.CIRCUIT) {
      this.locationData = location;
      this.currentDate = new Date().toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      if (this.locationData) {
        if (this.locationData.area) {
          this.circuitRadius = this.locationData.area * 1000;
        } else {
          this.circuitRadius = polyline.decode(location.encodedPolyline);
        }
        if (this.locationData.layouts) {
          this.layoutstext =
            "Layouts" + " " + "(" + this.locationData?.layouts?.length + ")";
          this.locationData.layouts = this.locationData.layouts.sort(
            (current: any, next: any) => {
              return (
                +new Date(next.updatedDate) - +new Date(current.updatedDate)
              );
            }
          );
        }
      }
      this.updateSlides();
      this.isShowCircuitInfo = true;
      this.showSearchAndCreate = false;
      this.mapHeight = "100%";
      this.searchLocation = false;
      this.isShowEmotionTourInfo = false;
      this.previousType = this.selectedType;
    } else if (location.viewType == EALL_TRACKS.EMOTION_TOUR) {
      this.locationsHttpService
        .getEmotionTourWithAnnotations(location.guid, 'location_module')
        .subscribe({
          next: (data) => {
            let emotiontourdata: any = data;
            this.searchLocation = false;
            this.isShowCircuitInfo = false;
            this.showSearchAndCreate = false;
            emotiontourdata = emotiontourdata.features.filter((item: any) => {
              if (item.properties.landmark_type && item.properties.landmark_type.toLowerCase() === "route") {
                if (
                  item.geometry.coordinates[0][0] ===
                  item.geometry.coordinates[
                  item.geometry.coordinates.length - 1
                  ][0] &&
                  item.geometry.coordinates[0][1] ===
                  item.geometry.coordinates[
                  item.geometry.coordinates.length - 1
                  ][1]
                ) {
                  data.isRoundTrip = true;
                }
                return item;
              }
            });
            this.emotionTourDetails = emotiontourdata[0];
            this.emotionTourDetails.guid = location.guid;
            this.emotionTourTrackencodedGpsPoints = data;
            this.previousType = this.selectedType;
            this.isShowEmotionTourInfo = true;
          },
        });
    }
  }

  /**
   * @description called when we click on +New icon to create a new emotion tour or circuit
   * @param event 
   */
  public setCreateOption(event: string): void {
    this.isDropdownOpen = true;
    switch (event) {
      case EALL_TRACKS.CIRCUIT:
        this.addNewCircuit();
        break;

      case EALL_TRACKS.EMOTION_TOUR:
        this.addNewDealerFlow();
        break;
      case EALL_TRACKS.OFF_ROAD:
        this.startOffroadTourCreation();
        break;
    }
  }

  public startOffroadTourCreation() {
    let prevData = {
      state: { showNotFound: true },
    };
    this.resetDataForCreatingTour();
    this.router.navigateByUrl(
      `/locations/offroad-trail/create`, prevData);
  }

  /**
   * @description This method is called when toggle submenu is clicked from main screen
   * @param event 
   */
  public meunOptionClicked(event: string): void {
    this.currentCoordinates = this._storeManagerService.getCurrentMapDataFromStore()?.latLng;
    this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
    this.selectedType = event;
    this.previousType = event;
    this.isDropdownOpen = false;
    this.passedList = [];
    this.passedList = [...this.mapCircuitsList, ...this.emotionTourList, ...this.offRoadTours];
    switch (event) {
      case EALL_TRACKS.CIRCUIT:
        this.displaySpinnerForClusters = true;
        this.searchHeader = LOCATIONS_CONSTANTS.BUTTON_NAMES.CIRCUITS;
        this.searchDescription = "BP_LM_TRACK_SEARCH_TEXT";
        // Needs update
        this.notificationMessage = "BP_LM_CIRCUITS_WORLD";
        this.getMapData(0, this.limitForCircuits, 0, this.currentCoordinates, this.mapradius);
        break;
      case EALL_TRACKS.ALL:
        this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
        this.searchDescription =
          "BP_LM_SEARCH_TEXT";
        this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.BROWSE_LOCATIONS_MSG;
        // Unusure why I commented this before...
        // upon unselecting the selected tab, call the API to get all the data
        this.getMapData(this.limitForCircuits, this.limitForCircuits, this.limitForEmotionTours, this.currentCoordinates, this.mapradius);
        break;
      case EALL_TRACKS.EMOTION_TOUR:
        this.searchHeader = "BP_LM_EMOTION_TOUR_LBL";
        this.searchDescription = "BP_LM_EMOTION_TOUR_SEARCH_TEXT";
        // Needs update
        this.notificationMessage = "BP_LM_BROWSE_LOCATIONS_MSG.";
        this.getMapData(this.limitForEmotionTours, 0, 0, this.currentCoordinates, this.mapradius);
        break;

        case EALL_TRACKS.OFF_ROAD:
          this.displaySpinnerForClusters = true;
          this.searchHeader = LOCATIONS_CONSTANTS.BUTTON_NAMES.OFFROAD;
          this.searchDescription = "BP_LM_EMOTION_TOUR_SEARCH_TEXT";
          // Needs update
          this.notificationMessage = "BP_LM_BROWSE_LOCATIONS_MSG.";
          this.getMapData(0, 0, this.limitForEmotionTours, this.currentCoordinates, this.mapradius);
          break;
        this.searchResults = this.yourTracks?.emotionTourList;
        this.searchHeader = "BP_LM_EMOTION_TOUR_LBL";
        this.searchDescription =
          "BP_LM_FIND_YOUR_TRACKS_MSG";
        break;
      default:
        this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
        this.searchDescription =
          environment.environment === 'dev' ? 'BP_LM_SEARCH_TEXT' : "BP_LM_TRACK_SEARCH_TEXT";
        break;
    }
  }

  showSearch(search: any) {
    this.showMapView = search.showSearch;
    this.selectedType = this.previousType;
    if (search.deletedCircuitGuid) {
      this.selectedType = EALL_TRACKS.ALL;
      this.previousType = EALL_TRACKS.ALL;
      this.copyCicuitInfo = [];
      this.copyEmotionTour = [];
      this.copyDeletedCircuit = search.deletedCircuitGuid;
      this.getMapData(
        this.limitForEmotionTours,
        this.limitForCircuits,
        this.limitForCircuits,
        this.currentCoordinates,
        this.mapradius
      );
    } else {
      this.isShowCircuitInfo = false;
      this.showSearchAndCreate = true;
      this.isShowEmotionTourInfo = false;
      this.searchLocation = false;
      this.isShowTrackInfo = false;
      this.isShowAddCircuitSearch = false;
      this.isShowCircuitGeofence = false;
    }
    this.location.replaceState(`/${this.language}/locations`);
    this.currentURL = this.location.path();
    this.meunOptionClicked(this.previousType);
  }

  updateSlides() {
    this.slides = this.locationData.layouts?.map((item: any) => {
      if (typeof item.encodedGpsPoints == "string") {
        item.encodedGpsPoints = polyline.decode(item.encodedGpsPoints);
      }
      item.trackLength = item.trackLength / 1000;
      item.circuitGuid = this.circuitGuid;
      item.updatedDate = new Date(item.updatedDate).toLocaleDateString(
        "de-DE",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
      return item;
    });

  }
  addNewLayout(showModal: any) {
    if (showModal) {
      this.showModal = true;
    }
  }

  closeModal(event: any) {
    if (!event.showModal) {
      this.showModal = false;
    }
  }

  showTrackPolyline(trackdetail: any) {
    this.trackdetails = trackdetail.encodedGpsPoints;
    this.sectorPointsDetails = trackdetail.sectorPoints;
    this.trackLength = trackdetail.trackLength;
    this.selectedTrack = trackdetail.guid;
    this.isShowTrackInfo = true;
    trackdetail.circuitGuid = this.circuitGuid;
    this.locationsHttpService.getLatestTrackDetails(trackdetail);
  }
  addNewCircuit() {
    localStorage.removeItem("form-image");
    this.showSearchAndCreate = false;
    this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
    this.searchDescription = "BP_LM_SEARCH_TEXT";
    this.searchPlaceholderText = "BP_LM_CIRCUIT_SEARCH";
    this.selectedType = EALL_TRACKS.CIRCUIT;
    this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.ZOOM_GEO_FENCE_MSG;
    this.isShowAddCircuitSearch = true;
    this.location.replaceState(`/${this.language}/locations/circuit/create`);
    this.showMapView = false;
    this.previousType = this.selectedType;
    this.mapHeight = "calc(100vh - 13.6875em)";
    this.page=ESEARCH_PAGE_STRING.ADD_NEW_CIRCUIT;
  }

  addNewDealerFlow () {
    let prevData = {
      state: { showNotFound: true },
    };
    this.resetDataForCreatingTour()
    this.router.navigateByUrl(
      `/locations/business-tour/create`, prevData);
  }

  showLoader(event: any) {
    this.showEarthGif = event.show;
  }
  containerClick() {
    if (!this.type) {
      this.isshowDropDown = !this.isshowDropDown;
    }
  }

  public resetDataForCreatingTour() {
    this.mediaCarousalService.CancelCurrentStep.next(ETOUR_CREATION_STEPS.SECOND);
    this.mediaCarousalService.highlightCount.next(0);
    this.mediaCarousalService.hideContentOnLoadersPage.next(false);
    this.mediaCarousalService.startLocationButtonClicked.next(false);
  }

  primaryButtonClick() {
    let coor = this.circuitService.getEditedCoordinates();
    let circuitObj;
    if (coor?.radius) {
      circuitObj = {
        radius: coor.radius,
        coordinates: coor.lat.toString() + "," + coor.lng,
      };
    } else {
      circuitObj = {
        encodedPolyline: coor,
      };
    }
    if (coor) {
      this.isAddCircuit = true;
      this.statusTitle = "BP_LM_SCANNING_GEOFENCE_MSG";
      this.statusMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATING;
      this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATION_IN_PROGRESS;
      this.statusImage = "";
      this.locationsHttpService.createGeofenceValidation(circuitObj).subscribe({
        next: (data: any) => {
          if (data.body) {
            this.circuitGuid = data.body.guid;
            this.statusMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATED_MSG;
            this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.NEXT_STEPS_MSG;
            this.statusImage = "assets/locations/icn_tick.png";

            setTimeout(() => {
              let prevData = {
                state: { isprevPagelocation: true, shareable: true },
              };
              this.router.navigateByUrl(
                `/${this.language}/locations/circuit/add`,
                prevData
              );
            }, AppConstants.MIN_TIME_TOASTER);
          }
        },
        error: (error) => {
          this.statusMessage = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.GEOFENCE_VALIDATION_ERROR;
          this.statusSubMessage = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.TRACK_EXISTS_MSG;
          this.statusImage = "assets/locations/shape.svg";
          setTimeout(() => {
            this.isAddCircuit = false;
          }, AppConstants.MIN_TIME_TOASTER);
        },
      });
    }
  }

  public secondaryButtonClick(event: any): void {
    this.alertTitle = this.phrasePipe.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData);
    this.alertMessage = "BP_LM_CONFIRM_SUBTITLE"
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.NO;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.YES;
    this.isModalOpen = true;
    this.actionType = "revert";
    this.type = EALL_TRACKS.CIRCUIT;
    this.isNavigateBack = true;
  }

  navigateToBack(type: any) {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
    this.alertMessage = "BP_LM_CONFIRM_ALERT_MESSAGE"
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.NO;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.YES;
    this.isModalOpen = true;
    this.actionType = "revert";
    this.type = type;
    this.isNavigateBack = true;
  }

  /**
   * @description 
   * @param event 
   */
  public selectedLocation(event: any): void {
    if (event.showNotification) {
      this.locations = event.selectedGeoPoints;
      this.selectedButton = "polygon";
      this.trackDetailsName = "SET GEO-FENCE";
      
      if (!event.isMoreZoom) {
        // Needs Update
        this.notificationMessage = "BP_LM_ZOOM_GEO_FENCE_MSG";
        this.isShowCircuitGeofence = false;
        this.isShowZoombutton = true;
      } else {
        this.isShowCircuitGeofence = true;
        this.isShowZoombutton = false;
      }
    } else if (event.isEnableGeofence) {
      this.isShowCircuitGeofence = true;
      this.isShowZoombutton = false;

      if (
        event.selectedOption === "" ||
        event.selectedOption === "polygon" ||
        !event.selectedOption ||
        event.selectedOption === "escape"
      ) {
        this.selectedButton = event.selectedOption;
        this.enableRadiusInputBox = false;
        this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.START_CIRCUIT_CREATION;
      } else if (event.selectedOption === "circle") {
        this.optionToBePassed = event.selectedOption;
        this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.SET_CENTER_CIRCLULAR;
      }

      this.trackDetailsName = "SET GEO-FENCE";
    }
    else if (event.encodedDetails || event.circleGeoCoordinates) {
      this.isDisablePrimaryButton = false;
      this.isShowZoombutton = false;
      if (event.showAlert) {
        if (
          this.optionToBePassed !== event.Option &&
          this.optionToBePassed !== "page-canceled"
        ) {
          if (event.Option === "escape") {
            event.Option = "polygon";
          }
          this.newSelection = event.Option ? event.Option : "polygon";
          this.isModalOpen = true;
          this.actionType = "show-alert";
        }
      }
    }
    else if (event.showLabel) {
      if (event.Option) {
        this.optionToBePassed = event.Option;
        if (event.Option === "polygon") {
          this.enableRadiusInputBox = false;
          this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.START_CIRCUIT_CREATION;
        }
        if (event.Option === "circle") {
          this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.SET_CENTER_CIRCLULAR;
        }
      }
    } else if (event.selectedOption === "escape") {
      this.selectedButton = 'clear';
    } else if (!event.isMoreZoom && event.selectedGeoPoints) {
      // Needs Update
      this.notificationMessage = "BP_LM_ZOOM_GEO_FENCE_MSG";
      this.isShowCircuitGeofence = false;

      this.selectedButton = event.selectedOption;
      this.optionToBePassed = event.selectedOption;

      this.isShowZoombutton = true;
      this.locations = event.selectedGeoPoints;
    } else {
      this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.ZOOM_GEO_FENCE_MSG;
      this.isShowCircuitGeofence = false;
      this.isShowZoombutton = false;
      this.selectedButton = event.selectedOption;
      this.optionToBePassed = event.selectedOption;
      this.trackDetailsName = "SELECT LOCATION";
    }
  }

  public optionSelected(option: any): void {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
    this.alertMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG;
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
    this.alertActionBtnTitle =AppConstants.COMMON_BUTTON_LABELS.CONTINUE;
    if (option === "polygon" || option === "circle") {
      this.selectedButton = option;
    } else {
      this.isDisablePrimaryButton = true;
      option = option.toString() + this.randomNumberService.randomNumber();
      this.optionToBePassed = option;
      this.selectedButton = option;
    }
  }
  zoomIn() {
    this.setZoomInLocation = {
      locations: this.locations,
      count: this.zoomCount + 1,
    };

    this.isNavigateBack = false;
  }

  disableSearchBar(event: any) {
    this.showSearchAndCreate = false;
  }

  selectedAnnotation(annotationsId: any) {
    this.selectedAnnotationsId = annotationsId;
  }

  displayDiscardCloseModal(event: any): void {
    this.isModalOpen = event.show;
    this.alertTitle = event.title;
    this.alertMessage = event.message;
    this.alertCancelBtnTitle = event.secondaryBtnTitle;
    this.alertActionBtnTitle = event.primaryBtnTitle
    this.actionType = event.actionType;
  }

  getCancelModalResponse(event: any) {
    this.isModalOpen = false;
    if (event.action) {
      this.isDisablePrimaryButton = true;
      this.optionToBePassed = this.newSelection;
      if (event.actionType === "revert") {
        this.showSearchAndCreate = true;
        // Needs Update
        this.notificationMessage = "BP_LM_TRACK_SEARCH_TEXT";
        this.showMapView = true;
        this.isShowAddCircuitSearch = false;
        this.isShowCircuitGeofence = false;
        this.isShowCircuitInfo = false;
        this.isShowEmotionTourInfo = false;
        this.searchLocation = false;
        this.isShowTrackInfo = false;
        this.mapHeight = "100%";
        this.optionToBePassed = "clear";
        if (this.type.toLowerCase() == "locations") {
          this.previousType = EALL_TRACKS.ALL;
          this.selectedType = this.previousType;
        } else {
          this.previousType = EALL_TRACKS.CIRCUIT;
          this.selectedType = this.previousType;
          this.searchHeader = "BP_LM_CIRCUITS_LBL";
          this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
          if (this.selectedType === EALL_TRACKS.CIRCUIT) {
            this.passedList = this.passedList.filter((item: any) => {
              return item.type === EALL_TRACKS.CIRCUIT;
            });
          }
        }
        this.isNavigateBack = false;
        this.selectedButton = "";
        this.optionToBePassed = "page-canceled";
        this.isShowZoombutton = false;
        this.clearDrawnItems = 'clearDrawnItems' + this.randomNumberService.randomNumber();
        this.location.replaceState(`/${this.language}/locations`);
        this.currentURL = this.location.path();
      } else if (event.actionType === "show-alert") {
        this.newSelection = this.newSelection + "change";
        this.selectedButton = this.newSelection;
      } else if (event.actionType === 'DELETE_TRACK') {
        this.trackDetailsComponent?.deleteTrackOnConfirm();
      } else if (event.actionType === 'DISCARD_STEP') {
        this.trackDetailsComponent?.changeCurrentStep('VIEW_TRACK_DETAILS');
        this._circuitListService.setSelectedTrackStatus = false
      } else if (event.actionType === 'HIDE_TRACK') {
        this._circuitListService.showTrackDetails = { show: false, layout: <any>null }
      }
    }
    else {
      this.isDisablePrimaryButton = false;
      this.selectedButton = this.optionToBePassed;
    }
  }
  getSelectedLocationAddress(event: any) {
    this.selectedLocationAddress = event;
    this.userLocation= event.position;
   
  }

  sendRadiusValuetoMap(radius: any) {
    if (radius < 100 && radius > 0) {
      this.radiusValueFromTool = radius;
    } else {
      this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.INVALID_VALUE_ENETERED, '1%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
    }

  }

  sendRadiusValuetoInput(radius: any) {
    this.radiusValue = radius;
  }

  updateNotification(data: any) {
    this.notificationMessage = data;
  }

  enableRadiusInput(value: any) {
    this.enableRadiusInputBox = value;
  }

  /**
   * @description Called when we click on All from the main filter
   * @param type 
   */
  public changeUserSelectedType(type: string): void {
    if (this.selectedUserType === type && this.selectedType === type) {
      return;
    }
    this.searchResults = [];
    this.circuitList = null;
    this.emotionTourList = null;
    this.selectedUserType = type;
    this.circuitList = []
    this.emotionTourList = []
    this.passedList = [];
      this.selectedType = EALL_TRACKS.ALL;
      this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
      this.searchDescription =
        LOCATIONS_CONSTANTS.HELPER_MSGS.SEARCH_ALL_LOCATIONS_MSG;
      this.circuitList = this.mapCircuitsList
      this.emotionTourList = this.mapEmotionTourList;
      this.getMapData(this.limitForEmotionTours, this.limitForCircuits, this.limitForEmotionTours, this.currentCoordinates, this.mapradius);
  }

  /**
   * @description Called to display a list of search results in dropdown.
   * Passed List is being sent empty so that in cluster-list on left panel
   *  gets populated with only search results
   * @param event
   */
  setSearchResults(event: any[]) {
    this.searchResults = event;
  }

  backToHomeFromMap() {
    this.changeUserSelectedType(EALL_TRACKS.ALL);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.searchApiSubscription.unsubscribe();
  }

}