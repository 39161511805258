import {createAction, props} from '@ngrx/store';

export const setProfile = createAction(
  'SWITCH_PROFILE',
props<{ profile: any }>());

export const setLanguage = createAction(
  'SWITCH_LANGUAGE',
  props<{ language: any }>()
);

export const setUserSelectedMapTiles = createAction(
  'SET_USER_SELECTED_MAP_TILES',
  props<{ mapTiles: any }>()
);

export const mapCurrentData = createAction(
  'MAP_CURRENT_DATA',
  props<{ mapData: any }>()
);

export const mapResetCurrentData = createAction(
  'MAP_RESET_CURRENT_DATA',
  props<{ mapResetData: any }>())
;