import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { concatMap, finalize, of } from 'rxjs';
import { DataServiceServiceProfile } from 'src/app/modules/alias/services/profile-data-service.service';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { LocationsHttpService } from '../../services/locations-http.service';
import { CarsInFleet } from 'src/app/modules/alias/interfaces/fleet-management.interface';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { AppConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
})
export class FleetManagementComponent implements OnInit, OnDestroy {
  @Input() tourFormDetails: any = {}
  @Input() mediaFiles: any[] = [];
  @Input() isEditMode: boolean = false;

  hasCarsInFleet: boolean = false;
  modalData: any = {}
  @Output() callGeoJsonApi = new EventEmitter<boolean>(false);
  vinList: any[] = [];
  toastType: any;
  position: any;
  message: string = '';
  showToast: boolean = false;
  leftPosition: any;

  constructor(private _profileService: DataServiceServiceProfile,
    private _spinnerService: LoadingSpinnerService,
    private _circuitService: CircuitsServicesService,
    private _locationsHttpService: LocationsHttpService,
    private _toastService: ToastService) { }

  ngOnInit(): void {
    this._toastService.toastDetails.subscribe((status:any) => {
      this.showToast = status.isShown;
      this.message = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
    if (!this.isEditMode) {
      this.callGetAllFleetsApi();
    } else {
      this.closePopupOnClick({ action: true, actionType: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW })
    }
  }

  /**
   * @description gets all the cars in the fleet with their VINs
   */
  public callGetAllFleetsApi(): void {
    this._spinnerService.show();
    this._profileService.getFleetList()
      .pipe(finalize(() => {
        this._spinnerService.hide();
      })).subscribe({
        next: (result: any) => {
          let response = result.body
          if (response?.length) {
            this.modalData = {
              title: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW,
              message: LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.SEND_TRACK_TO_FLEET_MSG,
              cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
              actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CONFIRM,
              actionType: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW
            }
            this.vinList = response.reduce((accumulator: any, current: any) => {
              accumulator.push(current.vin);
              return accumulator;
            }, []);
            this.hasCarsInFleet = true;
          } else {
            this.closePopupOnClick({ action: true, actionType: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW })
          }
        }, error: err => {
          this.closePopupOnClick({ action: true, actionType: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW })
        }
      });
  }

  /**
   * @description called when we click on confirm from popup.
   * This updates the track and then sends the tour to cart.
   * If there are no cars in fleet, only Publish the track
   * @param event 
   */
  public closePopupOnClick(event: {action: boolean, actionType: string}): void {
    if (event.action && event.actionType === LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.PUBLISH_NOW) {
      // Call new API after publishing the track
      this._spinnerService.show();
      this.hasCarsInFleet = false;
      if (this.tourFormDetails?.name === '') {
        this._spinnerService.hide();
        this.showToastMessage(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ET_NAME_REQ);
        return;
      }
      this._locationsHttpService.UpdateTrackDetails('Admin', this.tourFormDetails)
      .pipe(concatMap(() => {
        if (this.vinList.length > 0) {
          return this._locationsHttpService.sendTourToCar(this.tourFormDetails.guid, this.vinList.toString())
        } else {
          this.goBackToLastStep();
          this._spinnerService.hide();
          this.callGeoJsonApi.emit(true);
          return of(true)
        }
      }))
      .subscribe({
        next: (response: any) => {
        this.goBackToLastStep();
        this._spinnerService.hide();
        this.callGeoJsonApi.emit(true);
        if (this.vinList.length > 0) {
          this.showToastMessage(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TOUR_SENT_TO_CAR_SUCCESS);
        }
      }, error: err => {
        if (err.url?.includes('/fleet/add')) {
          this.callGeoJsonApi.emit(true);
        } else {
          this.callGeoJsonApi.emit(false);
        }
        this._spinnerService.hide();
        this.goBackToLastStep();
        if (err['error']['error-message'] && err['status'] != 500) { 
          this.showToastMessage(ToastType.Error, err['error']['error-message'])
        } else {
          this.showToastMessage(ToastType.Error, 'Something went wrong. Please try again in sometime')
        }
    }});
    } else {
      this.callGeoJsonApi.emit(false);
      this.goBackToLastStep();
    }
  }

  public goBackToLastStep(): void {
    this.hasCarsInFleet = false;
    this._circuitService.setSendTourToFleet(false);
  }

  public showToastMessage(type: any, message: string): void {
    this._toastService.show(type, message, '8%', '40%');
    setTimeout(() => {
      this._toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }

  ngOnDestroy(): void {
    this.hasCarsInFleet = false;
  }

}
