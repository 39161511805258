import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { LocationsHttpService } from '../../services/locations-http.service';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { AlertModalService } from '../../services/modal/alert-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { CustomTranslationPipe } from 'src/app/shared/pipes/custom-translation.pipe';
import { RandomNumberGeneratorService } from 'src/app/shared/service/random-number-generator.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';


@Component({
  selector: 'app-edit-geofence',
  templateUrl: './edit-geofence.component.html',
  styleUrls: ['./edit-geofence.component.scss']
})
export class EditGeofenceComponent implements OnInit, OnDestroy {
  mapValues: any;
  locations: any;
  apiKey: any;
  isEditFence: boolean = true;
  selectedButton: string = 'polygon';
  trackName: any;
  circuitName: any;
  urls: any;
  zoom: number = 13
  isZoomControlEnabled: boolean = true;
  enableSatellite : boolean = false;
  primaryButtonTitle: string = AppConstants.COMMON_BUTTON_LABELS.SAVE;
  secondaryButtonTitle: string = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
  trackDetailsName: string = "EDIT GEO-FENCE";
  primaryButtonColor: string = "primary";
  radius: any;
  paramGuid: any;
  showLayerSwitchMenu : boolean = true;
  showToast: boolean = false;
  toastMessage: any;
  position: any;
  toastType: any;
  leftPosition: any;

  notificationMessage: any;
  optionToBePassed: any;
  trackData: any;
  radiusValue: any;

  statusTitle: any;
  statusMessage: any;
  statusSubMessage: any;
  statusImage: any;
  isAddCircuit: boolean = false;
  isDisablePrimaryButton: boolean = true;
  showNotFoundPage: any;

  alertTitle: any;
  alertMessage: any;
  cancelBtnTitle: any;
  actionBtnTitle: any;
  isModalOpen: any;
  actionType: any;
  isPrevPageLocation: any;
  enableRadiusInputBox: any;
  prevOption: string = "";

  initialGeofenceCoordinateValue: any;
  initialGeofenceLocationValue: any;
  toastSubscription: any;
  translationData: any;
  phrasePipe: any;
  language: string = '';
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;

  placeholderObject = {
    placeholderTrackType : "Circuit"
  }

  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private circuitService: CircuitsServicesService,
    private httpServices: LocationsHttpService,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private alertModalService: AlertModalService,
    private translationService: TranslationService,
    private randomNumberService: RandomNumberGeneratorService,
    private languageService: LanguageService
    ) {
    this.route.paramMap.subscribe((paramMap) => {
      let guid: any = paramMap.get("guid");
      this.paramGuid = guid;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTab($event: any) {
    $event.returnValue = 'Your data will be lost!';
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    _event: KeyboardEvent
  ) {
    this.optionSelected('clear');
  }

  onBrowserBackBtnClose(): boolean {
    if (confirm('Your changes will not be saved') ) {
      return true
    } else {
      return false
    }
  }



  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.phrasePipe = new CustomTranslationPipe;    
    this.initialGeofenceCoordinateValue = this.circuitService.getEditedCoordinates();
    this.initialGeofenceLocationValue = this.circuitService.getCoordinatesLocation();
    
    let mapData:any = localStorage.getItem("mapData")
    this.mapValues = JSON.parse(mapData)?.state;
    this.isPrevPageLocation = this.mapValues?.isPrevPageLocation;
    if (this.mapValues?.location === undefined) {
      this.showNotFoundPage = true;
    }
    this.setBreadCrumbDetails();
    this.setValues();
    this.toastSubscription = this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
      this.placeholderObject = status.placeholderObject;
    });
  }

  setValues() {
    this.locations = this.mapValues?.location;
    this.apiKey = this.mapValues?.apiKey;
    this.radius = this.mapValues?.radius;
    this.radiusValue = this.radius?.length > 1 ? 0 : (this.radius / 1000).toPrecision(2);
    this.notificationMessage = '';
    if (this.radius?.length > 1) {
      this.prevOption = "polygon"
      this.selectedButton = "polygon"
    } else {
      this.prevOption = "circle"
      this.selectedButton = "circle"
    }
    this.filterTrackData();
  }

  updateNotification(data: any) {
    this.notificationMessage = data;
  }

  enableRadiusInput(value: any) {
    this.enableRadiusInputBox = value;
  }

  sendRadiusValuetoInput(radius: any) {
    radius = Number(radius).toFixed(2);
    this.radiusValue = radius;
  }

  sendRadiusValuetoMap(radius: any) {
    if (radius < 100 && radius > 0) {
      this.radius = radius;
      this.isDisablePrimaryButton = false;
    } else {   
      this.toastSubscription = this.toastService.toastDetails.subscribe((status: any) => {
        this.showToast = status.isShown;
        this.toastMessage = status.message;
        this.toastType = status.toastType;
        this.position = status.position;
        this.leftPosition = status.leftPosition;
      });
      this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.INVALID_VALUE_ENETERED, '1%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
    }

  }

  filterTrackData() {
    let trackLines: any = []
    if (this.mapValues?.track?.length > 0) {
      this.mapValues?.track.forEach((element: any) => {
        trackLines.push(element.encodedGpsPoints)
      });
      this.trackData = trackLines;
    }
  }

  setBreadCrumbDetails() {
    this.trackName = this.mapValues?.name
    this.circuitName = 'Circuits'
    this.urls = {
      circuitUrl:
        "/locations/circuit/" + this.paramGuid,
      trackUrl:
        "/locations/circuit/" + this.paramGuid + "/edit",
      locationUrl: "/locations",
      isSelected: 'track'
    };
  }

  goToLocation(_event: any) {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
    this.alertMessage = "BP_LM_CONFIRM_ALERT_MESSAGE"
    this.cancelBtnTitle = "No"
    this.actionBtnTitle = "Yes"
    this.isModalOpen = true;
    this.actionType = "goToLocations";
  }

  goToCircuits(_event: any) {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
    this.alertMessage = "BP_LM_CONFIRM_ALERT_MESSAGE"
    this.cancelBtnTitle = "No"
    this.actionBtnTitle = "Yes"
    this.isModalOpen = true;
    this.actionType = "goToCircuits";
  }

  optionSelected(option: any) {
    let prevCoordinates = this.circuitService.getEditedCoordinates();
    if (option === this.prevOption) {
      return
    }
    if (option === 'polygon' || option === 'circle') {
      this.isDisablePrimaryButton = false;
      this.optionToBePassed = option;
      if (prevCoordinates) {
        this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
        this.alertMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG
        this.cancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL
        this.actionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CONTINUE
        this.isModalOpen = true;
        this.actionType = "optionChange";        
      } else {        
        this.prevOption = this.optionToBePassed;
        this.selectedButton = this.optionToBePassed;
        if (this.selectedButton.substring(0, 7) === 'polygon') { this.notificationMessage = "BP_LM_CREATE_GEO_AREA" }
        if (this.selectedButton.substring(0, 6) === 'circle') { this.notificationMessage = "BP_LM_CENTRE_POINT" }
      }      
    } else {

      option = option.toString() + this.randomNumberService.randomNumber();
      this.selectedButton = option;
      this.changeDetectorRef.detectChanges();
    }

  }

  enableSaveButton(event: any) {
    if (event) {
      this.isDisablePrimaryButton = false;
    }
  }

  primaryButtonClick() {
    if (!this.isPrevPageLocation) {
      let editedFenceDetails;
      let coor = this.circuitService.getEditedCoordinates();
      if (coor === null) {
        this.statusMessage = "Validation status";
        this.statusSubMessage = "Validation not possible";
      }
      else if (coor.radius) {
        editedFenceDetails = {
          "guid": this.paramGuid,
          "radius": coor.radius,
          "coordinates": coor.lat.toString() + "," + coor.lng
        }
      }
      else {
        editedFenceDetails = {
          "guid": this.paramGuid,
          "encodedPolyline": coor
        }
      }
      this.isAddCircuit = true;
      this.statusTitle = "BP_LM_SCANNING_GEOFENCE_MSG";
      this.statusMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATING;
      this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATION_IN_PROGRESS;
      this.statusImage = "";
      this.httpServices.updateCircuit(editedFenceDetails).subscribe(
        {
          next: (_data) => {
        this.statusMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATED_MSG;
        this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.NEXT_STEPS_MSG;
        this.statusImage = "assets/locations/icn_tick.png";
        setTimeout(() => {
          this.isAddCircuit = false;
        }, 3000);
        setTimeout(() => {
          let prevData = { state: { isprevPagelocation: this.isPrevPageLocation, shareable: true } }
          this.router.navigateByUrl("/locations/circuit/" + this.paramGuid + "/edit", prevData);
        }, 4000)
      },
      error:  (error) => {
          this.statusMessage = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.GEOFENCE_VALIDATION_ERROR;
          this.statusSubMessage =
            error.error['error-message'];
          this.statusImage = "assets/locations/shape.svg";
          setTimeout(() => {
            this.isAddCircuit = false;
          }, 2000);
        }
      }
      )
    } else {
      let coor = this.circuitService.getEditedCoordinates();
      if (coor === null) {
        this.toastSubscription = this.toastService.toastDetails.subscribe((status: any) => {
          this.showToast = status.isShown;
          this.toastMessage = status.message;
          this.toastType = status.toastType;
          this.position = status.position;
          this.leftPosition = status.leftPosition;
        });
        this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.EMPTY_GEOFENCE_ERROR, '1%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
      } else {
        let prevData = { state: { isprevPagelocation: this.isPrevPageLocation, shareable: true } };
        this.router.navigateByUrl("/"+this.language+"/locations/circuit/add", prevData);
      }
      
    }
  }

  secondaryButtonClick() {
    this.alertTitle=this.phrasePipe?.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData)
    this.alertMessage= "BP_LM_CONFIRM_SUBTITLE"
    this.cancelBtnTitle=this.buttonNames.NO;
    this.actionBtnTitle=this.buttonNames.YES;
    this.isModalOpen = true;
    this.actionType = "revert";

  }

  getCancelModalResponse(event: any) {
    this.isModalOpen = false;
    if (event.action) {
      if (event.actionType === 'optionChange') {
        if (this.prevOption !== this.optionToBePassed) {
          this.prevOption = this.optionToBePassed;
          this.selectedButton = this.optionToBePassed;
          if (this.selectedButton.substring(0, 7) === 'polygon') { this.notificationMessage = "BP_LM_CREATE_GEO_AREA" }
          if (this.selectedButton.substring(0, 6) === 'circle') { this.notificationMessage = "BP_LM_CENTRE_POINT" }
        }
      }
      else if (event.actionType === 'revert') {
        this.alertModalService.onAction.next(null);
        let prevData = { state: { isprevPagelocation: this.isPrevPageLocation, shareable: true } }
        if (this.isPrevPageLocation) {
          this.circuitService.setCoordinateValue(this.initialGeofenceCoordinateValue);
          this.circuitService.setCoordinatesLocation(this.initialGeofenceLocationValue);
          this.router.navigateByUrl("/"+this.language+"/locations/circuit/add", prevData);
        } else {
          this.router.navigateByUrl("/locations/circuit/" + this.paramGuid + "/edit", prevData);
        }
      } else if (event.actionType === 'goToLocations') {
        this.router.navigateByUrl('/locations');
      } else if (event.actionType === "goToCircuits") {
        this.router.navigateByUrl(this.urls.circuitUrl);
      }
    }
  }

  ngOnDestroy() {
    if(this.toastSubscription) {
      this.toastSubscription.unsubscribe();
    }
  }
}