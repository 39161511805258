import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../../services/locations-http.service";
import { AlertModalService } from "../../../services/modal/alert-modal.service";
import { DecimalPipe, Location } from "@angular/common";
import { MediaCarouselService } from "../../../services/media/media-carousel.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { LanguageService } from "src/app/shared/service/language.service";
import { AppConstants } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../../interfaces/locations-constants";
@Component({
  selector: "app-layout-details-form",
  templateUrl: "./layout-details-form.component.html",
  styleUrls: ["./layout-details-form.component.scss"],
})
export class LayoutDetailsFormComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() formData: any;
  @Input() formEditable: any;
  @Output() updatedTrackDetails = new EventEmitter<any>();
  public layoutData: any;
  form!: UntypedFormGroup;
  public typeOptions: any;
  public saveData: any;
  public mediaFiles: any;
  public subscribeFormData: any;
  public subscribeMediaData: any;
  public layoutURL: any;
  public mediaUrls: any = [];
  guidValue: any;
  private trackDetails: any = {};
  public trackLength = 0;
  public imageUrls: any[] = [];
  videoUrl: any = "https://www.bigbuckbunny.org/";
  encodedTrackPoints: any;
  trackName: any;
  public count: any = 0;
  public wordCount = 0;
  hasChange: boolean = false;
  @Input("isFormDataReverted") isFormDataReverted = false;
  @Output() setUpdateFlagValue = new EventEmitter<any>();
  translationData: any;
  language: string = ''
  appConstants = AppConstants;
  locationConstants = LOCATIONS_CONSTANTS
  showLayoutUrl: boolean = false;
  @Input() showCopyrightData: boolean = false;
  @Input() deletedImageIds: string[] = [];
  urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private locationsHttpService: LocationsHttpService,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private _decimalPipe: DecimalPipe,
    private location: Location,
    private mediaCarouselService: MediaCarouselService,
    private translationService: TranslationService,
    private languageService: LanguageService
  ) {
    this.getMedia();
  }
  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
        this.showLayoutUrl = this.translationService.featureFlags?.locations.showLayoutUrl;
      }
    );
    
    this.initializeTrackDetails();
    this.subscribeFormData = this.locationsHttpService.shareformData.subscribe(
      (data) => {
        this.saveData = data;
        if (this.saveData == "publish") {
          this.saveAndPublish();
        } else if (this.saveData === "draft") {
          this.saveAsDraft();
        } else {
          this.updateDetails();
        }
      }
    );
  }

  revertFormData() {
    this.getTrackDetails(this.formData.guid);
    this.form.patchValue({
      name: [this.layoutData.name],
      description: [this.layoutData.description],
      createdDate: [this.layoutData.createdDate],
      visibility: [this.layoutData.visibility],
      noOfSectors: [this.layoutData.noOfSectors],
      guid: [this.layoutData.guid],
      ciamId: [this.layoutData.ciamid],
      trackLength: [this.layoutData.trackLength],
      copyright: this.formBuilder.group({
        attribution: this.layoutData.media?.image[0]?.copyright?.attribution,
        license: this.layoutData.media?.image[0]?.copyright?.license,
        license_url: [this.layoutData.media?.image[0]?.license_url, [Validators.pattern(this.urlRegex)]],
        subject_url: [this.layoutData.media?.image[0]?.subject_url, [Validators.pattern(this.urlRegex)]],
        complete: this.layoutData.media?.image[0]?.copyright?.attribution || this.layoutData.media?.image[0]?.copyright?.license
          && this.layoutData.media?.image[0]?.license_url || this.layoutData.media?.image[0]?.subject_url ? true: false
      })
    });
    if (this.layoutData.visibility.toUpperCase() == "DRAFT") {
      this.layoutData.visibility = "PERMANENT";
    }
    this.wordCount = this.form.value.description.length;
    this.form.get("visibility")?.patchValue(this.layoutData.visibility);
    this.locationsHttpService.clearMediaValue();
    this.mediaFiles = [];
    this.isFormDataReverted = false;
  }

  ngOnChanges() {
    this.checkFormStatus();
    if (this.isFormDataReverted) {
      this.revertFormData();
    }
  }

  ngAfterContentChecked() {
    this.textAreaAdjust();
  }

  checkFormStatus() {
    if (this.form) {
      if (this.formEditable) {
        this.form.enable();
        this.form.valueChanges.subscribe((_value) => {
          if (!this.hasChange) {
            this.setUpdateFlagValue.emit({ enableUpdateButton: true });
          }
        });
      } 
    }
  }

  getMedia() {
    this.mediaFiles = [];
    this.subscribeMediaData =
      this.locationsHttpService.sharemediaData.subscribe((data) => {
        if (data) {
          this.mediaFiles = data[0];
        }
      });
  }
  
  getDirtyValues(form: any) {
    let dirtyValues: any = {};
    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];
      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });
    return dirtyValues;
  }
  
  saveAndPublish() {
    if (this.form.controls["visibility"].value == "DRAFT") {
      this.form.controls["visibility"].setValue("PERMANENT");
    }
    let editedValues = this.getDirtyValues(this.form);
    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }
  saveAsDraft() {
    this.form.controls["visibility"].setValue("DRAFT");
    let editedValues = this.getDirtyValues(this.form);
    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }
  updateDetails() {
    let editedValues = this.getDirtyValues(this.form);
    if (Array.isArray(editedValues.guid)) {
      editedValues.guid = editedValues.guid.join();
    }
    if (Array.isArray(editedValues.name)) {
      editedValues.name = editedValues.name.join();
    }
    if (Array.isArray(editedValues.description)) {
      editedValues.name = editedValues.description.join();
    }
    this.guidValue = editedValues.guid;
    if (
      this.layoutData?.name?.toLowerCase() === editedValues?.name?.toLowerCase()
    ) {
      delete editedValues.name;
    }
    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }

  /**
   * @function getCircuitData
   * @description called on clicking `Update` from header, post update service is successful
   * @returns void
   */
  public getCircuitData(): void {
    this.locationsHttpService
      .getCircuit(this.layoutData.circuitGuid)
      .subscribe((data: any) => {
        let newCircuitData: any = data.body;
        newCircuitData.guid = this.layoutData.circuitGuid;
        this.locationsHttpService.getLatestCircuitDetails(newCircuitData);
        if (this.saveData != "") {
          this.router.navigateByUrl(
            `/locations/circuit/${newCircuitData.guid}`
          );
        } else {
          this.getTrackDetails(this.guidValue);
          if (this.layoutData.visibility.toUpperCase() == "DRAFT") {
            this.layoutData.visibility = "PERMANENT";
          }
        }
      });
  }

  /**
   * @function initializeTrackDetails
   * @returns void
   * @description called on load - ngOnInit to initialize form details
   */
  initializeTrackDetails(guid?: string) {
    this.locationsHttpService.getTrackDetails(this.formData.guid).subscribe((trackData) => {
        this.trackDetails = trackData.body;
        this.setValuesForTrackData();
    });
  }
  checkWordCount() {
    this.wordCount = this.form.value.description.length;
  }

  /**
   * @function setValuesForTrackData
   * @param guid : string
   * @returns void
   * @description called from `initializeTrackDetails` and `getTrackDetails` - sets form data - circuit details, name, type...
   */
  private setValuesForTrackData(guid?: string): void {
    this.trackDetails.ciamid = this.trackDetails["owner"] && this.trackDetails["owner"]["ciamId"];
    this.trackDetails.guid = guid ? guid : this.formData.guid;
    this.locationsHttpService.getLatestTrackDetails(this.trackDetails);
    if (this.trackDetails.track) {
      this.encodedTrackPoints =
        this.trackDetails.track.geometry.coordinates;
    } else {
      this.encodedTrackPoints = this.trackDetails.encodedGpsPoints;
    }
    if (!guid) {
      this.layoutURL = `${environment.routeBaseURL}/${this.language}/explore/circuit/${this.formData.name}-${this.formData.guid}`;
    } else {
      this.trackDetails.media?.image?.map((image: any, index: any) => {
        let imageObj: any = {
          originalUrl: image.originalUrl,
          thumbnailUrl: image.thumbnailUrl,
          type: "image",
          count: index,
          id: image.id,
          guid: this.trackDetails.guid,
        };
        this.mediaUrls.push(imageObj);
        this.count = index + 1;
      });
      this.trackDetails.media?.video?.map((video: any, index: any) => {
        let vedioObj: any = {
          originalUrl: video.originalUrl,
          thumbnailUrl: video.thumbnailUrl,
          type: "video",
          count: this.count + index,
          id: video.id,
          guid: this.trackDetails.guid,
        };
        this.mediaUrls.push(vedioObj);
      });
      this.mediaCarouselService.shareMediaUrls.next(this.mediaUrls);
      this.layoutData = this.trackDetails;
    }
    this.layoutData = this.trackDetails;
    this.layoutData.trackLength =
      this._decimalPipe.transform(
        this.layoutData.trackLength / 1000,
        "1.2-2"
      ) +
      " " +
      "km";
    this.layoutData.createdDate = new Date(
      this.layoutData.createdDate?.replace(/\s/, "T")
    ).toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    if (this.layoutData.visibility.toUpperCase() == "DRAFT") {
      this.layoutData.visibility = "PERMANENT";
    }
    this.layoutData.createdBy =
      this.trackDetails["owner"]?.["nickname"] || this.trackDetails["owner"]?.["name"]
        ? this.trackDetails["owner"]?.["nickname"] || this.trackDetails["owner"]?.["name"]
        : this.trackDetails["owner"]?.["ciamId"];
    this.showCopyrightData = this.layoutData.media?.image.length > 0;
    this.form = this.formBuilder.group({
      name: [this.layoutData.name],
      description: [this.layoutData.description],
      createdDate: [this.layoutData.createdDate],
      visibility: [this.layoutData.visibility],
      noOfSectors: [this.layoutData.noOfSectors],
      guid: [this.layoutData.guid],
      ciamId: [this.layoutData.ciamid],
      trackLength: [this.layoutData.trackLength],
      createdBy: [this.layoutData.createdBy],
      copyright: this.formBuilder.group({
        attribution: this.layoutData.media?.image[0]?.copyright?.attribution,
        license: this.layoutData.media?.image[0]?.copyright?.license,
        license_url: [this.layoutData.media?.image[0]?.copyright?.license_url, [Validators.pattern(this.urlRegex)]],
        subject_url: [this.layoutData.media?.image[0]?.subject_url, [Validators.pattern(this.urlRegex)]],
      })
    });
    this.typeOptions = [
      { name: "Permanent", id: "PERMANENT" },
      { name: "Temporary/ one time", id: "TEMPORARY" },
      { name: "Closed", id: "CLOSED" },
    ];
    this.wordCount = this.form.value.description.length;
    this.checkFormStatus();
  }

 
  /**
   * @function getTrackDetails
   * @param guid - string
   * @description called when we Cancel and click Yes from Cancel Popup
   * Called post update is complete - an details in circuit is updated
   */
  getTrackDetails(guid: any) {
      this.locationsHttpService.getTrackDetails(guid).subscribe((trackData) => {
        this.imageUrls = [];
        this.mediaUrls = [];
        this.trackDetails = trackData.body;
        this.updatedTrackDetails.emit(this.trackDetails);
        this.showCopyrightData = trackData?.media?.image?.length;
        this.setValuesForTrackData(guid);
        
      });  
  }

  /**
   * @description 
   * @param data 
   * @param media 
   */
  public updateTrack(data: any, media: any): void {
    if (this.form.invalid) {
      alert('Invalid form');
      return;
    }
    let userId = "Admin";
    if (this.imageUrls.length > 0) {
      this.form.controls.copyright.reset();
      if (this.form.controls.copyright.value.license || this.form.controls.copyright.value.license_url
        || this.form.controls.copyright.value.subject_url) {
          data.copyright = this.form.controls.copyright.value;
        } else {
          this.form.removeControl('copyright');
        }
    }
    data['guid'] = this.form.value.guid;
    data['visibility'] = this.form.value.visibility;
    if (this.deletedImageIds.length > 0) {
      data['deleteMediaIds'] = this.deletedImageIds;
    }
    this.alertModalService.spinnerActiveValue.next(true);
    this.locationsHttpService
      .UpdateTrackDetails(userId, data, media)
      .subscribe({
        next: (res: any) => {
          if (res.body.message) {
            this.getCircuitData();
            this.getTrackDetails(this.formData.guid);
            this.router.navigateByUrl(`/locations/circuit/${this.layoutData.circuitGuid}`
            );
            this.alertModalService.spinnerActiveValue.next(false);
            this.toastService.show(
              ToastType.Success,
              LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_UPDATE_SUCCESS_MSG,
              "8%",
              "45%"
            );
            setTimeout(() => {
              this.toastService.hide();
            }, 4000);
          }
        },

        error: (error: any) => {
          if (error.status == 0) {
            this.toastService.show(
              ToastType.Error,
              "Cannot upload file as media length exceeds",
              "8%",
              "41%"
            );
          } 
          
          else if ((error["error"]["error-code"] ==1434)) {
            this.toastService.show(
              ToastType.Error,
              error["error"]["error-message"],
              "8%",
              "41%"
            );
            this.form.get("name")?.patchValue(this.layoutData.name);
          } else {
            this.toastService.show(
              ToastType.Error,
              error["error"]["error-message"],
              "8%",
              "41%"
            );
          }
          setTimeout(() => {
            this.toastService.hide();
          }, AppConstants.MIN_TIME_TOASTER);
          this.alertModalService.onAction.next(null);
          this.alertModalService.spinnerActiveValue.next(false);
          
        },
      });
  }

  openLayoutUrl(url: string) {
    window.open(url, "_blank");
  }
  ngOnDestroy() {
    this.subscribeFormData.unsubscribe();
    this.subscribeMediaData?.unsubscribe(); //unsubscribe
    this.hasChange = true;
    this.locationsHttpService.sendMediaValue([]);
  }
  textAreaAdjust() {
    let textbox = document.getElementById("description");
    if (textbox) {
      textbox.style.overflow = "hidden";
      textbox.style.height = "1px";
      textbox.style.height = textbox.scrollHeight + "px";
    }
    if (textbox && textbox.scrollHeight > 100) {
      textbox.style.overflow = "auto";
      textbox.classList.add("scroll-style");
    }
  }
  copyText(val: any) {
    navigator.clipboard.writeText(val);
    this.toastService.show(ToastType.Success, `Copied Layout URL`, "1%", "40%");
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }
}
