import { TranslationService } from './../../../../shared/service/translation.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LocationsHttpService } from "../../services/locations-http.service";
import { EALL_TRACKS } from '../../interfaces/search-location.interface';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';

@Component({
  selector: "app-locations-submenu-toggle",
  templateUrl: "./locations-submenu-toggle.component.html",
  styleUrls: ["./locations-submenu-toggle.component.scss"],
})
export class LocationsSubmenuToggleComponent implements OnInit {
  @Input() public selectedViewType: any;
  @Input() public selectedUserType: any;
  @Input() public displaySpinner: boolean = false;
  @Output() public selectedOption = new EventEmitter<string>();
  public type: string = EALL_TRACKS.ALL;
  translationData: any;
  // Feature flags
  public enableCircuitFeature: boolean = false;
  public enableEmotionTourFeature: boolean = false;
  public enableoffroadfeature: boolean = false;
  locationButtonNames = LOCATIONS_CONSTANTS.BUTTON_NAMES;
  
  constructor(
    private router: Router,
    private locationsHttpService: LocationsHttpService,
    private _translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
        this.enableEmotionTourFeature = this._translationService.featureFlags.locations.enableEmotionTourFeature;
        this.enableoffroadfeature = this._translationService.featureFlags.locations.enableoffroadfeature;
        this.enableCircuitFeature = this._translationService.featureFlags.locations.enableCircuitFeature;
      }
    )
    this.locationsHttpService.sendSelectedTypeValue("all");
  }
  ngOnChanges(){
    this.type=this.selectedViewType;
  }

  public setOption(option: string): void {
    if (this.displaySpinner) {
      return;
    }
    if(this.type === option) {
      this.type = EALL_TRACKS.ALL;
    } else {
      this.type = option;
    }    
    this.locationsHttpService.sendSelectedTypeValue(this.type);
    this.selectedOption.emit(this.type);
  }

}
