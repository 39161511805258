<div style="width:100%; height: 100%">
  <div id="map" #mapElement></div>
</div>
<div *ngIf="showLayerSwitchMenu">
  
    <span class="recenter icon icon_recenter" title="Recenter" (click)="recenterCircuit()"></span>
  <button *ngIf="enableSatelliteView"
[ngClass]="selectedLayer === 'normal'?'button-satellite-layer':'button-normal-layer'"
class="switch-button-style selected-layer" (click)="switchMapView()"
title="{{selectedLayer === 'normal' ? 'Satellite': 'Map'}}"></button>

</div>