import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { DataServiceServiceProfile } from '../../services/profile-data-service.service';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { AppConstants } from 'src/app/shared/constants/constants';


@Component({
  selector: 'app-fleet-card',
  templateUrl: './fleet-card.component.html',
  styleUrls: ['./fleet-card.component.scss']
})
export class FleetCardComponent implements OnInit, OnDestroy {

  noCarsFound:boolean=true
  defaultCarImage="assets/images/alias/add-car.svg"
  car="assets/images/car.png"
  carNew="assets/images/car2.png"
  addCarClicked:boolean=false
  editCarClicked:boolean=false
  kebabMenuClicked:boolean=false
  showIndex:number=0
  isDeleteModalOpen: boolean = false;
  carList:any=null;
  title: string = 'BP_LM_CONFIRM_DELETE_TITLE';
  message: string = '';
  cancelBtnTitle: string = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
  actionBtnTitle: string = AppConstants.COMMON_BUTTON_LABELS.CONFIRM;
  actionType: string = 'delete-fleet';
  cardHovered:boolean=false
  subscriptions: Subscription[] = [];
  translationData:any
  carAddedSuccessfully:boolean=false
  loader:boolean=true
  vin:string=''
  editCarDetails:any;
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  public placeholderObject: any = '';

  // All output emitters go here
  @Output() carCountEmitter=new EventEmitter<any>();
  @Output() public componentEmitter = new EventEmitter<any>();
  @Output() public editCarDetailsEmitter= new EventEmitter<any>()
  
  constructor(
    private cdr:ChangeDetectorRef,
    private translationService:TranslationService,
    private dataService:DataServiceServiceProfile,
    private sharingData:SharingDataService,
    private loadingSpinnerService:LoadingSpinnerService,
  ) {}
 
  ngOnInit(): void {
    this.fetchFleetList()
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
    }));
    this.subscriptions.push(this.sharingData.getCardAdded().subscribe((data)=>{
      if(data){
      this.fetchFleetList()
      }
    }))
  }
  cancelClicked(event:any){
    if(event){
      this.addCarClicked=false
      this.editCarClicked=false
    }
  }
  fetchFleetList()
    {
    // this.loadingSpinnerService.show()
    this.dataService.getFleetList().subscribe((data:any)=>{
      this.loadingSpinnerService.hide()
        this.loader=false
        this.noCarsFound=false
        this.carList=data.body
        this.carCountEmitter.emit(data.body?.length)
    })
  }
  addCar(){
    this.addCarClicked=true
    this.editCarDetails=null
    this.cdr.detectChanges()
  }
  kebabMenu(i:any,event:any){
    this.showIndex=i
    this.message="BP_PM_DELETE_FLEET_MESSAGE"
    this.placeholderObject = {
      placeholderCar: this.carList[i]?.carName?this.carList[i].carName:this.carList[i]?.vin
    }
    event.stopPropagation()
    this.kebabMenuClicked=!this.kebabMenuClicked
  }
  closeMenu(){
    this.kebabMenuClicked=false
  }
  userAction(event:any){
    if (event.action && event.actionType === this.actionType) {
      this.loadingSpinnerService.show()
      this.isDeleteModalOpen = false;
      this.dataService.deleteCarFromFleet(this.vin).subscribe((data:any)=>{
          this.fetchFleetList()
          this.loadingSpinnerService.hide()
          this.componentEmitter.emit('fleet-car')
          this.sharingData.setToasterToBeDisplayed(true)
          
          this.cdr.detectChanges()
      })
    }else{
      this.isDeleteModalOpen = false;
    }
  }
  
  hoverOnCard(i:any,event:any){  
  this.cardHovered=true
  this.showIndex=i
  event.stopPropagation()  
  }
  mouseOutFromCard(i:any,event:any){
    this.cardHovered=false
    this.showIndex=i
    this.kebabMenuClicked=false
    this.cdr.detectChanges()
  }
  deleteFleet(vin:string,i:number){
    this.vin=vin
    this.isDeleteModalOpen = true;
    this.showIndex=i
    this.message='BP_PM_DELETE_FLEET_MESSAGE'
    this.placeholderObject = {
      placeholderCar: this.carList[i]?.carName?this.carList[i]?.carName:this.carList[i]?.vin
    }
  }

  editCar(data:any){
    this.editCarClicked=true
    this.editCarDetails=data
    this.editCarDetailsEmitter.emit(data)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriptions:any)=>subscriptions.unsubscribe())
  }

}
