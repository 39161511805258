import { map } from 'rxjs';
import { Component, ElementRef, EventEmitter, Input, HostListener, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MediaCarouselService } from '../../services/media/media-carousel.service';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { LocationsHttpService } from '../../services/locations-http.service';
import { BusinessTourPreview, CarouselData, ELAND_MARK, EMODAL_PRIMARY_ACTIONS, ETOUR_CREATION_STEPS } from '../../interfaces/business-tour-create.interface';
import { ActivatedRoute } from '@angular/router';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { AppConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-dealer-flow-carousel',
  templateUrl: './dealer-flow-carousel.component.html',
  styleUrls: ['./dealer-flow-carousel.component.scss']
})
export class DealerFlowCarouselComponent implements OnInit {

  windowInnerWidth: number = window.innerWidth;
  public showPrevNextButtons: {showLeftArrow: boolean, showRightArrow: boolean} = {showLeftArrow: false, showRightArrow: false};
  initialSlidesWidth: number = 555;
  slideWidth: number = 185;
  highlightIndex: number = 0;
  // checkBox:boolean = true;
  addOverlay:boolean = false;
  state:string = '';
  waypointsList:any = [];
  slideCoverImage: any;
  hideSlidesData: boolean = false;
  startLocationAdded: boolean = false;
  translationData: any;
  annotationId: string = '';
  subscription: Subscription[] = [];
  corouselWidth: any;
  contentListWidth: any;
  showInsiderSPtMsg:boolean=false

  @Input() waypointsData:any;
  @Input() startPointData:any;
  @Input() destinationPointData: any;
  @Input() annotationDetails: any;
  @Input() showLoaderUntillGeoJsonCompletes: boolean = false;
  @Input() annotationDataSetArray: any;
  @Input() currentStep:string = '' ;
  @Input() tourPublishStage:boolean = false;
  @Input() gpxUploaded:boolean = false;
  @Input() deleteEditedPin:boolean = false;
  @Input() editAmgAnnotationLoader: boolean = false;
  @Input() autoAnnotate:any = true;
  @Input() editAfterPublishActive:any = true;


  @Output() sendHighlightStep = new EventEmitter<any>();
  @Output() sendEnableStatus = new EventEmitter<any>();
  @Output() sendRemovedTour = new EventEmitter<any>();

  @ViewChild("slidesContainer", {static: false}) slidesContainer: ElementRef<HTMLDivElement>| undefined;
  @ViewChild("slide", {static: false}) slide: ElementRef<HTMLDivElement> | undefined;

  // Post Tour Publish details go here
  @Output() public selectedAnnotation = new EventEmitter<{highlightPoint: CarouselData, from: string}>();
  @Input() selectedAnnotationPinFromMap: string = ''
  public activeSlide: string = '';
  @Input() public displayPublishedDetails: boolean = false;
  public hideRoundTripCard: boolean = false;
  public displayProgressMsg: string = '';
  @Input() emotionTourTrackencodedGpsPoints: BusinessTourPreview = <any>null;
  @Input() public disableEdit: boolean = false;
  editActive: boolean = false;
  selectedCard:any;
  @Input() isCuratedTour: boolean = false;
  @Input() addEditedDetails: boolean = false;
  public isRoundTrip: boolean = false;
  public inViewCardDetailsMode: boolean = false;
  @ViewChild("startDiv", {static: false}) startDiv: ElementRef<HTMLDivElement> | undefined;
  @ViewChild("destDiv", {static: false}) destDiv: ElementRef<HTMLDivElement> | undefined;
  imgeNotLoading:boolean = false;
  deleteFlag: string = 'DELETE-INSIDER-SPOT';
  newlyAddedWaypoints:any[] = [];
  temporaryDeletedData:any[] = [];
  @Input() initiateWaypointSoftDelete: any;
  carouselTitles = LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES;
  helperMsgs = LOCATIONS_CONSTANTS.HELPER_MSGS;
  public isOffroadTourFlow: boolean = true;

  
  constructor(private mediaCarousalService: MediaCarouselService,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private translationService: TranslationService,
    private _actRoute: ActivatedRoute,
    private locationsHttpService: LocationsHttpService,
    private _elementRef: ElementRef,
    private mediaService: CircuitsServicesService) {
    this.activeSlide= '';
    if (this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid')) {
      this.hideRoundTripCard = true;
    }
  }

  @HostListener("window:resize", ["$event"])
    onResize(event: any) {
    this.windowInnerWidth = event.target.innerWidth;
    this.checkSlidesOverflow();
  }  

  ngOnInit(): void {
    if (!this.hideRoundTripCard) {
      this.state = this.currentStep = ETOUR_CREATION_STEPS.SECOND;
    }
    this.isOffroadTourFlow = this._actRoute.snapshot.params.trackType === 'offroad-trail';
    this.editActive = !this.disableEdit;
    this.subscription.push(this.mediaCarousalService.CancelCurrentStep.subscribe((data: string) => {
      if (data === 'EDIT_AFTER_PUBLISH_CANCELLED' && this.newlyAddedWaypoints.length > 0) {
        this.state = data;
        this.removeCancelledWaypoints();
      }
      if (data === 'EDIT_AFTER_PUBLISH_CANCELLED' && this.temporaryDeletedData.length > 0) {
        this.addTheSoftDeletedWaypointToArray();
      }
      if ((data === 'EDIT_AFTER_PUBLISH') || (this.currentStep === 'ADD_WAY_POINT' && this.editAfterPublishActive) && !this.autoAnnotate) {
        this.state = data;
        this.disableEdit = true;
        this.editActive = false;
      }
      if(data === '' && !this.editAfterPublishActive){
        this.currentStep = this.state = 'TOUR_PUBLISHED'
      }
      if ((data && !this.hideRoundTripCard) && !this.autoAnnotate) {
        // Check why we have state and current step; keep one and delete another
        this.state = this.currentStep = data;
      }
      if (!this.autoAnnotate) {
        this.state =  this.currentStep = data;
      }
    }));

    this.subscription.push(this.alertModalService.onAction.subscribe((data: any) => {
      if(data?.page === 'cancel_current_step' && data['action'] === true && data?.flag !== EMODAL_PRIMARY_ACTIONS.DELETE_INSDIER_SPOT){
        this.disableEdit = true;
        this.editActive = false;
        // Check why we have state and current step; keep one and delete another
        this.state = this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED
      }
    }));


    this.subscription.push(this.mediaCarousalService.decideModalAction.subscribe((response: string) => {
      if (response === 'delete'){
          this.callDeleteDialog(new Event('click'), this.mediaCarousalService.insiderSptDetails.index, this.mediaCarousalService.insiderSptDetails.name,this.mediaCarousalService.insiderSptDetails.id ?? '');
      }
    }));

    this.subscription.push(this.mediaCarousalService.hideContentOnLoadersPage.subscribe((data: boolean) => {
        this.hideSlidesData = data;
    }));

    this.subscription.push(this.mediaCarousalService.startLocationButtonClicked.subscribe((data: boolean) => {
        this.startLocationAdded = data;
    }));

    this.subscription.push(this.alertModalService.onAction.subscribe((data: any) => {
      if (data?.flag === EMODAL_PRIMARY_ACTIONS.DELETE_INSDIER_SPOT) {
          this.deleteHighlight();
      }
      if (data?.flag === 'DELETE-INSIDER-SPOT-POST-PUBLISH') {
        this.removeHighlightPostPublish();
      }
    }));

    this.subscription.push(this.mediaCarousalService.viewSelectedCardDetails.subscribe((data: boolean) => {
      if (!data && this.activeSlide) {
        this.activeSlide = '';
        this.selectedAnnotation.emit(<any>null);
      }
    }));

    this.translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if (this.disableEdit || (this.editActive && changes.currentStep?.currentValue === ETOUR_CREATION_STEPS.WAY_POINT_ADDED 
      && !changes.currentStep.previousValue.includes('EDIT'))) {
      this.editActive = false;
    }
    if(changes.waypointsData?.currentValue) {
      if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH){
        this.newlyAddedWaypoints.push(changes.waypointsData.currentValue)
      }
      if(this.editActive || this.addEditedDetails){
        if(this.selectedCard === 'start') {
          this.startPointData = { 
            id: changes.waypointsData.currentValue.id,
            name: changes.waypointsData.currentValue.name, 
            title: changes.waypointsData.currentValue.title, 
            description: changes.waypointsData.currentValue.description, 
            media: changes.waypointsData.currentValue.media_details?.map((media: any) => ({url: media.thumb_url}))
          };
          if (this.isRoundTrip) {
            this.destinationPointData = this.startPointData;
          }
        } else if(this.selectedCard === 'destination') {
          this.destinationPointData = {
            id: changes.waypointsData.currentValue.id,
            name: changes.waypointsData.currentValue.name, 
            title: changes.waypointsData.currentValue.title, 
            description: changes.waypointsData.currentValue.description, 
            media: changes.waypointsData.currentValue.media_details?.map((media: any) => ({url: media.thumb_url}))
          };
        }
        else {
          this.waypointsList.splice((this.selectedCard),1,changes.waypointsData.currentValue);
        }
        this.editActive = false;
      } else{
        this.waypointsList.push(changes.waypointsData.currentValue);
      }
    }

    if(changes.currentStep?.currentValue  && !this.hideRoundTripCard)  {
      if(this.autoAnnotate && !this.tourPublishStage){
        this.state = this.currentStep;
      } 
    }

    if(changes.tourPublishStage?.currentValue) {
      this.initialSlidesWidth -= 185;
      this.checkSlidesOverflow();
    }

    if (this.annotationDataSetArray?.length > 0) {
      this.waypointsList = this.annotationDataSetArray;
      if (this.displayPublishedDetails) {
        this.waypointsList = this.waypointsList.filter((wayPoint: any, index: any) => {
          return index === this.waypointsList.findLastIndex((obj: any) => obj.name === wayPoint.name)
        });
      }
      this.annotationDataSetArray = [];;
    }
    if (changes?.selectedAnnotationPinFromMap) {
      const slideIndex = this.waypointsList.findIndex((wayPoint: any) => wayPoint.id === this.selectedAnnotationPinFromMap);
      const slideDetails = this.waypointsList[slideIndex];
      this.highlightSelectedCardOnClick(this.selectedAnnotationPinFromMap, 'MAP_PIN', slideIndex, slideDetails);
    }
    if (this.emotionTourTrackencodedGpsPoints && !this.addEditedDetails && !this.selectedCard) {
      const startData = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.ORIGIN === feature.properties?.landmark_type)?.properties
      const destinationData = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.DESTINATION === feature.properties?.landmark_type)?.properties
      this.startPointData = {
        id: this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.ORIGIN === feature.properties?.landmark_type)?.id ?? '',
        name: startData?.name ?? this.getFullLocation(ELAND_MARK.ORIGIN),
        description: startData?.description ??  this.getFullLocation(ELAND_MARK.ORIGIN),
        media: startData && startData.media ? startData.media.map((media: any) => ({url: media.thumb_url})) : [{url: '../../../../../assets/locations/banner-images/OFFROAD_BANNER.png'}]
      }
      this.destinationPointData = {
        id: this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.DESTINATION === feature.properties?.landmark_type)?.id ??'',
        name: destinationData?.name ?? this.getFullLocation(ELAND_MARK.DESTINATION),
        description: destinationData?.description ?? this.getFullLocation(ELAND_MARK.DESTINATION),
        media: destinationData && destinationData.media ? destinationData.media.map((media: any) => ({url: media.thumb_url})) : [{url: '../../../../../assets/locations/banner-images/OFFROAD_BANNER.png'}]
      }
      this.isRoundTrip = this.emotionTourTrackencodedGpsPoints.isRoundTrip ? this.emotionTourTrackencodedGpsPoints.isRoundTrip: false;
    } else {
      this.isRoundTrip = this.mediaCarousalService.isRoundTripSelected;
    }
    this.mediaCarousalService.highlightCount.next(this.waypointsList.length);
    if (this.isRoundTrip && !this.tourPublishStage) {
      this.checkInsiderSpotExists();
    }
    if(changes.initiateWaypointSoftDelete && this.initiateWaypointSoftDelete.annotationId !== ''){
      this.callDeleteDialogPostPublish(this.initiateWaypointSoftDelete.index, this.initiateWaypointSoftDelete.name, this.initiateWaypointSoftDelete.annotationId)
    }
  }

  removeCancelledWaypoints(){
    this.waypointsList = this.waypointsList.filter((waypointData:any)=> {
       return !this.newlyAddedWaypoints.find((newWayPoint) => {
         return waypointData.id === newWayPoint.id
       })
     });
   }

   addTheSoftDeletedWaypointToArray(){
    this.waypointsList.push(...this.temporaryDeletedData);
    this.temporaryDeletedData = [];
   }

  public removeHighlightPostPublish(): void {
    this.temporaryDeletedData.push(this.waypointsList[this.highlightIndex])
    this.waypointsList.splice((this.highlightIndex), 1);
    this.initialSlidesWidth -= 185;
    this.deleteEditedPin = false;
    this.currentStep = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH;
    this.state = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH;
    this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.INSIDER_SPOT_DELETE_SUCCESS_MSG, "1%", "40%")
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }


  /**
   * @description Called when we click on + icon from card
   * @param data 
   * @returns void
   */
  public addHighlight(data: any): void {
    if (this.showLoaderUntillGeoJsonCompletes || this.editActive || this.activeSlide) {
      return;
    }
    this.addEditedDetails = false;
    if(!this.hideSlidesData) {
      this.state = data;
      this.sendHighlightStep.emit(data);
      this.mediaCarousalService.CancelCurrentStep.next(data);
      this.mediaCarousalService.showPlusIconOnMapHover.next(true);
    }
  }

  showCompleteCarousel(): boolean {
    return this.waypointsList.length > 0 || this.tourPublishStage || (this.gpxUploaded)
  }

  /**
   * @description Checks when the arrows should be visible for carousel. If Destination is not visible on right most end OR
   * Start is not visible for left most end, we will display these arrows.
   */
  public checkSlidesOverflow(): void {
    if (this.startDiv && this.destDiv) {
      const startRect = this.startDiv.nativeElement.getBoundingClientRect();
      const destRect = this.destDiv.nativeElement.getBoundingClientRect();
      this.showPrevNextButtons.showLeftArrow = !(startRect.left >= 0 && startRect.right <= this.windowInnerWidth);
      this.showPrevNextButtons.showRightArrow = !(destRect.right >= 0 && destRect.left <= this.windowInnerWidth);
    }
  }

  getAbsoluteWidth(element: any) {
    const styles = window.getComputedStyle(element);
    const margin = parseFloat(styles["marginLeft"]) + parseFloat(styles["marginRight"]);
    return Math.ceil(element.offsetWidth + margin);
  }

  goToPrevSlide() {
    // This is added because during loading this components from landing page, slidesConatiner is undefined as viewchild is not initialized
    if (!this.slidesContainer) {
      const parent = this._elementRef.nativeElement.querySelector('.dealer-carousel-parent');
      const child = parent.querySelector('.slides-list');
      parent.scrollLeft -= this.getAbsoluteWidth(child);
    } else {
      this.slidesContainer.nativeElement.scrollLeft -= this.getAbsoluteWidth(this.slide!?.nativeElement);
    }
  }

  goToNextSlide() {
    // This is added because during loading this components from landing page, slidesConatiner is undefined as viewchild is not initialized
    if (!this.slidesContainer) {
      const parent = this._elementRef.nativeElement.querySelector('.dealer-carousel-parent');
      const child = parent.querySelector('.slides-list');
      parent.scrollLeft += this.getAbsoluteWidth(child);
    } else {
      this.slidesContainer.nativeElement.scrollLeft += this.getAbsoluteWidth(this.slide!?.nativeElement);
    }
  }

  public callDeleteDialogPostPublish(index: number, name: string, annotationId: string): void {
    this.deleteFlag = 'DELETE-INSIDER-SPOT-POST-PUBLISH'
    if (index >= 0) {
      this.highlightIndex = index;
    }
    let firstCharacter = annotationId.slice(1, 5)
    if (firstCharacter === '-en-') {
      this.annotationId = annotationId.slice(5);
    } else{
      this.annotationId = annotationId;
    }
    this.sendRemovedTour.emit(annotationId);
    this.alertModalService.openModal.next(true);
    const modalData = {
      title: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.DLT_INSDR_SPT_TITLE,
      message: LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DELETE_INSIDER_SPT_MSG,
      cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
      actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.DELETE,
      placeholderObject: { 'placeholderInsiderspotName': name},
      flag: this.deleteFlag
    };
 
    this.alertModalService.modalData.next(modalData);
  }

  public callDeleteDialog(event: Event, index: number, name: string, annotationId: string): void {
    
    // Added this to disable delete button click post tour is published
  
    if (index >= 0) {
      this.highlightIndex = index;
    }
    this.annotationId = annotationId;
    this.sendRemovedTour.emit(annotationId);
    this.alertModalService.openModal.next(true);
    const modalData = {
      title: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.DLT_INSDR_SPT_TITLE,
      message: LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DELETE_INSIDER_SPT_MSG,
      cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
      actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.DELETE,
      placeholderObject: { 'placeholderInsiderspotName': name},
      flag: EMODAL_PRIMARY_ACTIONS.DELETE_INSDIER_SPOT
    };
    event.stopPropagation();
    this.alertModalService.modalData.next(modalData);
  }

  showDisableEditPopup(index: any): void {
    if (index === this.mediaCarousalService.insiderSptDetails.index) {
      this.alertModalService.openModal.next(true);
      const modalData = {
        title: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EDITING_CARD_DTLS,
        message: LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.UNSAVED_CHANGES_WARNING,
        cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
        actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CONTINUE,
        flag: EMODAL_PRIMARY_ACTIONS.DISABLE_EDIT
      };
      this.alertModalService.modalData.next(modalData);
    }
  }

  /**
   * @description Called when we click on any card in carousel, 
   * should highglight the specific card and change the view of modal
   * @param item 
   * @param index 
   */
  public viewCardDetailsOnClk(item: any, index: number | string): void {
    if (!this.inViewCardDetailsMode && item) {
      this.mediaCarousalService.insiderSptDetails = item;
      this.mediaCarousalService.insiderSptDetails['index'] = index;
      this.selectedCard = index;
      this.mediaCarousalService.viewSelectedCardDetails = true;
    } else {
      this.resetSelectedCardDetails()
    }
  }

  private resetSelectedCardDetails(): void {
    this.inViewCardDetailsMode = false;
    this.mediaCarousalService.viewSelectedCardDetails = false;
    this.selectedCard = null;
  }

  /**
   * @description Called when we confirm delete action. Removes the specific insider point from
   * the list. In case of AMG annotation, delete API is called and the way point will no longer
   * be available
   */
  public deleteHighlight(): void {
      if (this.highlightIndex >= 0) {
        this.waypointsList.splice(this.highlightIndex, 1);
        /* Using this to send value and use in categories component, else we will have to declare global variable
        pass to parent and then send to categories. the index to splice depends on what position destination is in. Assuming 1st is always origin */
        const tempNum = this.mediaCarousalService.highlightList.findIndex((dest) => dest.includes(ELAND_MARK.DESTINATION)) === 1 ? 2 : 1;
        this.mediaCarousalService.highlightList.splice(this.highlightIndex + tempNum, 1);
        if (!this.waypointsList?.length && this.isRoundTrip) {
          this.currentStep = this.state = ETOUR_CREATION_STEPS.ADD_WAY_POINT
          this.sendHighlightStep.emit(this.currentStep);
          this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
          this.mediaCarousalService.showPlusIconOnMapHover.next(true);
        } else {
          // Check why we have state and current step; keep one and delete another
          this.currentStep = this.state = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
          this.sendHighlightStep.emit(this.currentStep);
          this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
        }
        if (this.annotationId !== '' && this.annotationId !== null && this.annotationId !== undefined) {
          this.locationsHttpService.deleteAnnotation(this.annotationId).subscribe({
            next: () => {
              const index = this.mediaCarousalService.annotationIds.findIndex((id: string) => this.annotationId === id);
              if (index >= 0) {
                this.mediaCarousalService.annotationIds.splice(index, 1);
                this.editActive = false;
                this.mediaService.setEditActiveStatus(false);
              }
            },
            error: () => { },
          });
        }
        this.mediaCarousalService.deleteAnnotationRedrawMap.next(this.highlightIndex);
        this.mediaService.setEditActiveStatus(false);
        this.initialSlidesWidth -= 185;
        this.disableEdit = true;
        this.editActive = false;
        this.toastService.show(
          ToastType.Success,
          LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.INSIDER_SPOT_DELETE_SUCCESS_MSG,
          "1%",
          "40%",
        );

        setTimeout(() => {
          this.toastService.hide();
          this.highlightIndex = -1;
        }, AppConstants.MIN_TIME_TOASTER);
        this.mediaCarousalService.highlightCount.next(this.waypointsList.length);
      }
  }

  /**
   * @description called when slide is clicked from HTML file
   * Also called when user clicks on pins on map, handles 2-way binding between pins and carousel slides
   * @param highlightPoint 
   * @param annotationId 
   */
  public highlightSelectedCardOnClick(annotationId: string, from: string, index: number | string, highlightPoint?: CarouselData | any): void {
    const getDisabledState = (): boolean => {
      const disabledStates: string[] = [ETOUR_CREATION_STEPS.ADD_WAY_POINT, ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS, ETOUR_CREATION_STEPS.EDIT_START, ETOUR_CREATION_STEPS.EDIT_DESTINATION]
      return disabledStates.includes(this.currentStep) || !this.displayPublishedDetails && !this.tourPublishStage;
    }
    if (getDisabledState()) {return;}
    if ('CARD_CLICK' === from) {
      if (this.activeSlide === annotationId || (this.activeSlide?.includes(annotationId))) {
        this.activeSlide = '';
        this.selectedAnnotation.emit(<any>null);
        this.viewCardDetailsOnClk(null, index);
      } else {
        this.activeSlide = annotationId;
        if (highlightPoint) { 
          this.selectedAnnotation.emit({highlightPoint, from});
          this.viewCardDetailsOnClk(highlightPoint, index);
        } else {
          if (annotationId.includes('origin')) {
            const origin = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.ORIGIN === feature.properties?.landmark_type);
            const highlightPoint: CarouselData = {
              id: origin?.id ? origin.id: '',
              name: origin?.properties?.name ? origin.properties.name: '',
              distance: origin?.properties?.route_distance ? origin.properties.route_distance.toFixed(2) : '',
              description: origin?.properties?.description ? origin.properties.description: '',
              media_details: []
            }
            this.selectedAnnotation.emit({highlightPoint, from});
            this.viewCardDetailsOnClk(highlightPoint, index);
          } else if (annotationId.includes('destination')) {
            const destination = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.DESTINATION === feature.properties?.landmark_type);
            const highlightPoint: CarouselData = {
              id: destination?.id ? destination.id: '',
              name: destination?.properties?.name ? destination.properties.name: '',
              distance: destination?.properties?.route_distance ? destination.properties.route_distance.toFixed(2) : '',
              description: destination?.properties?.description ? destination.properties.description: '',
              media_details: []
            }
            this.selectedAnnotation.emit({highlightPoint, from})
            this.viewCardDetailsOnClk(highlightPoint, index);
          }
        }
      }
    } else if ('MAP_PIN' === from) {
      this.activeSlide = annotationId;
      if (annotationId) {
        const cardData = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => annotationId === feature.id);
        const highlightPoint: CarouselData = {
          id: cardData?.id ? cardData.id : '',
          name: cardData?.properties?.name ? cardData.properties.name : '',
          distance: cardData?.properties?.route_distance ? cardData.properties.route_distance.toFixed(2) : '',
          description: cardData?.properties?.description ? cardData.properties.description : '',
          media_details: cardData?.properties?.media ?? []
        }
        if (cardData?.properties.landmark_type === ELAND_MARK.DESTINATION) {
          index = 'destination'
        } else if (cardData?.properties.landmark_type === ELAND_MARK.ORIGIN) {
          index = 'start'
        }
        this.goToSlideOnSelect(annotationId);
        this.viewCardDetailsOnClk(highlightPoint, index);
      }
    }
  }

  /**
   * @description this is called to highlight the specific index and moves carousel accordingly,
   * We are taking the slide that is active and using it's index to move left or right. Do not touch this.
   * @param annotationId 
   */
  public goToSlideOnSelect(annotationId: string): void {
    let slideIndex ;
    if (annotationId.includes('destination')) {
      slideIndex = this.waypointsList.length;
    } else if (annotationId.includes('origin')) {
      slideIndex = -1;
    } else {
      slideIndex = this.waypointsList.findIndex((wayPoint: any) => wayPoint.id === annotationId);
    }
    const element = this._elementRef.nativeElement.querySelector('.dealer-carousel-parent');
    const child = element.querySelector('.slides-list');
    element.scrollLeft = slideIndex * child?.scrollWidth;
  }

  /**
   * @description returns full start/destination address
   * @param from 
   * @returns string
   */
  public getFullLocation(from: string): string {
    const checkIfTruthy = (val: string) => {
      return val ? val + ' ': '';
    }
    if (ELAND_MARK.ORIGIN === from) {
      const origin = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.ORIGIN === feature.properties?.landmark_type);
      const address = origin?.properties?.address;
      return address ? checkIfTruthy(address.house_number) + checkIfTruthy(address.street_name) + checkIfTruthy(address.municipality) + checkIfTruthy(address.zip_code) + checkIfTruthy(address.state) + checkIfTruthy(address.country): '';
    } else if (ELAND_MARK.DESTINATION === from) {
      const destination = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.DESTINATION === feature.properties?.landmark_type);
      const address = destination?.properties?.address;
      return address ? checkIfTruthy(address.house_number) + checkIfTruthy(address.street_name) + checkIfTruthy(address.municipality) + checkIfTruthy(address.zip_code) + checkIfTruthy(address.state) + checkIfTruthy(address.country): '';
    } else {
      return '';
    }
  }

  /**
   * @description Invoked when we click on the card. Enables editing of the insider point
   * @param data 
   * @param index 
   */
  public enableEdit(event: Event, data: any, index: any): void {
    event.stopPropagation();
    const disableEditStates: string[] = [ETOUR_CREATION_STEPS.SECOND, ETOUR_CREATION_STEPS.THIRD, ETOUR_CREATION_STEPS.START_ADDED, ETOUR_CREATION_STEPS.ADD_WAY_POINT, ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS, ETOUR_CREATION_STEPS.EDITING_START, ETOUR_CREATION_STEPS.EDITING_DESTINATION];
    const check = (): boolean => {
      return !this.editActive && 
      !disableEditStates.includes(this.currentStep) && !this.showLoaderUntillGeoJsonCompletes;    
    }
    const getId = (): string => {
      const origin = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.ORIGIN === feature.properties?.landmark_type);
      const destination = this.emotionTourTrackencodedGpsPoints?.features.find((feature) => ELAND_MARK.DESTINATION === feature.properties?.landmark_type);
      if (index === 'start') {
        return origin?.id ?? ''
      } else if (index === 'destination') {
        return destination?.id ?? ''
      } else {
        return ''
      }
    }
    if (check()) {
      this.mediaCarousalService.insiderSptDetails = data;
      if (index === 'start' || index === 'destination') {
        data.index = index;
        if (data.description?.length > 0) {
          data.isHighlight = true; // Case for editing previously created highlights
          data.id = getId()
        }
        if (this.isRoundTrip && index === 'destination') {
          // Currently disabling this, because for round trip - 
          // any changes made to start must update destination as well
          return;
        }
      } else {
        data.index = index + 1;
        if (data.description?.length > 0) {
          data.isHighlight = true; // Case for editing previously created highlights
        }
      }
      this.editActive = true;
      this.disableEdit = false;
      this.sendEnableStatus.emit(data);
      this.selectedCard = index;
      this.mediaService.setEditActiveStatus(true);
      this.mediaCarousalService.insiderSptDetails['index'] = index;
    } else if (this.editActive) {
      this.showDisableEditPopup(index);
    }
  }

  showAddInsiderSpotCard(): boolean {
    const allowedState: string[] = [ETOUR_CREATION_STEPS.WAY_POINT_ADDED, ETOUR_CREATION_STEPS.DESTINATION_ADDED, ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH];
    const show = ((allowedState.indexOf(this.state) > -1 && ((!this.emotionTourTrackencodedGpsPoints && this.autoAnnotate) || 
    ((this.emotionTourTrackencodedGpsPoints || this.tourPublishStage) && !this.autoAnnotate))))
      && !this.showLoaderUntillGeoJsonCompletes;
    return show;
  }

  checkInsiderSpotExists(): void {
    this.showInsiderSPtMsg = (this.state === 'DESTINATION_ADDED' || this.state === 'WAY_POINT_ADDED') && this.waypointsList?.length < 1;
  }

  showDestinationCard(): boolean {
    const allowedState: string[] = [ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH, ETOUR_CREATION_STEPS.TOUR_PUBLISHED, ETOUR_CREATION_STEPS.DESTINATION_ADDED, ETOUR_CREATION_STEPS.WAY_POINT_ADDED];
    const show = allowedState.includes(this.currentStep) || this.hideRoundTripCard || this.destinationPointData || this.gpxUploaded;
    return show;
  }

  public handleSlideClick(slide: any, index: number | string): void {
    const allowedStates: string[] = [ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH, ETOUR_CREATION_STEPS.DESTINATION_ADDED, ETOUR_CREATION_STEPS.WAY_POINT_ADDED];
    if (!this.tourPublishStage) {
      const e = new Event('click');
      this.enableEdit(e, slide, index)
    } else if (allowedStates.includes(this.currentStep) && !this.autoAnnotate) {
      const e = new Event('click');
      this.enableEdit(e, slide, index)
    } else {
      this.highlightSelectedCardOnClick(slide.id, 'CARD_CLICK', index, slide);
    }   
  }

  ngOnDestroy() {
    this.subscription.forEach((sub: any) => sub.unsubscribe());
    this.editActive = false;
    this.mediaCarousalService.viewSelectedCardDetails = false;
    this.mediaCarousalService.insiderSptDetails = null;
    this.inViewCardDetailsMode = false;
  }
}
