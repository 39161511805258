import { createSelector } from '@ngrx/store';

// this name is same as what we have in app.module.ts
export const selectFeature = (state: any) => state.businessPortal;

export const profileSelector = createSelector(
  selectFeature,
  (state) => state?.profile // this name is what we have in our app.reducer.ts
);

export const languageSelector = createSelector(
    selectFeature,
    (state) => state?.language
  );
export const mapTilesSelector = createSelector(
  selectFeature,
  (state) => state?.mapTiles
);

export const mapCurrentDataSelector = createSelector(
  selectFeature,
  (state) => state?.mapData
);

export const mapResetCurrentDataSelector = createSelector(
  selectFeature,
  (state) => state?.mapResetData
);

