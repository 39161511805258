import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { environment } from 'src/environments/environment';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { Router } from '@angular/router';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  isMenuVisible:boolean = false;
  scrolled60:any;
  above60:boolean=false
  aliasSelected:any;
  loggedInUser: Observable<any>;
  activeLink: string = 'events'

  logoutUrl: string = environment.logout;

  isLocationsVisible = environment.enableLocations;

  selectedLanguage: string;

  isMobileMenuVisible: boolean = false;
  isProfileVisible:boolean=false
  subscriptions: Subscription[] = [];
  translationData: any;
  allSupportedLangCodes: any[];

  constructor(private authenticationService: AuthenticationService, 
    private authAmplifyService: AuthAmplifyService, 
    private SharingData:SharingDataService, 
    private _translationService: TranslationService,
    private router:Router,
  private _storeManagerService: StoreManagerService) { 
    this.loggedInUser = authenticationService.isLoggedIn();
    this.allSupportedLangCodes = this._translationService.getSupportedLanguageByCode();
    let selectedLanguage = this._storeManagerService.getUserSelectedLanguageFromStore();
    this.selectedLanguage = selectedLanguage;
  }
 

  ngOnInit(): void {
    this._storeManagerService.setMapDataOnLoad(<any>null)
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.subscriptions.push(this.SharingData.getAliasList().subscribe((data:any)=>{
      if(data){
      this.aliasSelected=data
      }
      }
    ))

    

    this.subscriptions.push(this.SharingData.getMobileMenuStatus().subscribe((data:any)=>{
        this.isMobileMenuVisible=data;
      }
    ));

    this.subscriptions.push(this.SharingData.getProfileDetailsClicked().subscribe((value:any)=>{
      if(value){
        this.isProfileVisible=false
      }
    }))


    localStorage.getItem('profile');
    if(localStorage.getItem('profile')){
      this.aliasSelected=true
    }

  }



  async logout() {
    this.removeIndexedDb();
    // this.authAmplifyService.logout();
    this.authAmplifyService.proxyLogin().subscribe(response => {
      window.location.href = response.data;
    });
  }
  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
    this.toggleMobileMenu(); // menu should be hidden
  }

  switchLanguage(language:string) {
    this._storeManagerService.setUserLanguageToStore(language);
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  toggleMobileMenu(){
    this.SharingData.setMobileMenuStatus(!this.isMobileMenuVisible);
  }
  myProfile(){
    this.isProfileVisible=!this.isProfileVisible
  }
  closeDialog(event:any){
    event.stopPropagation()
    this.SharingData.setProfileSelected(true)
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.isProfileVisible=false
  }

  logoClicked(){
    this.router.navigate(['/events'])
  }
  ngOnDestroy(): void {
    // 'ngOnDestroy' is empty
  }
}
