import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthAmplifyService } from "src/app/shared/service/auth-amplify.service";
import { AlertModalService } from "src/app/modules/locations/services/modal/alert-modal.service";
import { Auth } from "aws-amplify";
import { AppConstants } from "src/app/shared/constants/constants";

@Component({
  selector: "app-authorize",
  templateUrl: "./authorize.component.html",
  styleUrls: ["./authorize.component.scss"],
})
export class AuthorizeComponent implements OnInit {
  tokenDetails: any;
  token: any;
  previousUrl: any;
  subscribeFormData: any;
  errortriggered: boolean = false;
  isCancelVisible: boolean = false;
  auth:any=Auth
  proxyAuth:string = environment.proxyUrl
  code: any;
  constructor(
    private router: Router,
    private authAmplifyService: AuthAmplifyService,
    private alertModalService: AlertModalService,
    private route: ActivatedRoute
  ) {
    // this.authAmplifyService.config();
    console.log('Called Constructor');
    
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      if (this.code) {
        authAmplifyService.callback(this.code).subscribe(response => {
          this.router.navigate(["/events"]);
        });;
      }
    });
  }


  
  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
  }

  ngOnInit(): void {
    
    // this.alertModalService.onAction.subscribe((data: any) => {
    //   if (data && data.action === true && data.page == 'session-expired') {
    //     this.logout();
    //   }
    // });
    


    // this.authAmplifyService.currentSession().then(
    //   (res: any) => {
    //     let accessToken = res.getAccessToken();
    //     let jwt = accessToken.getJwtToken();
    //     let jwt_expires_in = accessToken.getExpiration();
    //     let refreshToken = res.getRefreshToken();
    //     let jwtRefreshToken = refreshToken.getToken();
    //     let idToken = res.getIdToken();
    //     let jwtIdToken = idToken.getJwtToken();
    //     let fullData = {
    //       id_token: jwtIdToken,
    //       access_token: jwt,
    //       refresh_token: jwtRefreshToken,
    //       expires_in: jwt_expires_in,
    //       token_type: "Bearer"
    //     };
    //   let redirectUrl = localStorage.getItem('redirectUrl');
      
    //   if(redirectUrl) {
    //     this.router.navigate([redirectUrl]);
    //     localStorage.removeItem('redirectUrl');
    //   } else if (res) {
    //     let navUrl = 'alias';
    //     this.router.navigate([navUrl]);
    //     this.authAmplifyService.setUserLoginStatus(true);
    //     this.authAmplifyService.setCurrentUserToken(fullData);

    //     let redirectUrl = localStorage.getItem("redirectUrl");

    //     if (redirectUrl) {
    //       this.router.navigate([redirectUrl]);
    //       localStorage.removeItem("redirectUrl");
    //     } else  {
    //       this.router.navigate(["/events"]);
    //     }
    //   } },
    //   (err: any) => {
    //     this.errortriggered = true;
    //     let error=JSON.stringify(err)
    //     this.clearData();
    //     this.alertModalService.openModal.next(true);
    //     let modalData = {
    //       title: 'Something went wrong',
    //       message: `${error}. Please click 'Continue' for further action.`,
    //       cancelBtnTitle: APP_CONSTANTS.COMMON_BUTTON_LABELS.CANCEL,
    //       actionBtnTitle: APP_CONSTANTS.COMMON_BUTTON_LABELS.CONTINUE,
    //       isCancelVisible: false
    //     }
    //     this.alertModalService.modalData.next(modalData);
    //   }
    // );
  }


  // async logout() {
  //   this.removeIndexedDb();
  //   this.authAmplifyService.logout().then(()=> {
  //     window.location.assign(environment.logout);
  //   });
  // }

  clearData() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.clearAllCookies();
  }

  clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (const element of cookies) {
      const cookie = element;
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  getRoleGroup(jwt: string) {
    try {
      return JSON.parse(atob(jwt.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
}
