import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RacesTimeComponent } from './components/races-time/races-time.component';
import { ModalComponent } from './components/modal/modal.component';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-out-side.directive';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { DragDropFileToUploadDirective } from './directives/drag-drop-file-upload.directive';
import { CommonMediaUploadComponent } from './components/common-media-upload/common-media-upload.component';
import { TranslationModule } from '../modules/translation/translation.module';
import { GenericTooltipComponent } from './components/generic-tooltip/generic-tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { SafePipe } from './pipes/safe.pipe';
import { OnlyIntegersDirective } from './directives/only-integers.directive';
import { TwoDecimalOnlyDirective } from './directives/two-decimal-only.directive';
import { CustomDropdownComponent } from './components/dropdown/custom-dropdown.component';
import { ButtonComponent } from './component/button/button.component';


@NgModule({
  declarations: [
    ImageCropperComponent,
    DragDropFileToUploadDirective,
    RacesTimeComponent,
    ModalComponent,
    CommonModalComponent,
    ClickOutsideDirective,
    CommonMediaUploadComponent,
    GenericTooltipComponent,
    TooltipDirective,
    CustomDropdownComponent,
    SafePipe,
    OnlyIntegersDirective,
    TwoDecimalOnlyDirective,
    ButtonComponent  
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    TranslationModule,

  ],
  exports: [
    ImageCropperComponent,
    RacesTimeComponent,
    ModalComponent,
    CommonModalComponent,
    ClickOutsideDirective,
    CommonMediaUploadComponent,
    TooltipDirective,
    CustomDropdownComponent,
    SafePipe,
    OnlyIntegersDirective,
    TwoDecimalOnlyDirective,
    ButtonComponent
  ],
  
})
export class SharedModule { }